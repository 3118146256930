import {
  Box,
  Button,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import React from "react";
// import { useTransition } from "react";
import { AxiosCall } from "../../api";
import { EmptyScreen, LoadingScreen } from "../../components";
import Config from "../../config";
import { OrderHistory } from "./orderHistory";
import axios from "axios";
import { useState } from "atp-task-viewer-with-tree";
import { useEffect } from "react";
import { async } from "central_pharma_binder";

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "48px",
    padding: "8px 16px",
    background: theme.palette.background.table,
    borderBottom: "1px solid #DCDCDC",
  },
  papers: {
    height: "100%",
    border: "1px solid #DCDCDC",
    background: theme.palette.background.table,
  },
  container: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 128px)",
      flex: "1 1 auto",
    },
  },
  content: {
    height: "calc(100% - 48px)",
    padding: theme.spacing(2),
    overflowY: "auto",
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 48px)",
      // padding: theme.spacing(1),
    },
  },
  textResponsive: {
    fontWeight: "600",
    fontSize: "1.1rem",

    [theme.breakpoints.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
}));

export const FeedBack = (props) => {
  const {
    user,
    handleClose = () => false,
    onReorderClicked = () => false,
    BedNumber,
    BedCode,
    Difference,
  } = props;
  const classes = useStyles();
  const Theme = useTheme();
  const forPhone = useMediaQuery(Theme.breakpoints.down("sm"));
  const [orderHistory, setOrderHistory] = React.useState([]);
  const [isLoading, setLoading] = React.useState(true);
  const [store, setStore] = React.useState([]);
  const [edit, setEdit] = React.useState([]);
  // console.log("store",store)
  // const [search, setSearch] = React.useState("");

  // const [isPending, startTransition] = useTransition();

  React.useEffect(() => {
    getOrderDetails();
    //eslint-disable-next-line
  }, []);

  // const onHandleChange = async (e) => {
  //   ;
  //   let value = e.target.value;
  //   if (value.trim().length > 0) {
  //     setSearch(value);
  //     let updateList = orderHistory.filter((l) => {
  //       return l?.MealCode?.MealDisplayName.toLowerCase().includes(value);
  //     });
  //     setOrderHistory(updateList);
  //   } else {
  //     setSearch("");

  //     getOrderDetails();
  //   }
  // };

  const getOrderDetails = async () => {
    let params = {
      db_name: Config.dbname,
      filter: {
        bedId: BedCode,
      },
      queryid: "e0c57e39-6395-4bdb-a5cf-c83551d2d67f",
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
      params
    );
    // let result = res?.result;
    setOrderHistory(res);
    setLoading(false);
    // handleEnablebtn()
  };

  const cancelval = process.env.REACT_APP_CANCELORDER;
  const Edval = process.env.REACT_APP_ORDEREDIT;
  //This is useEffect is used for cancel order
  useEffect(async () => {
    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "CodeableConceptMaster",
      filter: `CodeableConceptMaster.activestatus==true && CodeableConceptMaster.Type=='${cancelval}' && CodeableConceptMaster.status == true`,
      return_fields:
        "MERGE({coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod).display)})",
    };
    let config = {
      url: process.env.REACT_APP_ARANGO_URL_READ,
      method: "POST",
      data: params,
      headers: { "Content-Type": "application/json" },
    };
    await axios(config).then((res) => {
      let arr = res?.data?.result;
      let code = arr.map((c) => {
        return c.coding;
      });
      setStore(code);
    });
  }, []);

  //This useEffect is used for Edit quantity
  useEffect(async () => {
    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "CodeableConceptMaster",
      filter: `CodeableConceptMaster.activestatus==true && CodeableConceptMaster.Type=='${Edval}' && CodeableConceptMaster.status == true`,
      return_fields:
        "MERGE({coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod).display)})",
    };
    let config = {
      url: process.env.REACT_APP_ARANGO_URL_READ,
      method: "POST",
      data: params,
      headers: { "Content-Type": "application/json" },
    };
    await axios(config).then((res) => {
      let arr = res?.data?.result;
      let code = arr.map((c) => {
        return c.coding;
      });
      setEdit(code);
    });
  }, []);

  return (
    <Box className={classes.papers} elevation={0}>
      <Box className={classes.Header}>
        <Box className={classes.container}>
          {!forPhone && (
            <IconButton color="primary" onClick={() => handleClose()}>
              <ArrowBackIcon fontSize="medium" />
            </IconButton>
          )}

          <Typography variant="h6" className={classes.textResponsive}>
            {"Order History"}
          </Typography>
        </Box>
        {/* {!forPhone && (
          <SearchTextField
            value={search}
            onHandleChange={(e) => onHandleChange(e)}
            //   onKeyPress={(e) => onEnterClicked(e)}
            //   onSearchClicked={() => onSearchClicked()}
            placeholder="Search by dish"
            colored
            padding
            disable={true}
            variant="2"
            forPhone={forPhone}
          />
        )} */}
        {forPhone && (
          <Button
            variant="contained"
            startIcon={<ArrowBackIcon />}
            onClick={() => handleClose()}
            color="primary"
            size="small"
          >
            Go Back
          </Button>
        )}
      </Box>
      {/* {forPhone && (
        <SearchTextField
          value={search}
          onHandleChange={(e) => onHandleChange(e)}
          //   onKeyPress={(e) => onEnterClicked(e)}
          //   onSearchClicked={() => onSearchClicked()}
          placeholder="Search by dish"
          colored
          padding
          margin
          disable={true}
          variant="2"
          forPhone={forPhone}
        />
      )} */}
      <Box className={classes.content}>
        {!isLoading && orderHistory.length > 0 ? (
          <OrderHistory
            orderHistory={orderHistory}
            user={user}
            store={store}
            edit={edit}
            getOrderDetails={getOrderDetails}
            onReorderClicked={onReorderClicked}
            handleCloseDialog={handleClose}
            BedNumber={BedNumber}
            Difference={Difference}
          />
        ) : isLoading ? (
          <LoadingScreen message="Loading..." />
        ) : (
          <EmptyScreen message="No history found..." />
        )}
      </Box>
    </Box>
  );
};
