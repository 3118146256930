import {
  makeStyles,
  Typography,
  Grid,
  Button,
  Divider,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { LinearProgressWithLabel } from "../../components";
import { ComponentToRender } from "./componentToRender";
import {
  actions,
  idmapis,
  assessmentapis,
  LevelApi,
} from "central_pharma_binder";
import { useDispatch, useSelector } from "react-redux";
import { AlertContext, BackdropContext } from "../../../../../contexts";
import { AlertProps, AxiosCall } from "../../utils";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import { useTheme } from "@material-ui/core";

import { getMobileLimitBasedOnCC, giveMeRoleDataSecurity } from "../../utils";
import {
  checkStringValidations,
  checkNumericValidations,
  checkDobValidations,
  checktimevalidation,
  checkdatetimevalidation,
  checkfileUploadValidation,
} from "./checkValidations";

import { Prompt } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { ValidateNumber, ValidateTime } from "../../utils";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formTitle: {
    fontWeight: "bold",
    margin: "0 8px",
    // color: theme.palette.primary.main
    display: "-webkit-box",
    WebkitLineClamp: "1",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  menuIcon: {
    color: "black",
  },
  skipBtn: {
    // color: theme.palette.hash.primary
  },
  header: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 3,
  },
  uiComponentWrapper: {
    marginTop: 10,
    padding: "8px 28px",
  },
  savebtn: {
    padding: "6px 12px",
    borderRadius: 6,
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.light,
    "&:hover": {
      // background: theme.palette.primary.light,
      // color: theme.palette.text.dark,
      // backgroundColor: theme.palette.primary.main,
      // color: theme.palette.primary.light,
    },
  },
  draftbtn: {
    padding: "6px 12px",
    borderRadius: 6,
    marginLeft: "5px",
    marginRight: "5px",
    // color: theme.palette.secondary.main,
    // borderColor: theme.palette.secondary.main,
  },
  nxtBtn: {
    fontSize: "0.8125rem",
    display: "flex",
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.light,
    // '&:hover': {
    //   background: theme.palette.primary.light,
    //   color: theme.palette.text.dark,
  },
  headerWrappper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    margin: "1em 0",
  },
  headerWrappper1: {
    flex: "0 0 auto",
  },
  headerWrappper2: {
    flex: "1 1 auto",
  },
  headerWrappper3: {
    flex: "0 0 auto",
  },
}));

export const FormBuilder = ({
  form_id,
  forms,
  getSectionId,
  parent_form_id,
  isEdit,
  entry_id,
  answer = {},
  setHistoryOfViewedForms,
  secName = "",
  isReadyOnly = false,
  onSaveForm = () => false,
  onAnswerUpdated = () => false,
  setView,
  viewSection = () => false,
  statusUpdateData = {},
  entryPatientDetail = {},
  patientDetail = {},
  restForm = () => false,
  formName,
  onBackBtnClicked,
  ticketId,
  isCancel = false,
  isCancelFunc = () => false,
}) => {
  // console.log("answer", answer);
  // const personId = jwt_decode(localStorage.getItem("queue_token"));
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const backDrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);

  const [allowDraft, setAllowDraft] = React.useState(false);
  const [isChangesSaved, setIsChangesSaved] = React.useState(false);
  // const [isDisable, SetoptionsList] = React.useState(false)
  const [smartAnswer, setSmartAnswer] = React.useState(true);
  const [formsWithAnswer, setFormsWithAnswer] = React.useState(answer);
  const [errorValidation, setErrorValidation] = React.useState({});
  // const [pci, setPci] = React.useState([]);
  let optionList = {};
  React.useEffect(() => {
    // (async () => {
    //   let Client_ID = await idmapis.getclientif_from_tenantid(
    //     localStorage.getItem("tenentid")
    //   );
    //   let MappedDiseaseTable = await assessmentapis.getAssessmentData({
    //     client_id: Client_ID?.result[0]._id,
    //     form_id: `forms/${parent_form_id}`,
    //   });
    //   let OrgName = await LevelApi.getOrgName(
    //     MappedDiseaseTable.result[0].mappingTable.selectedOrg._id
    //   );
    //   setPci(OrgName.result[0].PCILocation);
    // })();
  }, []);

  const savingJSON = useSelector(
    (state) =>
      state?.renderSlice?.upsertEntry ?? {
        loading: false,
        data: [],
        error: false,
      }
  );
  const userData = useSelector((state) => state?.authSlice?.login?.data);

  let currentButton;
  let currentForm = forms[0];
  let currentIndex = 1;
  let validated;

  if (form_id) {
    for (let index = 0; index < forms.length; index++) {
      const fr = forms[index];
      if (fr.id === form_id) {
        currentForm = fr;
        currentIndex = index + 1;
        break;
      }
    }
  }

  const canIableToMoveNextForm = (node) => {
    if (node?.dependent) {
      let valueArr = node?.dependent?.option?.map((_) => _.value) ?? [];

      if (
        valueArr.indexOf(
          formsWithAnswer[node?.dependent?.parent_question_id]?.answer
        ) > -1
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const mobileValidations = (answer, id) => {
    if (answer && answer?.phoneCode?.length > 0) {
      const limit = getMobileLimitBasedOnCC(answer.phoneCode);
      if (limit === answer?.number?.length) {
        return "";
      } else {
        errorValidation[id] = {
          error: true,
          errorMessage: ``,
        };
      }
    } else {
      errorValidation[id] = {
        error: true,
        errorMessage: `Please fill the required field`,
      };
    }
  };

  const locationLevelValidations = (answer, id) => {
    if (answer?.country) {
      return "";
    } else {
      errorValidation[id] = {
        error: true,
        errorMessage: `Please select the country field`,
      };
    }
  };

  const storeErrorValidations = (id, msg) => {
    errorValidation[id] = {
      error: true,
      errorMessage: msg,
    };
  };

  const stringValidations = (item, val, id) => {
    if (val || item[0]?.is_required) {
      checkStringValidations(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };

  const checktimevalidations = (item, val, id) => {
    if (val || item[0]?.is_required) {
      checktimevalidation(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };
  const checkdatetimevalidations = (item, val, id) => {
    if (val || item[0]?.is_required) {
      checkdatetimevalidation(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };

  const numericValidations = (item, val, id) => {
    if (val || item[0]?.is_required) {
      checkNumericValidations(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };
  const fileValidation = (item, val, id) => {
    // console.log(item, val, id);
    if (item[0]?.is_required === true) {
      checkfileUploadValidation(
        item[0]?.validation,
        val,
        id,
        storeErrorValidations,
        item[0].question
      );
    }
  };
  const dobValidations = (item, val, id) => {
    if (val?.age >= 0 || item[0]?.is_required) {
      checkDobValidations(
        item[0]?.validation,
        val?.age,
        id,
        storeErrorValidations
      );
    }
  };

  const tableValidations = (table_scheme, val, id) => {
    errorValidation[id] = [];

    table_scheme.row.map((l, i) => {
      //get the all index that has validations property
      let findIndexKeys = l.validations && Object.keys(l.validations);

      //get the index that has date validations
      let removeDateValidations = table_scheme.column
        .map((l, j) => {
          if (l.ui_component === "date") {
            return j;
          }
          return undefined;
        })
        .filter((l) => l !== undefined);

      // remove date valiadtions from findIndexKeys because date validations done automatically in UI
      findIndexKeys = findIndexKeys.filter(
        (l, i) => l !== removeDateValidations[i]?.toString()
      );

      if (findIndexKeys.length > 0) {
        findIndexKeys?.map((f) => {
          checkStringValidations(
            l.validations[f],
            val.tableData[i][f],
            id,
            (id, msg) => tableErrorValidations(id, msg, i, f)
          );
          return f;
        });
      }
      return l;
    });

    if (errorValidation[id]?.length === 0) {
      delete errorValidation[id];
    }
  };

  const tableErrorValidations = (id, msg, rowIndex, dataIndex) => {
    errorValidation[id][rowIndex] = [];

    errorValidation[id][rowIndex][dataIndex] = {
      error: true,
      errorMessage: msg,
    };
  };

  const checkRequired = (form, answer) => {
    const requiredTrueids = [];
    const answerdetails = answer;
    const availabletrues = [];

    let isSmartSkip = "";

    const FramedQuestions = form.framed_questions;

    const checkSmartQuesionAvailable = FramedQuestions.map((item) => {
      if (item.is_required === true && item.ui_component === "popup_switch") {
        return true;
      } else {
        return false;
      }
    });
    // eslint-disable-next-line
    const mapFramedQuestion = FramedQuestions.map((item) => {
      if (
        (item.is_required === true ||
          (item?.validation && Object.keys(item?.validation)?.length > 0)) &&
        item.ui_component !== "label"
      ) {
        if (
          item.ui_component === "input_date_picker" &&
          item.is_required === true
        ) {
          requiredTrueids.push(item);
        } else if (item.ui_component !== "input_date_picker") {
          requiredTrueids.push(item);
        }
      }
    });
    if (requiredTrueids.length > 0 && Object.keys(answer).length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the required fields !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false;
    }
    const requiredId = requiredTrueids.map((item) => {
      return item.id;
    });

    //empty the errorValidation object to check
    for (var item in errorValidation) delete errorValidation[item];
    // eslint-disable-next-line
    const checking = requiredId.map((item) => {
      if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "input_number"
      ) {
        numericValidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
          "input_text" ||
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
          "input_textarea"
      ) {
        stringValidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "input_time_picker"
      ) {
        checktimevalidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "input_datetime_picker"
      ) {
        checkdatetimevalidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "mobile_no"
      ) {
        mobileValidations(answerdetails[item]?.answer, item);
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "location_level"
      ) {
        locationLevelValidations(answerdetails[item]?.answer, item);
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "date_of_birth"
      ) {
        dobValidations(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
          "custom_table" ||
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
          "custom_table_2"
      ) {
        tableValidations(
          requiredTrueids?.filter((l) => l.id === item)[0]?.table_scheme,
          answerdetails[item]?.answer,
          item
        );
      } else if (
        requiredTrueids?.filter((l) => l.id === item)[0]?.ui_component ===
        "file_upload"
      ) {
        fileValidation(
          requiredTrueids?.filter((l) => l.id === item),
          answerdetails[item]?.answer,
          item
        );
      } else {
        if (
          answerdetails[item]?.answer?.length > 0 ||
          answerdetails[item]?.answer?.value?.length > 0 ||
          answerdetails[item]?.answer?.tableData?.length > 0 ||
          answerdetails[item]?.answer?.address?.length > 0
        ) {
          availabletrues.push(answerdetails[item]);
        } else {
          errorValidation[item] = {
            error: true,
            errorMessage: `Please fill the required field`,
          };
        }
      }
      // validation for smart skip
      if (
        Object.values(checkSmartQuesionAvailable).indexOf(true) > -1 &&
        answerdetails[item]?.answer === "No"
      ) {
        // let requiredTrueidsLength = requiredTrueids.length;
        // availabletrues.length = requiredTrueidsLength;

        //empty the errorValidation object to check
        for (var items in errorValidation) delete errorValidation[items];

        isSmartSkip = true;
      }

      // }
    });

    setErrorValidation(errorValidation);

    if (checkSmartQuesionAvailable && isSmartSkip) {
      return true;
    } else {
      if (errorValidation && Object.keys(errorValidation)?.length === 0) {
        return true;
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Please fill the required fields !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return false;
      }
    }
  };

  const onSkipBtnClicked = (currentIndex, currentForm) => {
    skipFunction(currentIndex, currentForm);
  };

  const skipFunction = (currentIndex, currentForm) => {
    let isSkipCheck = currentForm?.is_skippable;
    if (isSkipCheck) {
      onNextBtnClicked(currentIndex, currentForm, false, true);
    }
  };

  const giveMeNotifyType = (isDraft) => {
    if (isDraft) {
      return 1;
    } else {
      switch (secName) {
        case 0:
          return 2;
        case 1:
          return 3;
        case 2:
          return 4;
        case 4:
          return 5;

        default:
          return 0;
      }
    }
  };
  const constructQuestion = (data) => {
    let res = [];
    Object.keys(data).forEach((e) =>
      res.push({ question_id: e, answer: data[e] })
    );
    return res;
  };
  const levelConstruct = (datas, type = "") => {
    let data = {};
    if (type === "form") {
      datas.forEach((e, i) =>
        Object.keys(e).forEach((v) => {
          data = {
            ...data,
            [`Level${i + 1}`]: {
              LevelData_id: [e[v]][0]._id,
              LevelDataName: [e[v]][0].child_name,
            },
          };
        })
      );
    } else {
      datas.forEach((e, i) =>
        Object.keys(e).forEach((v) => {
          data = {
            ...data,
            [`Level${i + 1}`]: {
              LevelData_id: [e[v]][0]._id,
              LevelDataName: [e[v]][0].child_name,
              LevelStructureName: [e[v]][0].parentLevel.label,
              LevelStructure_id: [e[v]][0].parentLevel._id,
            },
          };
        })
      );
    }
    return data;
  };
  const getFormatedLevelData = (res1, res2) => {
    let LEVEL = {};
    res1.result.forEach(
      (e, i) =>
        (LEVEL = {
          ...LEVEL,
          [`Level${i + 1}`]: { ...res1.result[i], ...res2.result[i] },
        })
    );
    return LEVEL;
  };
  const locationLevelObjConstruct = (j) => {
    let Result = {};
    j.forEach((e) =>
      Object.keys(e).forEach((v) => (Result = { ...Result, [v]: e[v] }))
    );
    return Result;
  };
  const constructFormData = (data) => {
    let finalData = Object.entries(data).reduce((acc, curr) => {
      acc = { ...acc, [curr[1].questions]: curr[1].answer };
      return acc;
    }, {});
    return finalData;
  };

  const TriggerEmailService = async (data) => {
    let mailId = Object.values(data).find((e) =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.answer)
    )?.answer;
    var config = {
      method: "post",
      url: process.env.REACT_APP_REGISTRATION_MAIL,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ mailId }),
    };
    axios(config).then((res) => {
      window.location.reload();
      backDrop.setBackDrop({
        ...backDrop,
        open: false,
      });
    });
  };
  const RegistrationServiceCall = async (data) => {
    var config = {
      method: "post",
      url: process.env.REACT_APP_REGISTRATION_SERVICE_CALL,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config).then((res) => {
      // window.location.reload();

      backDrop.setBackDrop({
        ...backDrop,
        open: false,
      });
    });
  };
  const checkForDuplicateMailId = async (val) => {
    let mailId = Object.values(val).find((e) =>
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.answer)
    )?.answer;
    const payload = {
      db_name: process.env.REACT_APP_DB,
      entity: "form_entries",
      filter: `form_entries.answers[*].answer.questions any =='Email' && form_entries.answers[*].answer.answer any=='${mailId}' && form_entries.activestatus==true`,
      return_fields: "form_entries",
    };
    let result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
      headers: { "Content-Type": "application/json" },
      data: payload,
    });

    return result?.data?.result.length === 0 ? true : false;
  };
  const onNextBtnClicked = async (
    currentIndex,
    currentForm,
    isDraft = false,
    isSkip = false
  ) => {
    //console.log("typess", currentForm, formsWithAnswer);
    //Checking wheather the section is skipable or not

    // console.log("Running");

    if (isSkip) {
      validated = true;
      setErrorValidation({});
    } else {
      validated = checkSectionType()
        ? true
        : checkRequired(currentForm, formsWithAnswer);
    }

    if (validated) {
      const dataForViewSection = [currentIndex, currentForm];
      setView(dataForViewSection);

      if (!entry_id) {
        if (await checkForDuplicateMailId(formsWithAnswer)) {
          if (currentIndex === forms.length || isDraft) {
            const roleDataSecurity = giveMeRoleDataSecurity(userData);

            backDrop.setBackDrop({
              ...backDrop,
              open: true,
              message: "Saving your entry...",
            });
            let params = {
              notifier_id: "",
              // client_id: Client_ID.result[0]._id,
              verifier_registration_status: 0,
              form_id: form_id,
              data: formsWithAnswer,
              notifier_status: isDraft ? 9 : 1,
              notifyType: giveMeNotifyType(isDraft),
              roles: roleDataSecurity.myRole,
              // patient_id: patientDetail?._id,
              // patientDetail: {
              //   ...patientDetail?.createPatientData,
              //   patient_id: patientDetail?._id,
              //   _key: patientDetail?._key,
              // },
              // createPatient: patientDetail?._id.length !== 0 ? false : true,
              secName,
              ticketId,
              answers: constructQuestion(formsWithAnswer),
            };

            if (isEdit) {
              params.entry_id = entry_id;
              params.verifier_status = secName === 1 ? 3 : null;
              params.registration_status = secName === 2 ? 8 : null;
              params.investigator_status = secName === 3 ? 6 : null;
            }
            Promise.resolve(dispatch(actions.UPSERT_ENTRY(params))).then(
              (res) => {
                backDrop.setBackDrop({
                  ...backDrop,
                  open: false,
                });
                onBackBtnClicked();

                if (!res.payload.error) {
                  let entryid =
                    res?.payload?.data?.Result[0]?.properties?.doc?.entry_id;
                  onSaveForm({ ...params, entry_id: entryid });

                  alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `Form has been ${
                      isDraft
                        ? "saved as draft"
                        : isEdit
                        ? "updated"
                        : "submitted"
                    } Successfully!!!`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                  });
                } else {
                  backDrop.setBackDrop({
                    ...backDrop,
                    open: false,
                  });
                  alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: res.payload.data.message,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center,
                  });
                }
              }
            );
          } else {
            if (canIableToMoveNextForm(forms[currentIndex])) {
              getSectionId(forms[currentIndex]);
            } else {
              onNextBtnClicked(currentIndex + 1, forms[currentIndex], false);
            }
          }
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: `Email Id already exist`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      } else {
        let params = {
          entry_id: entry_id,
          form_id: form_id,
          data: formsWithAnswer,
        };
        Promise.resolve(dispatch(actions.UPSERT_ENTRY(params))).then((res) => {
          backDrop.setBackDrop({
            ...backDrop,
            open: false,
          });
          onBackBtnClicked();

          if (!res.payload.error) {
            onSaveForm({ ...params });
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: `Form has been ${
                isDraft ? "saved as draft" : isEdit ? "updated" : "submitted"
              } Successfully!!!`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            backDrop.setBackDrop({
              ...backDrop,
              open: false,
            });
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: res.payload.data.message,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        });
      }
    }
  };

  const onSaveAsDraftClicked = async (
    currentIndex,
    currentForm,
    isDraft = false,
    isSkip = false
  ) => {
    //console.log("typess", currentForm, formsWithAnswer);
    //Checking wheather the section is skipable or not

    if (isSkip) {
      validated = true;
      setErrorValidation({});
    } else {
      validated = checkSectionType()
        ? true
        : checkRequired(currentForm, formsWithAnswer);
    }

    if (validated) {
      const dataForViewSection = [currentIndex, currentForm];
      setView(dataForViewSection);

      if (currentIndex === forms.length || isDraft) {
        const roleDataSecurity = giveMeRoleDataSecurity(userData);
        let Client_ID = await idmapis.getclientif_from_tenantid(
          localStorage.getItem("tenentid")
        );

        let MappedDiseaseTable = await assessmentapis.getAssessmentData({
          client_id: Client_ID.result[0]._id,
          form_id: `forms/${parent_form_id}`,
          //! HARDCODED FOR CHECKING PURPOSE
          // client_id: "client/10038",
          // form_id: "forms/441a3229-0942-490e-a01a-b580061653d2",
        });
        let LevelOrgData = await LevelApi.levelOrg(
          MappedDiseaseTable.result[0].mappingTable.selectedOrg._id
        );
        let OrgName = await LevelApi.getOrgName(
          MappedDiseaseTable.result[0].mappingTable.selectedOrg._id
        );
        let parentOrg = await LevelApi.parentOrgName(OrgName.result[0].OrgType);
        backDrop.setBackDrop({
          ...backDrop,
          open: true,
          message: "Saving your entry...",
        });
        let params = {
          notifier_id: "",
          // client_id: Client_ID.result[0]._id,
          verifier_registration_status: 0,
          form_id: parent_form_id,
          data: formsWithAnswer,
          notifier_status: isDraft ? 9 : 1,
          notifyType: giveMeNotifyType(isDraft),
          roles: roleDataSecurity.myRole,
          // patient_id: patientDetail?._id,
          // patientDetail: {
          //   ...patientDetail?.createPatientData,
          //   patient_id: patientDetail?._id,
          //   _key: patientDetail?._key,
          // },
          // createPatient: patientDetail?._id.length !== 0 ? false : true,
          secName,
        };

        if (isEdit) {
          params.entry_id = entry_id;
          params.verifier_status = secName === 1 ? 3 : null;
          params.registration_status = secName === 2 ? 8 : null;
          params.investigator_status = secName === 3 ? 6 : null;
        } else {
          params.location = {
            client_id: Client_ID.result[0]._id,
            level: [levelConstruct(LevelOrgData.result[0].level, "form")],
          };
        }
        //!INSERT INTO QUEUE
        let ConstructInsertIntoQueueData = {
          inputDoc: {
            // pci: pci ? "PCI Location" : "Non PCI Location",
            verifier_registration_status: 0,
            // patient_details: {
            //   patient_id: patientDetail._id,
            //   // patient_name: `${patientDetail.patient_name.titoe.value} ${
            //   //   patientDetail.patient_name.first_name
            //   //     ? patientDetail.patient_name.first_name
            //   //     : ""
            //   // } ${
            //   //   patientDetail.patient_name.middle_name
            //   //     ? patientDetail.patient_name.middle_name
            //   //     : ""
            //   // } ${
            //   //   patientDetail.patient_name.last_name
            //   //     ? patientDetail.patient_name.last_name
            //   //     : ""
            //   // }`,
            //   patient_name: ` ${
            //     patientDetail.patient_name.first_name
            //       ? patientDetail.patient_name.first_name
            //       : ""
            //   } ${
            //     patientDetail.patient_name.middle_name
            //       ? patientDetail.patient_name.middle_name
            //       : ""
            //   } ${
            //     patientDetail.patient_name.last_name
            //       ? patientDetail.patient_name.last_name
            //       : ""
            //   }`,
            //   patientid: patientDetail.ID,
            //   dob: patientDetail.dob_age.dob,
            //   // email: patientDetail.email,
            //   email: "",
            //   number: patientDetail.mobile.number,
            //   phoneCode: patientDetail.mobile.number,
            //   // address: patientDetail.addressline,
            //   address: "",
            // },
            notifier_id: "",
            client_id: Client_ID.result[0]._id,
            client_Name: Client_ID.result[0].client_name,
            clientid: Client_ID.result[0].tenantid,
            Level: levelConstruct(LevelOrgData.result[0].level),
            ParentOrgName: parentOrg.result[0].name,
            Organization_id:
              MappedDiseaseTable.result[0].mappingTable.selectedOrg._id,
            Facility_name:
              MappedDiseaseTable.result[0].mappingTable.selectedOrg.name,
            assessment:
              MappedDiseaseTable.result[0].mappingTable.assessment._id,
            assessment_name:
              MappedDiseaseTable.result[0].mappingTable.assessment.name,
            // disease_id: MappedDiseaseTable.result[0].mappingTable.disease._id,
            // disease_name:
            //   MappedDiseaseTable.result[0].mappingTable.disease.name,
            notified_date: Date.parse(new Date()) / 1000,
            form_id: parent_form_id,
            form_name: formName,
            Notifier_Status: process.env.REACT_APP_DRAFT_STATUS,
            Verifier_Status: process.env.REACT_APP_VERIFIER_STATUS,
            answers: constructQuestion(formsWithAnswer),
          },
        };
        Promise.resolve(
          dispatch(actions.INSERTINTOQUEUE(ConstructInsertIntoQueueData))
        )
          .then((response) => {
            if (response.payload.data.error !== true) {
              // REFERENCE OF TICKEDID IS STORE IN FORM_ENTRIES COLLECTION FOR SHOWING THE FORM IN TASKVIEWER
              params = {
                ...params,
                ticketId: response.payload.data.ticketId,
                clientId: Client_ID.result[0]._id,
              };

              Promise.resolve(dispatch(actions.UPSERT_ENTRY(params))).then(
                (res) => {
                  backDrop.setBackDrop({
                    ...backDrop,
                    open: false,
                  });
                  if (!res.payload.error) {
                    if (!isDraft && !isEdit) {
                      setIsChangesSaved(true);
                    }

                    alert.setSnack({
                      ...alert,
                      open: true,
                      severity: AlertProps.severity.success,
                      msg: `Notification has been saved as draft submitted  ${
                        isDraft
                          ? "saved as draft"
                          : isEdit
                          ? "updated"
                          : "submitted"
                      } Successfully!!!`,
                      vertical: AlertProps.vertical.top,
                      horizontal: AlertProps.horizontal.center,
                    });

                    if (!isDraft) {
                      getSectionId(forms[0]);
                    }

                    if (isDraft) {
                      setAllowDraft(true);
                      getSectionId(forms[0]);
                      // history.push({
                      //   pathname: Routes.notify,
                      //   search: `?id=${parent_form_id}&tab=0`,
                      // });
                    }

                    if (!isDraft && !isEdit) {
                      // history.push({
                      //   pathname: Routes.notify,
                      //   search: `?id=${parent_form_id}&tab=0`,
                      // });
                    }

                    if (isEdit) {
                      navigateToListingPage(parent_form_id);
                    } else {
                      if (!isDraft) {
                        setHistoryOfViewedForms([]);
                        setFormsWithAnswer({});
                        setView([]);
                        restForm();
                      } else {
                        setHistoryOfViewedForms([]);
                        setFormsWithAnswer({});
                        setView([]);
                        restForm();
                      }
                    }
                  } else {
                    backDrop.setBackDrop({
                      ...backDrop,
                      open: false,
                    });
                    alert.setSnack({
                      ...alert,
                      open: true,
                      severity: AlertProps.severity.error,
                      msg: res.payload.data.message,
                      vertical: AlertProps.vertical.top,
                      horizontal: AlertProps.horizontal.center,
                    });
                  }
                }
              );
            } else {
              backDrop.setBackDrop({
                ...backDrop,
                open: false,
              });
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: response.payload.data.message,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
            }
          })
          .catch((err) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something went wrong! Try Again",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          });
      } else {
        if (canIableToMoveNextForm(forms[currentIndex])) {
          getSectionId(forms[currentIndex]);
        } else {
          onNextBtnClicked(currentIndex + 1, forms[currentIndex], false);
        }
      }
    }
  };

  const navigateToListingPage = (parent_form_id) => {
    let route = "";
    let tab = "";

    switch (secName) {
      case 0: //notify
        route = Routes.notify;
        tab = 0;
        break;

      case 1: //Vertifications
        route = Routes.verifications;
        tab = 1;
        break;

      case 2: //Investigations
        route = Routes.case_registration;
        tab = 1;
        break;

      case 3: //Case Registrations
        route = Routes.investigations;
        tab = 1;
        break;

      default:
        break;
    }
    history.push({
      pathname: route,
      search: `?id=${parent_form_id}&tab=${tab}`,
    });
  };

  const onPrevBtnClicked = (currentIndex, currentForm) => {
    if (currentIndex !== 1) {
      if (canIableToMoveNextForm(forms[currentIndex - 2])) {
        getSectionId(forms[currentIndex - 2]);
      } else {
        onNextBtnClicked(currentIndex - 3, forms[currentIndex], false, true);
      }
    }
  };

  const onChange = (index, data, value) => {
    if (data?.dependencies?.length > 0) {
      if (value?.value == data?.dependencies[0]?.option[0]?.value) {
        let newForms = formsWithAnswer;
        newForms[data?.id] = {
          id: data?.id,
          answer: value,
          questions: data?.question,
          tag: data?.tag,
        };

        setFormsWithAnswer({
          ...newForms,
        });
        onAnswerUpdated(newForms);
      } else if (value == data?.dependencies[0]?.option[0]?.value) {
        let newForms = formsWithAnswer;
        newForms[data?.id] = {
          id: data?.id,
          answer: value,
          questions: data?.question,
        };
        setFormsWithAnswer({
          ...newForms,
        });
        onAnswerUpdated(newForms);
      } else if (value?.value != undefined) {
        let newForms = formsWithAnswer;
        let targetid = data?.dependencies[0]?.target?.id;
        newForms[targetid] = {
          id: targetid,
          answer: "",
          questions: data?.question,
        };
        newForms[data?.id] = {
          id: data?.id,
          answer: value,
          questions: data?.question,
        };
        setFormsWithAnswer({
          ...newForms,
        });
        onAnswerUpdated(newForms);
      } else if (value?.value == undefined) {
        let newForms = formsWithAnswer;
        let targetid = data?.dependencies[0]?.target?.id;
        newForms[targetid] = {
          id: targetid,
          answer: undefined,
          questions: data?.question,
        };
        newForms[data?.id] = {
          id: data?.id,
          answer: value,
          questions: data?.question,
        };
        setFormsWithAnswer({
          ...newForms,
        });
        onAnswerUpdated(newForms);
      }
    } else {
      if (data?.ui_component == "input_text") {
        if (data?.validation?.regexValidation?.value == "timeRegex") {
          let newForms = formsWithAnswer;
          newForms[data?.id] = {
            id: data?.id,
            answer: value,
            questions: data?.question,
          };
          setFormsWithAnswer({
            ...newForms,
          });
          onAnswerUpdated(newForms);
        } else {
          let newForms = formsWithAnswer;
          newForms[data?.id] = {
            id: data?.id,
            answer: value,
            questions: data?.question,
          };
          setFormsWithAnswer({
            ...newForms,
          });
          onAnswerUpdated(newForms);
        }
      } else {
        let newForms = formsWithAnswer;
        newForms[data?.id] = {
          id: data?.id,
          answer: value,
          questions: data?.question,
        };
        setFormsWithAnswer({
          ...newForms,
        });
        onAnswerUpdated(newForms);
      }
    }
  };

  React.useEffect(() => {
    if (currentForm?.framed_questions?.[0]?.priority === 0) {
      let answer =
        formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]?.answer ??
        "No";
      setSmartAnswer(answer === "Yes");
    } else {
      setSmartAnswer(false);
    }

    // setView([currentIndex, currentForm]);
    // eslint-disable-next-line
  }, [currentForm, formsWithAnswer]);

  const canIshowSmartQuestion = (currentForm) => {
    return currentForm?.framed_questions?.[0]?.priority === 0;
  };

  const isSmartAnswerCheckNeed = (currentForm) => {
    if (currentForm?.framed_questions?.[0]?.priority === 0) {
      return smartAnswer;
    } else {
      return true;
    }
  };

  const canIVisibleHere = (question) => {
    if (question?.dependent) {
      let valueArr = question?.dependent?.option?.map((_) => _.value) ?? [];

      let ansIndex =
        formsWithAnswer[question?.dependent?.parent_question_id]?.answer
          ?.value ??
        formsWithAnswer[question?.dependent?.parent_question_id]?.answer;

      if (Array.isArray(ansIndex)) {
        let canIBreak = false;
        for (let index = 0; index < valueArr.length; index++) {
          const value = valueArr[index];
          for (let i = 0; i < ansIndex.length; i++) {
            const v = ansIndex[i];
            if (v === value) {
              canIBreak = true;
              break;
            }
          }
          if (canIBreak) {
            break;
          }
        }
        return !canIBreak;
      }

      if (valueArr.indexOf(ansIndex) > -1) {
        return false;
      }
      return true;
    }
    return false;
  };
  // eslint-disable-next-line
  const getButtonName = () => {
    if (currentIndex === forms.length) {
      if (savingJSON.loading) {
        if (isEdit) {
          return "Updating...";
        } else {
          return "Submitting...";
        }
      } else {
        if (isEdit) {
          if (isReadyOnly) {
            if (currentForm.is_investigator_section) {
              return "Investigated";
            } else {
              return (
                <Typography variant="body1" className={classes.nxtBtn}>
                  Next <ChevronRightIcon fontSize="small" />
                </Typography>
              );
            }
          } else {
            return "Submit";
          }
        } else {
          return "Submit";
        }
      }
    } else {
      if (isReadyOnly) {
        if (currentForm.is_investigator_section) {
          return "Next";
        } else {
          return (
            <Typography variant="body1" className={classes.nxtBtn}>
              Next <ChevronRightIcon fontSize="small" />
            </Typography>
          );
        }
      } else {
        return "Next";
      }
    }
  };

  React.useEffect(() => {
    // eslint-disable-next-line
    currentButton = getButtonName();

    if (Object.keys(formsWithAnswer).length > 4) {
      setIsChangesSaved(false);
    }

    // eslint-disable-next-line
  }, [getButtonName]);

  const giveFormLayoutType = () => {
    if (currentForm?.formLayoutType === "1 Column Layout") {
      return 12;
    } else if (currentForm?.formLayoutType === "2 Column Layout") {
      return 6;
    } else {
      return 4;
    }
  };

  const giveMeSM = (ui_component) => {
    if (
      ui_component === "custom_table" ||
      ui_component === "custom_table_2" ||
      ui_component === "location_level"
    ) {
      return 12;
    } else {
      return 6;
    }
  };

  const giveMeMD = (ui_component) => {
    if (
      ui_component === "custom_table" ||
      ui_component === "custom_table_2" ||
      ui_component === "location_level"
    ) {
      return 12;
    } else {
      return giveFormLayoutType();
    }
  };

  const checkSectionType = () => {
    if (statusUpdateData?.verifier_status === 3 && secName === 0) {
      return true;
    } else if (statusUpdateData?.verifier_status === 3 && secName === 1) {
      return true;
    } else if (statusUpdateData?.verifier_status === 10 && secName === 1) {
      return true;
    } else if (statusUpdateData?.registration_status === 8 && secName === 1) {
      return true;
    } else if (statusUpdateData?.registration_status === 10 && secName === 2) {
      return true;
    } else if (statusUpdateData?.registration_status === 8 && secName === 2) {
      return true;
    } else if (statusUpdateData?.registration_status === 6 && secName === 3) {
      return true;
    } else if (statusUpdateData?.investigator_status === 6 && secName === 3) {
      return true;
    } else if (currentForm?.section_type && currentForm?.section_type >= 0) {
      if (currentForm?.section_type === secName) {
        return false;
      } else {
        return true;
      }
    } else if (!currentForm?.section_type && secName === 0) {
      if (
        statusUpdateData?.notifier_status === 1 &&
        statusUpdateData?.verifier_status === 10
      ) {
        return false;
      } else if (statusUpdateData?.notifier_status === 1) {
        return true;
      } else {
        return false;
      }
    } else if (currentForm?.is_investigator_section && secName === 3) {
      return false;
    } else {
      return true;
    }
  };

  const getOPtionValur = async (frq) => {
    let optionvalue = [];
    let data = {};
    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "QDMQueries",
      filter: `QDMQueries.queryid=='${frq?.QueryId}'`,
      return_fields: "QDMQueries.arangoQuery",
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
      params
    );
    if (res?.result?.length > 0) {
      let ArangoQuery = res?.result[0];

      let Params1 = {
        db_name: process.env.REACT_APP_DB,
        query: ArangoQuery,
      };

      let resValue = await AxiosCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/api/execute_aql`,
        Params1
      );

      resValue.map((data) => {
        let Obj = {
          label: data?.label,
          value: data?.value,
        };
        optionvalue.push(Obj);
      });
    }
    return optionvalue;
  };

  // console.log("formwithanswers", formsWithAnswer);

  const GetOptions = (frq) => {
    async function name(frq) {
      const data = await getOPtionValur(frq);
      return data;
    }
    const result = name(frq).then((res) => res);
    return result;
  };

  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <LinearProgressWithLabel
            total={forms?.length ?? 1}
            progress={currentIndex}
            color={theme.palette.success.main}
          />
        </Grid>

        <Grid
          item
          xs={12}
          className={classes.header}
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <div className={classes.headerWrappper}>
            <div className={classes.headerWrappper1}>
              {/* <Tooltip title="View Section" placement="top">
                <IconButton
                  // className={classes.menuIcon}
                  id="ViewSection"
                  onClick={() => {
                    viewSection();
                  }}
                >
                  <MenuIcon color="primary" />
                </IconButton>
              </Tooltip> */}
            </div>
            <div className={classes.headerWrappper2}>
              <Typography
                id="FormTitle"
                variant="body1"
                color="textPrimary"
                className={classes.formTitle}
              >
                {currentForm?.section_name}
              </Typography>
            </div>

            <div className={classes.headerWrappper3}>
              {currentForm?.is_skippable && (
                <Button
                  className={classes.skipBtn}
                  id="skipBtn"
                  style={{ marginRight: 8 }}
                  size="small"
                  variant="text"
                  disabled={savingJSON.loading}
                  onClick={() => {
                    onSkipBtnClicked(currentIndex, currentForm);
                  }}
                >
                  {"Skip"}
                </Button>
              )}

              {currentIndex !== 1 && (
                <Button
                  id="Prev"
                  style={{ marginLeft: 8, marginRight: 8 }}
                  size="small"
                  variant="text"
                  className={classes.savebtn}
                  disabled={savingJSON.loading}
                  onClick={() => onPrevBtnClicked(currentIndex, currentForm)}
                >
                  {"Prev"}
                </Button>
              )}

              <Button
                id="objectbutton"
                size="small"
                variant="contained"
                color="primary"
                className={classes.savebtn}
                disabled={
                  savingJSON.loading
                    ? savingJSON.loading
                    : currentIndex === forms.length && checkSectionType()
                    ? true
                    : false
                }
                onClick={() => onNextBtnClicked(currentIndex, currentForm)}
              >
                {getButtonName()}
              </Button>

              {/* {secName === 0 && !isReadyOnly && (
                <Button
                  id="saveAs"
                  size="small"
                  variant="outlined"
                  className={classes.draftbtn}
                  disabled={checkSectionType()}
                  onClick={() =>
                    onSaveAsDraftClicked(currentIndex, currentForm, true)
                  }
                >
                  Save as Draft
                </Button>
              )} */}

              {isCancel && (
                <Button
                  id="cancel"
                  size="small"
                  variant="outlined"
                  className={classes.draftbtn}
                  //disabled={checkSectionType()}
                  onClick={() => isCancelFunc()}
                >
                  cancel
                </Button>
              )}
            </div>
          </div>
          {/* <Grid item style={{ margin: "15px 0" }}>

          </Grid> */}
          {/* {
            <Grid item style={{ paddingRight: 8 }}>
              {currentForm?.is_skippable && (
                <Button
                  className={classes.skipBtn}
                  id="skipBtn"
                  style={{ marginRight: 8 }}
                  size="small"
                  variant="text"
                  disabled={savingJSON.loading}
                  onClick={() => {
                    onSkipBtnClicked(currentIndex, currentForm);
                  }}
                >
                  {"Skip"}
                </Button>
              )}

              {currentIndex !== 1 && (
                <Button
                  id="Prev"
                  style={{ marginLeft: 8, marginRight: 8 }}
                  size="small"
                  variant="text"
                  className={classes.savebtn}
                  disabled={savingJSON.loading}
                  onClick={() => onPrevBtnClicked(currentIndex, currentForm)}
                >
                  {"Prev"}
                </Button>
              )}

              {
                <Button
                  id="objectbutton"
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.savebtn}
                  disabled={
                    savingJSON.loading
                      ? savingJSON.loading
                      : currentIndex === forms.length && checkSectionType()
                      ? true
                      : false
                  }
                  onClick={() => onNextBtnClicked(currentIndex, currentForm)}
                >
                  {getButtonName()}
                </Button>
              }

              {secName === 0 && !isReadyOnly && (
                <Button
                  id="saveAs"
                  size="small"
                  variant="outlined"
                  className={classes.draftbtn}
                  disabled={checkSectionType()}
                  onClick={() =>
                    onSaveAsDraftClicked(currentIndex, currentForm, true)
                  }
                >
                  Save as Draft
                </Button>
              )}
            </Grid>
          } */}
        </Grid>

        <Grid>
          <Prompt
            when={
              isChangesSaved === false &&
              currentButton !== "Submit" &&
              !allowDraft &&
              (currentButton === "Submit" ||
                (!checkSectionType() && !isReadyOnly))
            }
            message={`Are you sure you want to Leave the Form ? `}
          />
        </Grid>
        {canIshowSmartQuestion(currentForm) && (
          <Grid item xs={12} className={classes.uiComponentWrapper}>
            <ComponentToRender
              data={currentForm?.framed_questions?.[0]}
              value={
                formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]
                  ?.answer ?? ""
              }
              index={0}
              onChange={onChange}
            />
          </Grid>
        )}

        <Divider variant="fullWidth" />
        <Grid container>
          {isSmartAnswerCheckNeed(currentForm) &&
            currentForm?.framed_questions &&
            currentForm?.framed_questions
              ?.filter((frQ) => frQ?.priority !== 0)
              .map((frQ, i) => {
                let disabled = canIVisibleHere(frQ);
                return (
                  <>
                    {!disabled ? (
                      <Grid
                        item
                        xs={12}
                        sm={giveMeSM(frQ?.ui_component)}
                        md={giveMeMD(frQ?.ui_component)}
                        key={i}
                        className={classes.uiComponentWrapper}
                        id={"Grid" + i}
                      >
                        {frQ?.QueryId === "" ? (
                          <ComponentToRender
                            data={frQ}
                            isrequired={frQ.is_required}
                            value={formsWithAnswer?.[frQ.id]?.answer ?? ""}
                            index={i}
                            onChange={onChange}
                            isReadyOnly={checkSectionType()}
                            errorValidation={errorValidation[frQ.id]}
                            disabled={disabled}
                          />
                        ) : (
                          <ComponentToRender
                            data={frQ}
                            isrequired={frQ.is_required}
                            value={formsWithAnswer?.[frQ.id]?.answer ?? ""}
                            index={i}
                            onChange={onChange}
                            isReadyOnly={checkSectionType()}
                            errorValidation={errorValidation[frQ.id]}
                            disabled={disabled}
                            GetOptions={GetOptions(frQ).then((res) => res)}
                          />
                        )}
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
        </Grid>
      </Grid>
    </div>
  );
};
