import axios from "axios";
import React, { useContext } from "react";
import { MainPanel, SidePanel } from "../../../components";
import { AlertContext, BackdropContext } from "../../../contexts";
import { AlertProps } from "../../../utils";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { async } from "taskviewersurveillance";

export const ListPanel = (props) => {
  const {
    queueId,
    scenarioId,
    each,
    stateid,
    scenarioState,
    getScenarioStateCount = () => false,
    getScenarioStateData,
    updateScenaorio,
    setValue,
    updateFavState,
    favtab,
    PermissionData,
    setQueue = () => false,
    refresh
  } = props;
  let ispharamcyclicked = true;
  const alertMessage = React.useContext(AlertContext);
  const [treedata, setTreedata] = React.useState([]);
  const [logState, setState] = React.useState([]);
  const [selectedTask, setSelectedTask] = React.useState({});
  const [alltickets , setAlltickets] = React.useState([]);
  const [isLoading, Setloading] = React.useState(false);
  const [config, setconfig] = React.useState([]);
  const [timeline, setTimeline] = React.useState([]);
  const [Aligndatechecked, setAligndatechecked] = React.useState(false);
  const [Alignpharmacychecked, setAlignpharmacychecked] = React.useState(false);
  const db = sessionStorage.getItem("ProjectDbname");
  const [alignment, setAlignment] = React.useState("");
  const backdrop = useContext(BackdropContext);
  const updateTimeLine = (value) => {
    setTimeline(value);
  };

  React.useEffect(() => {
    if (queueId && scenarioId) {
      getTaskViewerConifg(queueId, scenarioId, each, stateid, favtab);
    }
    setSelectedTask({});
    //eslint-disable-next-line
  }, [queueId, scenarioId, favtab, stateid , refresh]);

  const getFavouriteList = async (
    queueId,
    scenarioId,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    field10,
    field11,
    field12,
    field13,
    table,
    favtab
  ) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}' and QDMATPtransactionlog.favourite==true `,
        sort: `QDMATPtransactionlog._key DESC`,
        return_fields: `{field1:QDMATPtransactionlog.payload.inputDoc.${field1},field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field3:QDMATPtransactionlog.payload.inputDoc.${field3},field4:QDMATPtransactionlog.payload.inputDoc.${field4},field5:QDMATPtransactionlog.payload.inputDoc.${field5},field6:QDMATPtransactionlog.payload.inputDoc.${field6},field7:QDMATPtransactionlog.payload.inputDoc.${field7},field8:QDMATPtransactionlog.payload.inputDoc.${field8},field10:QDMATPtransactionlog.payload.inputDoc.${field10},field11:QDMATPtransactionlog.payload.inputDoc.${field11},field12:QDMATPtransactionlog.payload.inputDoc.${field12},field13:QDMATPtransactionlog.payload.inputDoc.${field13},table:QDMATPtransactionlog.payload.inputDoc.${table},ticketId:QDMATPtransactionlog.payload.ticketId,state:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.result;
          if (favtab) {
            setState(data);
            updateFavState(data);
          } else {
            updateFavState(data);
          }

          Setloading(false);
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) { }
  };

  const getTaskViewerConifg = async (
    queueId,
    scenarioId,
    each,
    stateid,
    favtab
  ) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "Taskviewer_config",
        filter: `Taskviewer_config.queueid=='${queueId}' `,
        return_fields: `Taskviewer_config`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      Setloading(true);

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            let field1 = data?.field1 === "" ? "undefined" : data?.field1;
            let field2 = data?.field2 === "" ? "undefined" : data?.field2;
            let field3 = data?.field3 === "" ? "undefined" : data?.field3;
            let field4 = data?.field4 === "" ? "undefined" : data?.field4;
            let field5 = data?.field5 === "" ? "undefined" : data?.field5;
            let field6 = data?.field6 === "" ? "undefined" : data?.field6;
            let field7 = data?.field7 === "" ? "undefined" : data?.field7;
            let field8 = data?.field8 === "" ? "undefined" : data?.field8;
            let field9 = data?.field9 === "" ? "undefined" : data?.field9;
            let field10 = data?.field10 === "" ? "undefined" : data?.field10;
            let field11 = data?.field11 === "" ? "undefined" : data?.field11;
            let field12 = data?.field12 === "" ? "undefined" : data?.field12;
            let field13 = data?.field13 === "" ? "undefined" : data?.field13;
            let table = data?.table === "" ? "undefined" : data?.table;

            setconfig({
              field1: field1,
              field2: field2,
              field3: field3,
              field4: field4,
              field5: field5,
              field6: field6,
              field7: field7,
              field8: field8,
              field9: field9,
              field10: field10,
              field11: field11,
              field12: field12,
              field13: field13,
              table: table,
              tableConfig:
                data?.tableConfig === "" ? "undefined" : data?.tableConfig,
            });

            if (favtab) {
              getFavouriteList(
                queueId,
                scenarioId,
                field1,
                field2,
                field3,
                field4,
                field5,
                field6,
                field7,
                field8,
                field9,
                field10,
                field11,
                field12,
                field13,
                table,

                favtab
              );
            } else {
              getAlltransactionlog(
                queueId,
                scenarioId,
                field1,
                field2,
                field3,
                field4,
                field5,
                field6,
                field7,
                field8,
                field9,
                field10,
                field11,
                field12,
                field13,
                table,
                each,
                stateid
              );
            }
          } else if (res.data.error) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `taskviewer_config-${res.data.error}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  const getAlltransactionlog = async (
    queueId,
    scenarioId,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    field10,
    field11,
    field12,
    field13,
    table,
    each,
    stateid
  ) => {
    const db = sessionStorage.getItem("ProjectDbname");
    let reportingOrg = localStorage.getItem("ReportingOrg") ?? ""

    let adminQueryWithState = reportingOrg === "" ? 
      `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'and QDMATPtransactionlog.payload.statesid[0]=='${stateid}' `
     : `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'and QDMATPtransactionlog.payload.statesid[0]=='${stateid}' and QDMATPtransactionlog.payload.inputDoc.RerportingOrganization.value =='${reportingOrg}' `

    let adminQueryWithoutState = reportingOrg === "" ? `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'` : `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}' and QDMATPtransactionlog.payload.inputDoc.RerportingOrganization.value =='${reportingOrg}'`
    try {
      let params = each
        ? {
          db_name: `${db}`,
          entity: "QDMATPtransactionlog",
          // filter: `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'and QDMATPtransactionlog.payload.statesid[0]=='${stateid}'`,
          filter: adminQueryWithState,
          sort: `QDMATPtransactionlog._key DESC`,
          return_fields: `{field1:QDMATPtransactionlog.payload.inputDoc.${field1},field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field3:QDMATPtransactionlog.payload.inputDoc.${field3},field4:QDMATPtransactionlog.payload.inputDoc.${field4},field5:QDMATPtransactionlog.payload.inputDoc.${field5},field6:QDMATPtransactionlog.payload.inputDoc.${field6},field7:QDMATPtransactionlog.payload.inputDoc.${field7},field8:QDMATPtransactionlog.payload.inputDoc.${field8},field10:QDMATPtransactionlog.payload.inputDoc.${field10},field11:QDMATPtransactionlog.payload.inputDoc.${field11},field12:QDMATPtransactionlog.payload.inputDoc.${field12},field13:QDMATPtransactionlog.payload.inputDoc.${field13},table:QDMATPtransactionlog.payload.inputDoc.${table},ticketId:QDMATPtransactionlog.payload.ticketId,state:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id}`,
        }
        : {
          db_name: `${db}`,
          entity: "QDMATPtransactionlog",
          // filter: `QDMATPtransactionlog.queueIds=='${queueId}' and QDMATPtransactionlog.payload.scenarioId[0]=='${scenarioId}'`,
          filter:adminQueryWithoutState,
          sort: `QDMATPtransactionlog._key DESC`,
          return_fields: `{field1:QDMATPtransactionlog.payload.inputDoc.${field1},field2:QDMATPtransactionlog.payload.inputDoc.${field2},field9:QDMATPtransactionlog.payload.inputDoc.${field9},field3:QDMATPtransactionlog.payload.inputDoc.${field3},field4:QDMATPtransactionlog.payload.inputDoc.${field4},field5:QDMATPtransactionlog.payload.inputDoc.${field5},field6:QDMATPtransactionlog.payload.inputDoc.${field6},field7:QDMATPtransactionlog.payload.inputDoc.${field7},field8:QDMATPtransactionlog.payload.inputDoc.${field8},field10:QDMATPtransactionlog.payload.inputDoc.${field10},field11:QDMATPtransactionlog.payload.inputDoc.${field11},field12:QDMATPtransactionlog.payload.inputDoc.${field12},field13:QDMATPtransactionlog.payload.inputDoc.${field13},table:QDMATPtransactionlog.payload.inputDoc.${table},ticketId:QDMATPtransactionlog.payload.ticketId,state:first( for queuestates in queuestates filter queuestates._id==QDMATPtransactionlog.payload.statesid[0] return {states:queuestates.states,statesid:queuestates._id}),favourite:QDMATPtransactionlog.favourite,key:QDMATPtransactionlog._key,id:QDMATPtransactionlog._id}`,
        };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result;
            setState(data);
            getFavouriteList(
              queueId,
              scenarioId,
              field1,
              field2,
              field3,
              field4,
              field5,
              field6,
              field7,
              field8,
              field9,
              field10,
              field11,
              field12,
              field13,
              table,
              false
            );
            setTimeout(() => {
              Setloading(false);
            }, 1000);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      let data = {
        queueId: queueId,
        scenarioId: scenarioId,
        each: each,
        stateid: stateid,
        reportingOrgid: reportingOrg,
      };
      var requestOptions = {
        method: "post",
        url: ispharamcyclicked
          ? process.env.REACT_APP_TREEVIEW_BY_PHARMACY
          : process.env.REACT_APP_TREEVIEW_BY_DATE,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      await axios(requestOptions)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data.response;
            let Arr = [data];
            setTreedata(data);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };

  const getActionPerformedState = async (ticketId) => {
    const db = sessionStorage.getItem("ProjectDbname");
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId == '${ticketId}'`,
        return_fields: `QDMATPtransactionlog.payload.statesid`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      Setloading(true);

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            const data = res.data.result[0];
            const TabValue =
              scenarioState.findIndex((l) => data[0] === l._id) + 2;
            setValue(`${TabValue}`);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) { }
  };
  const updateselectedList = (ticketId, obj) => {
    let selectedValue = logState.filter((li) => li.ticketId == ticketId);
    let selectobj = selectedValue[0];
    selectobj["table"] = obj;
  };

  const onCardClicked = (cardData , data) => {
    let treevalue = [];
    treedata.forEach(function iter(screen) {
      if (screen?.str_id == cardData) {
        treevalue.push(screen);
      } else if (screen?.children?.length > 0) {
        screen?.children.forEach(iter);
      }
    });
    if (data?.length > 0) {
      setSelectedTask({});
      setAlltickets(data);
      setAlignment("");
    }
    else if(treevalue[0]?.ticketId != undefined)
    {
      let selectedvalue = logState.filter(
        (li) => li.ticketId == treevalue[0]?.ticketId
      );
      let arr = selectedvalue[0];
      setSelectedTask(arr);
      setAlltickets([])
      setAlignment("");
    }
    else {
      setSelectedTask({});
      setAlltickets([])
      setAlignment("");
    }
  };

  const onFavIconClicked = (data) => {
    addThisFavourite(data.key, data.favourite);
  };

  const setAlignByDate = (data) => {
    if (data == false) {
      ispharamcyclicked = true;
    } else {
      ispharamcyclicked = false;
    }
    setAligndatechecked(data);
    setAlignpharmacychecked(false);
    getTaskViewerConifg(queueId, scenarioId, each, stateid);
  };
  const setAlignByPharmacy = (data) => {
    ispharamcyclicked = true;
    setAlignpharmacychecked(data);
    setAligndatechecked(false);
    getTaskViewerConifg(queueId, scenarioId, each, stateid);
  };

  const addThisFavourite = async (key, favourite) => {
    const db = sessionStorage.getItem("ProjectDbname");
    try {
      let params = [
        {
          db_name: `${db}`,
          entity: "QDMATPtransactionlog",
          filter: {
            _key: `${key}`,
          },
          is_metadata: true,
          metadataId: `${process.env.REACT_APP_metaID}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            favourite: !favourite,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.Result[0];
          let isFav = data.properties.doc.favourite;
          if (!favtab) {
            getTaskViewerConifg(queueId, scenarioId, each, stateid, false);
            getFavouriteList(
              queueId,
              scenarioId,
              config.field1,
              config.field2,
              config.field3,
              config.field4,
              config.field5,
              config.field6,
              config.field7,
              config.field8,
              config.field9,
              config.field10,
              config.field11,
              config.field12,
              config.field13,
              config.table,
              false
            );
            getScenarioStateCount(queueId, scenarioId);
          } else {
            getTaskViewerConifg(queueId, scenarioId, each, stateid, true);
            getScenarioStateCount(queueId, scenarioId);
          }
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: isFav
              ? "Added to favourite list!"
              : "Removed from favourite list",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } catch (error) { }
  };

  const onUserActionPerformed = (ticketid) => {
    setAlignByPharmacy()
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading" });
    setSelectedTask({});
    setState([]);
    Setloading(true);
    setTimeout(async () => {
      ticketid.map(async (tic) =>{
        await getActionPerformedState(tic);
      })
      
      await getScenarioStateData(queueId, scenarioId);
      await getTaskViewerConifg(queueId, scenarioId, each, stateid, false);
      getScenarioStateCount(queueId, scenarioId);
      updateScenaorio(scenarioId);
      Setloading(false);
      if(ticketid?.length > 1)
      {
        window.location.reload(false);
      }
      backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
    }, 5000);
  };

  const updateState = (value) => {
    setAlignment(value);
  };

  return (
    <React.Fragment>
      <div
        style={{
          height: "100%",
          display: "flex",
          background: "#ffffff",
        }}
      >
        <SidePanel
          PermissionData={PermissionData}
          isLoading={isLoading}
          config={config}
          logState={logState}
          getTaskViewerConifg={getTaskViewerConifg}
          each={each}
          queueId={queueId}
          scenarioId={scenarioId}
          selectedTask={selectedTask}
          stateid={stateid}
          onCardClicked={onCardClicked}
          onFavIconClicked={onFavIconClicked}
          treedata={treedata}
          Aligndatechecked={Aligndatechecked}
          Alignpharmacychecked={Alignpharmacychecked}
          setAlignByDate={setAlignByDate}
          setAlignByPharmacy={setAlignByPharmacy}
          scenarioState={scenarioState}
          setQueue={setQueue}
        />
        <MainPanel
          scenarioId={scenarioId}
          PermissionData={PermissionData}
          isLoading={isLoading}
          alignment={alignment}
          updateState={updateState}
          logState={logState}
          selectedTask={selectedTask}
          scenarioState={scenarioState}
          onUserActionPerformed={onUserActionPerformed}
          updateTimeLine={updateTimeLine}
          timeline={timeline}
          config={config}
          updateselectedList={updateselectedList}
          alltickets={alltickets}
          setQueue={setQueue}
        />
      </div>
    </React.Fragment>
  );
};
