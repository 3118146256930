import React, { useEffect, useState } from "react";
import { OrderCard } from "./orderCard";
import { AxiosCall } from "../../api";
import { useLocation } from "react-router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";

const styles = {
  appBar: {
    backgroundColor: "white",
    borderBottom: "1px solid #e0e0e0",
    boxShadow: "none",
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
};

export const ViewCart = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [cartId, setCartId] = useState("");
  const [trackOrderData, setTrackOrderData] = useState([]);
  const goback = () => {
    if (props.goBack) {
      props.goBack();
    } else {
      history.push(Routes.myorders);
    }
  };

  const getTrackOrder = async () => {
    let object = {
      db_name: process.env.REACT_APP_DB,
      filter: {
        cartid: location?.state,
      },
      queryid: "842c83b0-1715-46b6-b80f-2ccfa1706b3b",
    };
    let res = await AxiosCall(
      "post",
      process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      object
    );
    let result = res;
    setTrackOrderData(result);
  };

  React.useEffect(() => {
    getTrackOrder();
  }, []);

  useEffect(() => {
    console.log("Location state:", location.state);
    if (location.state) {
      setCartId(location.state); 
    }
  }, [location.state]);

  return (
    <div>
      <AppBar position="static" style={styles.appBar}>
        <Toolbar>
          <IconButton edge="start" color="primary" onClick={goback}>
            <ArrowBackIcon />
          </IconButton>
          <Box style={styles.boxContainer}>
            <Typography
              style={{
                color: "#353434",
                marginRight: "40px",
                fontFamily: "IPMO_regular",
                fontSize: "17px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
              }}
            >
              Cart Id
            </Typography>
            <Typography variant="subtitle1" style={{ color: "rgba(38, 50, 56, 0.73)" }}>
              #{cartId}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>

      {trackOrderData.map((orderedItem) => {
        return (
          <OrderCard
            orderdate={orderedItem?.OrderDate}
            orderid={orderedItem?.OrderNo}
            invoicenumber={orderedItem?.InvoiceNo}
            paymentstatus={orderedItem?.PaymentStatus}
            duedate={orderedItem?.DueDate}
            itemname={orderedItem?.ItemName}
            manufacturer={orderedItem?.Manufacturer}
            dispensequantity={orderedItem?.DispenseQuantity}
            price={orderedItem?.Price}
            chip={orderedItem?.OrderStatus}
            productimage={orderedItem?.drugimage}
            currency={orderedItem?.currency}
          />
        );
      })}
    </div>
  );
};
