import {
  alpha,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AxiosCall } from "../../api";
import { FoodCard, TimeLine } from "../../components";
import Config from "../../config";
import { RatingForm } from "./rating";
import UpdateIcon from "@material-ui/icons/Update";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { ToastMessage } from "../../utils";
import { AlertContext, BackdropContext } from "../../contexts";
import { DataArray } from "@mui/icons-material";
import { async } from "central_pharma_binder";
import { AiOutlineDownload } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& .MuiDialog-scrollPaper": {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "center",
    },
    "& .MuiDialog-paperFullScreen": {
      width: "100%",
      height: "85%",
      margin: "0px",
      maxWidth: "100%",
      maxHeight: "none",
      borderRadius: "16px 16px 0 0",
    },
  },
  flexSpaceBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0",
  },
  flexBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  textResponsive: {
    flex: "1 1 auto",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem !important",
    },
  },
  group: {
    marginLeft: "8px !important",
    paddingLeft: 12,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  closeIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#fff",
    position: "absolute",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    top: -20,
    right: -20,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tableContainer: {
    // padding: "0px 16px",
    margin: "16px 16px 16px 0",
    boxShadow: theme.shadows[3],
    [theme.breakpoints.down("sm")]: {
      // padding: "0px 8px",
      margin: "4px 8px 0 0",
    },
  },
  table: {
    minWidth: 650,
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "38px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    borderRadius: "8px 8px 0 0",
  },
  closeIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "#fff",
    position: "absolute",
    cursor: "pointer",
    backgroundColor: theme.palette.primary.main,
    top: -20,
    right: -20,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    height: 400,
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 350,
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover,
    // color: theme.palette.common.white,
    opacity: "0.7",
    // background: "transparent",
    boxShadow: "none",
    fontSize: "0.75rem",
    fontWeight: "700",
    textTransform: "uppercase",
  },
  // body: {
  //   fontSize: "0.75rem",
  // },
}))(TableCell);

export const OrderHistory = (props) => {
  const classes = useStyles();
  const {
    orderHistory = [],
    user,
    getOrderDetails,
    onReorderClicked,
    handleCloseDialog,
    BedNumber,
    Difference,
    store,
    edit,
  } = props;
  // console.log("store", BedNumber)
  const [open, setOpen] = React.useState(false);
  const [selectItem, setSelectedItem] = React.useState({});
  const [ratingItems, setRatingItems] = React.useState([]);
  const [ratingMaster, setRatingMaster] = React.useState([]);
  const [openm, setOpenm] = React.useState(false);
  const [timeline, setTimeline] = React.useState([]);
  const [currantStatus, setCurrantStatus] = React.useState();
  const [mloading, setmloading] = React.useState(false);
  const [updateEdits, setUpdateeditz] = React.useState();
  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();
  const forPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const backdrop = useContext(BackdropContext);
  const alert = useContext(AlertContext);
  React.useEffect(() => {
    getRateValueFromCodingMaster();
  }, []);

  function ROW({ menu, store, edit }) {
    // console.log("menu", menu)
    const [mainstate, setMainstate] = useState(false);
    const [disable, setDisable] = React.useState(true);
    const [edits, setEdits] = React.useState(true);
    // console.log("edits", edits);

    const cancelled = () => {
      let finalstatus;
      for (let i = 0; i <= store.length - 1; i++) {
        let status = store[i];
        if (status[0] == menu?.drugdetails?.OrderStatusId) {
          finalstatus = false;
          break;
        } else {
          finalstatus = true;
        }
      }
      setDisable(finalstatus);
    };

    const Edited = () => {
      for (let i = 0; i <= edit.length - 1; i++) {
        let ed = edit[i];
        // console.log("ed", ed[0]);
        if (ed[0] == menu?.drugdetails?.OrderStatusId) {
          // console.log(ed[0], "true")
          setEdits(false);
          return false;
        }
      }
      return true;
    };

    React.useEffect(() => {
      cancelled();
      Edited();
    }, []);

    // const visible = menu?.drugdetails?.OrderStatusId == editable ? true : false;
    // console.log("visible", visible);

    function handleEdit(Orderid) {
      setMainstate(!mainstate);
    }

    // setDummy(menu?.drugdetails?.OrderStatusId)
    console.log("duedate", menu?.drugdetails?.dueDate?.length);
    return (
      <>
        <TableRow>
          <StyledTableCell style={{ width: "10%" }}>
            <Typography
              variant="caption"
              color={"textSecondary"}
              style={{
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {menu?.drugdetails?._key}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left" style={{ width: "25%" }}>
            <Typography
              variant="caption"
              style={{
                fontWeight: "600",
              }}
            >
              {menu?.drugdetails?.OrderItemCode?.itemname}
            </Typography>
          </StyledTableCell>
          {/* {BedNumber && (
            <StyledTableCell align="left" style={{ width: "15%" }}>
              <Typography variant="caption">
                {menu?.drugdetails?.PharmacyDetails?.BedNumber}
              </Typography>
            </StyledTableCell>
          )} */}
          {/* {BedNumber && (
            <StyledTableCell align="left" style={{ width: "15%" }}>
              <Typography variant="caption">
                {menu?.drugdetails?.SaleChannel}
              </Typography>
            </StyledTableCell>
          )} */}

          <StyledTableCell align="left" style={{ width: "8%" }}>
            <Typography variant="caption">
              {menu?.drugdetails?.SaleChannel}
            </Typography>
          </StyledTableCell>

          {/* <StyledTableCell
          align="left"
          style={{ width: "10%" }}
        >
          <Typography variant="caption">
            {menu?.OrderItemCode?.parntname}
          </Typography>
        </StyledTableCell> */}
          <StyledTableCell align="left" style={{ width: "8%" }}>
            <Typography
              variant="caption"
              color={"textSecondary"}
              style={{
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {menu?.drugdetails?.OrderStatus}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="left" style={{ width: "10%" }}>
            <Typography
              variant="caption"
              color={"textSecondary"}
              style={{
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {menu?.drugdetails?.PaymentStatus.length <= 0
                ? "payment pending"
                : menu?.drugdetails?.PaymentStatus}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="center" style={{ width: "7%" }}>
            <Typography
              variant="caption"
              color={"textSecondary"}
              style={{
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {menu?.drugdetails?.dueDate ? menu?.drugdetails?.dueDate : "-"}
            </Typography>
          </StyledTableCell>

          <StyledTableCell align="center" style={{ width: "5%" }}>
            <Typography
              variant="caption"
              color={"textSecondary"}
              style={{
                fontWeight: "600",
                textTransform: "uppercase",
              }}
            >
              {menu?.drugdetails?.OrderItemCode?.dispensequantity}
            </Typography>
          </StyledTableCell>

          {mainstate ? (
            <EditTable
              getOrderDetails={getOrderDetails}
              orderHistory={orderHistory}
              menu={menu}
              lists={menu}
              setMainstate={setMainstate}
              Difference={Difference}
            />
          ) : (
            <>
              <StyledTableCell align="center" style={{ width: "5%" }}>
                <Typography
                  variant="caption"
                  color={"textSecondary"}
                  style={{
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {menu?.drugdetails?.OrderItemCode?.quantity}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "5%" }}>
                <Typography
                  variant="caption"
                  color={"textSecondary"}
                  style={{
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {menu?.drugdetails?.OrderItemCode?.calculatedprice}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "5%" }}>
                <Typography
                  variant="caption"
                  color={"textSecondary"}
                  style={{
                    fontWeight: "600",
                    textTransform: "uppercase",
                  }}
                >
                  {menu?.drugdetails?.OrderItemCode?.totalprice}
                </Typography>
              </StyledTableCell>

              <StyledTableCell
                align="left"
                style={{ width: "8%", padding: "0px" }}
              >
                <Grid container>
                  <Tooltip title="Edit">
                    <Grid item style={{ padding: "0.2rem" }}>
                      {/* <Button color="primary" variant="contained" disabled={compare} onClick={()=>handleOrderreceived(menu?.ticketId)}>Received</Button> */}
                      <IconButton
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        disabled={edits}
                        onClick={() => handleEdit(menu)}
                      >
                        {/* <img style={{height:"25px", width:"25px",color:}} src="https://img.icons8.com/external-beshi-color-kerismaker/48/000000/external-Receive-delivery-beshi-color-kerismaker.png"/> */}
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <div style={{ padding: "0.2rem" }}>
                      <IconButton
                        disabled={
                          disable
                          // !== menu?.drugdetails?.OrderStatusId
                          //   ? true
                          //   : false
                        }
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        onClick={() =>
                          handleOrdercancel(menu?.drugdetails?.ticketId)
                        }
                      >
                        <CancelIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip title="Order Received">
                    <div style={{ padding: "0.2rem" }}>
                      <IconButton
                        style={{
                          height: "25px",
                          width: "25px",
                        }}
                        disabled={
                          menu?.drugdetails?.OrderStatusId ===
                          process.env.REACT_APP_ORDERRECEIVED
                            ? false
                            : true
                        }
                        id="Received"
                        onClick={() =>
                          handleOrderreceived(menu?.drugdetails?.ticketId)
                        }
                      >
                        <CallReceivedIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              </StyledTableCell>
            </>
          )}

          {/* <TableCell align="right">{row.protein}</TableCell> */}
        </TableRow>
      </>
    );
  }
  const getRateValueFromCodingMaster = async () => {
    let params = {
      db_name: Config.dbname,
      entity: "CodeableConceptMaster",
      filter:
        "CodeableConceptMaster.Type=='FOODRATING' &&CodeableConceptMaster.status==true && CodeableConceptMaster.activestatus==true",
      sort: "CodeableConceptMaster.text",
      return_fields:
        "MERGE(CodeableConceptMaster,{coding:FIRST(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod))})",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );

    let result = res?.result;

    setRatingMaster(result);
  };

  const openModel = (value, isEdit) => {
    setOpen(true);
    if (isEdit) {
      setRatingItems(value?.feedBack[0]?.Feedback);
      setSelectedItem({ ...value, isEdit });
    } else {
      let addontoarr =
        value.Addon.trim().length > 0
          ? value.Addon.split(",").map((l) => {
              return {
                title: l,
                rating: { value: 0, label: "" },
                feedback: "",
                image: "",
              };
            })
          : [];
      let list = [
        {
          title: value?.OrderItemcode?.MealCode?.MealDescription,
          rating: { value: 0, label: "" },
          feedback: "",
          image: "",
        },
        ...addontoarr,
      ];
      setRatingItems(list);
      setSelectedItem({ ...value, isEdit });
    }
  };

  const handleClose = (complete) => {
    setOpen(false);
    if (complete) {
      getOrderDetails();
    }
  };

  const getDateTOString = (value) => {
    const d = `${value}T00:00:00Z`;
    const date = new Date(d);
    return date.toDateString();
  };
  const getImageByType = (t) => {
    let obj = {
      "106 - Medical Supplies and Cosmetics": `${process.env.PUBLIC_URL}/images/aerosel.jfif`,
      "105 - Medications": `${process.env.PUBLIC_URL}/images/tablet.jpg`,
      OINTMENT: `${process.env.PUBLIC_URL}/images/Ointment.jpg`,
      GEL: `${process.env.PUBLIC_URL}/images/gell.jfif`,
      CAPSULE:
        "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    };

    // console.log("obj", obj);

    let src =
      "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80";

    return obj[`${t}`] ?? src;
  };
  const handleClosemodal = () => {
    setOpenm(false);
  };

  const getTransactionId = async (ticketId) => {
    try {
      setmloading(true);
      let params = {
        db_name: `${Config.dbname}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId=='${ticketId}'`,
        return_fields: "QDMATPtransactionlog",
      };
      let res = await AxiosCall(
        "post",
        `${Config.api_url}/api/read_documents`,
        params
      );
      let data = res.result[0];
      setCurrantStatus(data?.payload?.statesid[0]);
      // console.log("currentstatus", data?.payload?.statesid[0]);
      let SecnarioStateList = await getSenarioStateList(
        data?.payload?.scenarioId[0]
      );
      await getAduitLog(data._id, SecnarioStateList);
    } catch (error) {}
  };

  const getSenarioStateList = async (id) => {
    let params = {
      db_name: `${Config.dbname}`,
      entity: "queuescenariostatemapping",
      filter: `queuescenariostatemapping.scenarioid=='${id}' and queuescenariostatemapping.activestatus==true`,
      return_fields: "queuescenariostatemapping.states",
    };
    let res = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      params
    );
    let data = res.result[0];

    return data;
  };

  const getAduitLog = async (_id, scenarioState) => {
    try {
      let params = {
        db_name: `${process.env.REACT_APP_AuditDB}`,
        entity: "QDMATPtransactionlog_audit",
        filter: `QDMATPtransactionlog_audit.refkey=='${_id}'`,
        return_fields: "QDMATPtransactionlog_audit",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config).then((res) => {
        if (res.data.Code === 201) {
          let data = res.data.result;

          if (data.length > 0) {
            let decodeArr = data.map((l) => {
              let Payload = atob(l.payload);
              let Document = atob(l.document);
              return {
                ...l,
                payload: JSON.parse(Payload),
                document: JSON.parse(Document),
              };
            });
            let isArrHasState = decodeArr.filter(
              (l) =>
                l?.document?.payload?.statesid &&
                l?.document?.payload?.queueMetaData?.userAction?.userAction
            );

            let z = scenarioState.map((l) =>
              Object.assign(l, { statustime: "", userAction: "" })
            );

            for (let j = 0; j < z.length; j++) {
              for (let i = 0; i < isArrHasState.length; i++) {
                if (
                  z[j]._id === isArrHasState[i].document.payload.statesid[0]
                ) {
                  Object.assign(z[j], {
                    statustime: isArrHasState[i].statustime,
                    userAction:
                      isArrHasState[i].document?.payload?.queueMetaData
                        ?.userAction?.userAction,
                  });
                }
              }
            }
            setTimeline(z);
            setmloading(false);
          } else {
            let z = scenarioState.map((l) =>
              Object.assign(l, { statustime: "", userAction: "" })
            );
            setTimeline(z);
            setmloading(false);
          }
        }
      });
    } catch (error) {}
  };
  const onTimeLineClicked = (data) => {
    handleOpen();
    getTransactionId(data?.ticketId);
  };
  const handleOpen = () => {
    setOpenm(true);
  };

  const onReorderBtnClicked = (data) => {
    // console.log("Reorder", data);
    let list = [];
    data.forEach((l) => {
      list = [...list, l?.drugdetails?.OrderItemCode];
    });
    onReorderClicked(list, data);
    handleCloseDialog();
  };

  // const Edited = () => {
  //   for (let i = 0; i <= edit.length - 1; i++) {
  //
  //     let ed = edit[i];
  //     // console.log("ed", ed[0]);
  //     if (ed[0] == menu?.drugdetails?.OrderStatusId) {
  //       // console.log(ed[0], "true")
  //       setEdits(false);
  //       return false;
  //     }
  //   }
  //   return true
  // };

  const onDownloadInvoice = async (data) => {
    let config3 = {
      method: "POST",
      url: process.env.REACT_APP_INVOICE_DOWNLOAD_URL,
      headers: { "Content-Type": "application/json" },
      data: { cart_id: data },
    };
    let result = await axios(config3).then((res) => {
      return res;
    });

    let ans = result?.data?.data;

    if (ans.length !== 0) {
      for (let i = 0; i <= ans.length - 1; i++) {
        let links = ans[i];

        fetch(links?.Invoice_File_URL).then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");

            alink.download = links?.Invoice_ID + ".pdf";
            alink.href = fileURL;
            alink.click();
          });
        });
      }
    } else {
      ToastMessage("Invoice is not yet generated", "error", "top-center");
    }
  };

  const onDownloadDO = async (data) => {
    let config3 = {
      method: "POST",
      url: process.env.REACT_APP_DO_DOWNLOAD_URL,
      headers: { "Content-Type": "application/json" },
      data: { cart_id: data },
    };
    let result = await axios(config3).then((res) => {
      return res;
    });
    console.log("result", result?.data?.data);
    let ans = result?.data?.data;

    if (ans.length !== 0) {
      for (let i = 0; i <= ans.length - 1; i++) {
        let links = ans[i];

        fetch(links?.dofileurl).then((response) => {
          response.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");

            alink.download = "DO_" + links?.donumber + ".pdf";
            alink.href = fileURL;
            alink.click();
          });
        });
      }
    } else {
      ToastMessage(
        "Delivery order is not yet generated",
        "error",
        "top-center"
      );
    }
  };

  const handleOrderreceived = async (data) => {
    setLoading(true);
    let Orders = {
      ticketId: data,
      userAction: process.env.REACT_APP_USERACTION,
      data: { ticketId: data },
    };
    let res = await AxiosCall(
      "post",
      process.env.REACT_APP_PERFORMUSERACTION_NIFIAPI,
      Orders
    );
    let ret = res?.error;
    if (ret == false) {
      let statusinfo = {
        db_name: process.env.REACT_APP_DB,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId=='${data}'`,
        return_fields: "QDMATPtransactionlog.payload.inputDoc.OrderStatus",
      };
      let statusconfig = {
        url: process.env.REACT_APP_GETTASKINFO_URL,
        method: "POST",
        data: statusinfo,
        headers: { "Content-Type": "application/json" },
      };
      axios(statusconfig).then((resconfig) => {
        // console.log("resconfig", resconfig?.data?.result?.[0]);
        let statusid = resconfig?.data?.result?.[0];
        if (statusid == "CodingMaster/11440") {
          getOrderDetails();
        }
      });
      let statusupdate = {
        ticketId: data,
      };
      setTimeout(async () => {
        let receive = await AxiosCall(
          "post",
          process.env.REACT_APP_ORDER_STATUS_UPDATE_API,
          statusupdate
        );
        // console.log("receive", receive);
        if (receive?.response?.Code == 201) {
          getOrderDetails();
          alert.setSnack({
            ...alert,
            open: true,
            msg: "Order received",
            severity: "success",
          });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            msg: "Somthing went wrong",
            severity: "error",
          });
        }
      }, 2500);
    }
    setLoading(false);
    getOrderDetails();
  };

  const handleOrdercancel = async (data) => {
    setLoading(true);
    let params1 = {
      ticketId: data,
      userAction: process.env.REACT_APP_CANCEL_USERACTION,
      data: { ticketId: data },
    };
    let config1 = {
      url: process.env.REACT_APP_PERFORMUSERACTION_NIFIAPI,
      method: "POST",
      data: params1,
      headers: { "Content-Type": "application/json" },
    };

    await axios(config1)
      .then((res1) => {
        if (!res1?.data?.error) {
          let statusinfo = {
            db_name: process.env.REACT_APP_DB,
            entity: "QDMATPtransactionlog",
            filter: `QDMATPtransactionlog.ticketId=='${data}'`,
            return_fields: "QDMATPtransactionlog.payload.inputDoc.OrderStatus",
          };
          let statusconfig = {
            url: process.env.REACT_APP_GETTASKINFO_URL,
            method: "POST",
            data: statusinfo,
            headers: { "Content-Type": "application/json" },
          };
          axios(statusconfig).then((resconfig) => {
            // console.log("resconfig", resconfig?.data?.result?.[0]);
            let statusid = resconfig?.data?.result?.[0];
            if (statusid == "CodingMaster/11440") {
              getOrderDetails();
            }
          });
          let params2 = {
            ticketId: data,
          };
          let config2 = {
            url: process.env.REACT_APP_ORDER_STATUS_UPDATE_API,
            method: "POST",
            data: params2,
            headers: { "Content-Type": "application/json" },
          };
          setTimeout(() => {
            axios(config2)
              .then((res2) => {
                if (res2?.data?.response?.Code === 201) {
                  alert.setSnack({
                    ...alert,
                    open: true,
                    msg: "Order cancelled successfully",
                    severity: "success",
                  });
                } else {
                  alert.setSnack({
                    ...alert,
                    open: true,
                    msg: "something went wrong !",
                    severity: "error",
                  });
                }
                getOrderDetails();
              })
              .catch((err2) => {
                alert.setSnack({
                  ...alert,
                  open: true,
                  msg: "something went wrong !",
                  severity: "error",
                });
              });
          }, 2000);
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            msg: "something went wrong !",
            severity: "error",
          });
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: "something went wrong !",
          severity: "error",
        });
      });
    setLoading(false);
  };

  // const[id,setID]=useState("")

  // const buttonEnable = async() => {
  //   let btnparam = {
  //     db_name: "pintar2u",
  //     entity: "CodeableConceptMaster",
  //     filter:
  //       "CodeableConceptMaster.activestatus==true && CodeableConceptMaster.Type=='OrderCancelEnabled' && CodeableConceptMaster.status == true",
  //     return_fields:
  //       "MERGE({coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod).display)})",
  //   };
  //   let res = await AxiosCall("post", process.env.REACT_APP_ARANGO_URL_READ, btnparam);
  //   let maps =res.result.map((m)=>(m.coding))
  //    setID(maps)
  // }

  return isLoading ? (
    <Grid
      container
      style={{
        display: "-ms-flexbox",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Grid>
  ) : (
    <Grid container spacing={1}>
      {orderHistory?.map((l, index) => (
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Box className={classes.flexBox}>
            <UpdateIcon fontSize="small" color="primary" />
            <Typography
              variant="body1"
              display="block"
              color="primary"
              className={classes.textResponsive}
            >
              {getDateTOString(l?.OrderDate)}
            </Typography>
          </Box>

          {l?.druggroup.map((li) => {
            {
              /* console.log("cartid", li?.cart); */
            }
            return (
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Box className={classes.flexSpaceBtn}>
                  <Box className={classes.flexBox}>
                    <Typography
                      variant="body1"
                      display="block"
                      className={classes.textResponsive}
                    >
                      {/* Cart Id:{li?.cart.split("-")[0]} */}
                      Cart Id:{li?.cart}
                    </Typography>
                  </Box>

                  <div style={{ flex: "0 0 auto" }}>
                    <Button
                      variant="text"
                      color="primary"
                      style={{ padding: "0em 1.5em 0em 0em" }}
                      startIcon={<AiOutlineDownload />}
                      onClick={() => onDownloadDO(li?.cart)}
                    >
                      {"Delivery Order"}
                    </Button>
                    <Button
                      variant="text"
                      color="primary"
                      style={{ padding: "0em 1.5em 0em 0em" }}
                      startIcon={<AiOutlineDownload />}
                      onClick={() => onDownloadInvoice(li?.cart)}
                    >
                      {"Invoice"}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onReorderBtnClicked(li?.drgroup)}
                    >
                      {"Reorder"}
                    </Button>
                  </div>
                  {/* )} */}
                </Box>
                <Grid container spacing={1} className={classes.group}>
                  <TableContainer
                    className={classes.tableContainer}
                    component={Paper}
                  >
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">
                            Order ID
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {" "}
                            Item Name
                          </StyledTableCell>
                          {/* {BedNumber && (
                            <StyledTableCell align="left">
                              Pharmacy Name
                            </StyledTableCell>
                          )} */}
                          {/* {BedNumber && (
                            <StyledTableCell align="left">
                              Sale Channel
                            </StyledTableCell>
                          )} */}
                          <StyledTableCell align="left">
                            Sale Channel
                          </StyledTableCell>
                          {/* <StyledTableCell align="left">Parent Name</StyledTableCell> */}
                          <StyledTableCell align="left">
                            Order Status
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Payment Status
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Due Date (MM/DD/YYYY)
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Dispense Quantity
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Quantity
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            Price
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Total
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            Actions{" "}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {li?.drgroup?.map((menu, i) => {
                          return (
                            <ROW
                              menu={menu}
                              edit={edit}
                              key={`${i}${menu?.drugdetails?.OrderItemCode?.serialno}`}
                              Difference={Difference}
                              store={store}
                            />
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      ))}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        fullScreen
        onClose={handleClose}
        className={classes.dialogRoot}
      >
        <RatingForm
          handleClose={handleClose}
          selectItem={selectItem}
          ratingItems={ratingItems}
          user={user}
          ratingMaster={ratingMaster}
          setRatingItems={setRatingItems}
        />
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openm}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openm}>
          <div className={classes.paper}>
            <Box className={classes.Header}>
              <Typography>{"Order Status"}</Typography>
            </Box>
            {mloading ? (
              <div
                style={{
                  height: "calc(100% - 38px)",
                  overflowY: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <div style={{ height: "calc(100% - 53px)", overflowY: "auto" }}>
                <TimeLine
                  scenarioState={timeline}
                  currantState={currantStatus}
                />
              </div>
            )}

            <IconButton
              className={classes.closeIcon}
              onClick={() => handleClosemodal()}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Fade>
      </Modal>
    </Grid>
  );
};

function creditLimitMessagefunc(Difference, calculation) {
  let Total = calculation;
  let cr = Difference - Total;
  if (cr < 0) {
    ToastMessage(`Credit Limit exceeded`, "error", "top-center");
  }
  return "";
}

function EditTable(props) {
  const [state2, setState2] = useState(
    props?.menu?.drugdetails?.OrderItemCode?.quantity ?? ""
  );

  let calculation =
    props?.lists?.drugdetails?.OrderItemCode?.calculatedprice * state2;

  const Edtable = async () => {
    if (state2 < 1000) {
      editables();
      let param1 = [
        {
          db_name: process.env.REACT_APP_DB,
          entity: "QDMATPtransactionlog",
          is_metadata: false,
          filter: { ticketId: props?.lists?.drugdetails?.ticketId },
          doc: {
            payload: {
              inputDoc: {
                OrderItemCode: {
                  dispensequantity: parseInt(state2),
                  quantity: parseInt(state2),
                  totalprice: parseFloat(calculation),
                },
              },
            },
          },
        },
      ];
      // if(OrderStatus){}
      let updates = await AxiosCall(
        "post",
        process.env.REACT_APP_ARANGO_URL_UPSERT,
        param1
      );
      if (updates?.Code == 201) {
        setTimeout(props?.getOrderDetails, 100);
      }
    } else {
      ToastMessage(
        `Maximum Quantity Should less than 1000`,
        "error",
        "top-center"
      );
    }
  };
  const editables = async () => {
    let paramss = [
      {
        db_name: process.env.REACT_APP_DB,
        entity: "OrderTransaction",
        is_metadata: false,
        filter: { ticketId: props?.lists?.drugdetails?.ticketId },
        doc: {
          OrderItemCode: {
            quantity: parseInt(state2),
            dispensequantity: parseInt(state2),
            totalprice: parseFloat(calculation),
          },
        },
      },
    ];

    await AxiosCall("post", process.env.REACT_APP_ARANGO_URL_UPSERT, paramss);
    creditLimitMessagefunc(props?.Difference, calculation);
  };

  return (
    <>
      {/* <TableCell align="center" style={{ width: "10%" }}>
        <TextField
          style={{ width: "6rem" }}
          size="small"
          variant="outlined"
          name="dispense quantity"
        />
      </TableCell> */}
      <TableCell>
        <TextField
          style={{ width: "6rem" }}
          size="small"
          onChange={(e) => setState2(e.target.value)}
          variant="outlined"
          name="quantity"
          value={state2}
        />
      </TableCell>
      <TableCell>
        <Grid item spacing={2}>
          <Tooltip title="Submit">
            <IconButton
              onClick={Edtable}
              style={{
                height: "25px",
                width: "25px",
                padding: "5px",
              }}
            >
              <DoneOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton
              onClick={() => {
                props?.setMainstate(false);
              }}
              style={{
                height: "25px",
                width: "25px",
                padding: "5px",
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </TableCell>
    </>
  );
}
