import React from "react";
import { withNavBars } from "../../HOCs";
import {
  Typography,
  makeStyles,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Paper,
  Popover,
  Divider,
  Tooltip,
} from "@material-ui/core";
import ChartLayout from "./chartLayout";
import ClientDashBoard from "./clientDashBoard";
import { AxiosCall } from "../../utils";
import { checkWithIdm } from "atp-casbin-js";
import { LoadingScreen } from "../../components/loadingScreen";
import NotifierDashBoard from "./notifierDashboard";
import VerifierDashboard from "./verifierDashBoard";
// import { idmapis } from "surveillance-binder";
import { useCheckWithIDM } from "./hooks";
import Config from "../../config";
import { AxiosArangoCall } from "../../api";
import axios from "axios";
import SearchIcon from "@material-ui/icons/Search";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import jwt_decode from "jwt-decode";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FaClinicMedical } from "react-icons/fa";
import { TbBuildingHospital } from "react-icons/tb";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: theme.spacing(2),
    width: "100%",
    height: "100%",
    background: "#EFF1F4",
    overflowY: "auto",
  },
  itemSpacing: {
    padding: 10,
    position: "relative",
    border: "1px solid #FFFFFF",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 2px 4px #0000000A",
    borderRadius: "8px",
    opacity: 1,
    marginBottom: 10,
    [theme?.breakpoints.down("xs")]: {
      padding: "12px 6px",
    },
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //justifyContent: "space-around",
    flexWrap: "wrap",
  },
  mobViewColumn: {
    [theme?.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [personDetails, setPersonDetails] = React.useState({});
  const [pharmacy, setPharmacy] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [headPharmacy, setHeadPharmacy] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [search, setSearch] = React.useState("");
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [reason, setReason] = React.useState();
  const [indivreason, setIndivReason] = React.useState();
  const [pharmacyuser, setPahrmacyuser] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [tktid, setTktid] = React.useState("");
  // console.log("total", tktid);
  const history = useHistory();
  React.useEffect(() => {
    const data = getUserRole(localStorage.getItem("kclkid"));

  }, []);
    
  React.useEffect(() => {
    if(headPharmacy.length > 0){
      filterData?.map((v, index) => {
        if(v?.status === "Active"){
          history.push(Routes.PharmacyDashboard);
        }
      });
    }
  }, [headPharmacy]);

  // console.log("personDetails", personDetails);

  const getUserRole = async (id) => {
    var data = {
      db_name: process.env.REACT_APP_DB,
      entity: "Person",
      filter: `Person.keycloackid=='${id}' && Person.activestatus==true`,
      return_fields:
        "{roleDetail:Person.roleid[0],_id:Person._id,facility:Person.orgType,OrgId:Person.OrgID[0].id}",
    };

    var config = {
      method: "post",
      url: `${Config.api_url}/api/read_documents`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // console.log("lo", response.data);
        let details = response.data?.result?.[0];
        setPersonDetails(details);
        if (details?.facility !== "") {
          setPahrmacyuser(true);
        } else {
          setIsAdmin(true);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  // const [loading, setLoading] = React.useState(true);
  // const ViewClientDashBoard = useCheckWithIDM("Admin Dashboard") || true;
  // const ViewNotifierDashBoard = useCheckWithIDM("Pharmac Dashboard") || true;
  // const ViewVerifierDashBoard = useCheckWithIDM("Verifier Dashboard") || true;

  // React.useLayoutEffect(() => {
  //   // const checkSuperAdmin = async () => {
  //   //   let roleid = localStorage.getItem("RoleId");
  //   //   let roletype = await idmapis.getuserrole_from_permissionrolemapping(
  //   //     roleid
  //   //   );
  //   //   let issuperadmin =
  //   //     roletype?.result[0].rolename ===
  //   //     process.env.REACT_APP_SUPERADMIN_KEYNAME;
  //   //   if (issuperadmin) {
  //   //     setSuperAdminView(false)
  //   //     setLoading(false);
  //   //   } else {
  //   //     setLoading(false);
  //   //   }
  //   // };
  //   // checkSuperAdmin();
  // }, []);

  React.useEffect(() => {
    let token = localStorage.getItem("aTclk");
    let Email = jwt_decode(token);
    setEmail(Email?.email);
    var config = {
      method: "POST",
      url: process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      headers: { "Content-Type": "application/json" },
      data: {
        db_name: process.env.REACT_APP_DB,
        filter: {
          email: `${Email?.email}`,
          //"g3.btech@gmail.com",
        },
        queryid: process.env.REACT_APP_BED_QUERYID,
      },
    };
    var config1 = {
      method: "POST",
      url: process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES,
      headers: { "Content-Type": "application/json" },
      data: {
        db_name: process.env.REACT_APP_DB,
        filter: {
          email: `${Email?.email}`,
          //"g3.btech@gmail.com",
        },
        queryid: process.env.REACT_APP_STATUS_QUERYID,
      },
    };
    axios(config)
      .then((res) => {
        axios(config1)
          .then((res1) => {
            setTktid(res?.data[0]?.ticketId);
            // console.log("reso", res1?.data);
            localStorage.setItem(
              "length",
              res1?.data?.length == 0 ? true : false
            );
            localStorage.setItem("tktid", res?.data[0]?.ticketId);
            localStorage.setItem(
              "status",
              res?.data[0]?.RegistrationStatus == "CodingMaster/11457"
                ? true
                : false
            );

            let nullRemoverForParent =
              res?.data?.filter((v) => v !== null) ?? [];

            if (res?.data) {
              setPharmacy(res?.data ?? []);
              setFilterData(res?.data);
            }

            setHeadPharmacy(res1?.data);
            let reasonArray = res?.data;
            let reasonsDatas = [];

            for (let i = 0; i < reasonArray.length; i++) {
              // console.log("reasonArray", reasonArray[i]?._key);
              let key = reasonArray[i]?._key;
              let reasonData = reasonArray[i]?.Reason[0]?.filter((item) => {
                if (
                  item?.id ===
                  process.env.REACT_APP_REASON_REDO_ANSWER_QUE_ID ||
                  item?.id ===
                  process.env.REACT_APP_REASON_REDO_COMMENTS_QUE_ID ||
                  item?.id ===
                  process.env.REACT_APP_REASON_REJECT_ANSWER_QUE_ID ||
                  item?.id ===
                  process.env.REACT_APP_REASON_REJECT_COMMENTS_QUE_ID
                ) {
                  return item;
                }
              });

              reasonsDatas.push({ id: `${key}`, data: reasonData });
            }

            // console.log("reasonData3", reasonsDatas);
            setReason(reasonsDatas ?? []);
          })
          .catch((err) => {
            console.log({ err });
          });
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const handleSearch = (value) => {
    if (value) {
      var filteredvalue = pharmacy.filter((v) => {
        return (
          v?.["BedDescription"]
            ?.toLowerCase()
            ?.includes(value?.toLowerCase()) ||
          v?.["BedType"]?.toLowerCase()?.includes(value?.toLowerCase()) ||
          v?.["status"]?.toLowerCase()?.includes(value?.toLowerCase())
        );
      });
      setFilterData(filteredvalue);
    } else {
      setFilterData(pharmacy);
    }
    setSearch(value);
  };

  const handleRedoToRegistrationPage = (value) => {
    // console.log("valuewwww", value);
    if (value?.status === "Rejected") {
      history.push({
        pathname: Routes.userregistration,
        state: {
          //id: value?.ticketId,
          status: value?.status,
          Email: email,
        },
      });
    } else {
      history.push({
        pathname: Routes.userregistration,
        state: {
          id: value?.ticketId,
          Email: email,
          edit: true,
          personId: personDetails._id,
          status: value.status,
        },
      });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // console.log("dfg", reason);

  const openPop = (e, v) => {
    // console.log("vvv", v);
    // console.log("openReason", reason);
    let indiReason = reason?.filter((item) => {
      // console.log("openreItem", item?.id);
      if (item?.id === v?._key) {
        return item;
      }
    });

    // console.log("indiReason", indiReason[0]?.data);
    setIndivReason(indiReason[0]?.data);
    setAnchorEl(e.currentTarget);
  };
  // new Change
  //console.log("dvd", headPharmacy);
  // console.log("hj", filterData);

  return (
    <>
      <div className={classes.root}>
        <>
          {headPharmacy?.length === 0 ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" align="center">
                 Welcome to 
                 <br />
                {/* Pharmacy Procurement Platform */}
                Pintar2U
              </Typography>
            </div>
          ) : (
            <Grid container spacing={2}>
              {headPharmacy?.[0]?.status == true && (
                <Grid container item xs={12}>
                  <Paper style={{ width: "100%", padding: "5px" }}>
                    <Grid container xs={12} alignItems="center">
                      <Grid container item xs={9} alignItems="center">
                        <TbBuildingHospital
                          style={{ width: "30px", height: "25px" }}
                        />
                        <Typography variant="h5">
                          {headPharmacy?.[0]?.name?.[0] ??
                            headPharmacy?.[0]?.name?.[0]}
                        </Typography>
                      </Grid>
                      {pharmacy?.length > 0 && (
                        <Grid item xs={3}>
                          <TextField
                            onChange={(e) => {
                              handleSearch(e.target.value);
                            }}
                            value={search}
                            size="small"
                            variant="outlined"
                            placeholder="Search"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
              )}
              {filterData?.length !== 0 ? (
                <Grid container item spacing={2}>
                  {filterData?.map((v, index) => {
                    {/* console.log("VB", v); */ }
                    return (
                      <Grid
                        item
                        xs={12}
                      //className={`${classes?.rowCenter} ${classes?.itemSpacing} ${classes?.mobViewColumn}`}
                      >
                        <Paper style={{ width: "100%", padding: "10px" }}>
                          <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                          //className={`${classes?.rowCenter} ${classes?.itemSpacing} ${classes?.mobViewColumn}`}
                          >
                            <Grid
                              style={{
                                display: "flex",

                                marginLeft: "40px",
                              }}
                              xs={3}
                            >
                              <FaClinicMedical
                                style={{
                                  width: "25px",
                                  height: "20px",
                                  marginRight: "20px",
                                }}
                              />
                              <Tooltip title="Entity Name">
                                <Typography style={{ display: "inline" }}>
                                  {v?.BedDescription}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid xs={3}>
                              <Tooltip title="Contact Person">
                                <Typography style={{ display: "inline" }}>
                                  {v?.BedType}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid
                              style={{ display: "flex", alignItems: "center" }}
                              xs={3}
                            >
                              <Tooltip title="Status">
                                <Typography
                                  style={{
                                    display: "inline",
                                  }}
                                >
                                  {v?.status}
                                </Typography>
                              </Tooltip>
                              {(v?.status === "Redo Requested" ||
                                v?.status === "Rejected") &&
                                v?.Reason?.length > 0 && (
                                  <Tooltip
                                    style={{ marginLeft: "10px" }}
                                    title="Reason"
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={(e) => {
                                        openPop(e, v);
                                        /* histroy.push({
                                          pathname: Routes.Profile,
                                          state: tktid,
                                        }) */
                                      }}
                                    >
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              {(v?.status === "Redo Requested" ||
                                v?.status === "Waiting for Approval" ||
                                v?.status === "Active" ||
                                v?.status === "Re-Submitted") && (
                                  <Grid style={{ marginLeft: "100px" }}>
                                    <Tooltip title="Edit Form">
                                      <IconButton
                                        size="small"
                                        //disabled={
                                        //headPharmacy?.[0]?.status !==
                                        //process.env
                                        //.REACT_APP_REGISTRATION_REDO_STATE_NAME
                                        //}
                                        onClick={() =>
                                          v?.status === "Redo Requested" ||
                                            v?.status === "Waiting for Approval" ||
                                            v?.status === "Re-Submitted"
                                            ? handleRedoToRegistrationPage(v)
                                            : history.push({
                                              pathname: Routes.Profile,
                                              state: v,
                                            })
                                        }
                                      >
                                        <EditIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                            </Grid>

                            {v?.status === "Rejected" &&
                              v?.Reregistered != "Completed" && (
                                <Grid xs={1}>
                                  <Tooltip title="Register Again">
                                    <button
                                      id={
                                        "practitionerroletable-Add-New-button"
                                      }
                                      style={{
                                        backgroundColor: "#0071F2",
                                        color: "white",
                                        border: "none",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                        paddingLeft: "5px",
                                        paddingRight: "5px",
                                        borderRadius: "5px",
                                        fontFamily: "poppin",
                                        fontSize: "12px",
                                      }}
                                      onClick={() =>
                                        handleRedoToRegistrationPage(v)
                                      }
                                    >
                                      Register Again
                                    </button>
                                  </Tooltip>
                                </Grid>
                              )}
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                //className={`${classes?.rowCenter} ${classes?.itemSpacing} ${classes?.mobViewColumn}`}
                >
                  <Paper style={{ width: "100%", padding: "10px" }}>
                    <div
                      style={{
                        padding: "10px 20px 6px 38px",
                        textAlign: "center",
                      }}
                      className={classes.nodatafound}
                    >
                      <span>No data found.</span>
                    </div>
                  </Paper>
                </Grid>
              )}
            </Grid>
          )}
        </>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div style={{ height: "260px", width: "350px", overflow: "auto" }}>
          <div
            style={{
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "20px",
            }}
          >
            <h3>Reason</h3>
            <h3 onClick={handleClose} style={{ paddingRight: "20px", color: "#6B6D6D", cursor: "pointer" }}>x</h3>
          </div>
          <Divider />
          <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                paddingTop: "20px",
                paddingLeft: "20px"
              }}
            >
              <div>
                <Typography style={{ fontSize: "15px", color: "#007aff", width: "100px", }}>
                  {`${indivreason?.[0]?.questions} :`}
                </Typography>
              </div>
              <div style={{
                width: "150px",
              }}>
                {indivreason?.[0]?.answer?.map((item) => {
                  return (
                    <Typography style={{ fontSize: "15px", color: "#F52E0B" }}>
                      {reason?.[0]?.answer?.length > 1 ? `${item},` : `${item}`}
                    </Typography>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                paddingTop: "20px",
                paddingLeft: "20px"
              }}
            >
              <div>
                <Typography style={{
                  fontSize: "15px", color: "#007aff", width: "100px",
                }}>
                  {`${indivreason?.[1]?.questions} :`}
                </Typography>
              </div>
              <div style={{ width: "150px" }}>
                <Typography
                  style={{
                    fontSize: "15px",
                    color: "#F52E0B",
                  }}
                >
                  {indivreason?.[1]?.answer?.length > 1
                    ? `${indivreason?.[1]?.answer}`
                    : `${indivreason?.[1]?.answer}`}
                </Typography>
              </div>
            </div>

          </div>

        </div>
      </Popover>
    </>
  );
};

export default Dashboard;