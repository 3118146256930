import React from "react";
import {
  Divider,
  TextField,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../../router/routes";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import { AxiosCall, ToastMessage } from "../../../utils";
import { async } from "taskviewersurveillance";
import Config from "../../../config";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

function Changepw(props) {
  const history = useHistory();

  const [showPasswordold, setShowPasswordold] = React.useState(false);
  const [showPasswordnew, setShowPasswordnew] = React.useState(false);
  const [showPasswordcnfrm, setShowPasswordcnfrm] = React.useState(false);

  const [oldpw, setOldpw] = useState("");
  const [newpw, setNewpw] = useState("");
  const [confirmpw, setConfitmpw] = useState("");
  // console.log(oldpw, "oldpw", newpw);

  const handleUpdate = async () => {
    let token = localStorage.getItem("aTclk");
    let email = jwt_decode(token);
    // console.log(email);
    let params = {
      username: email.email,
      password: oldpw,
    };
    let customheader = { tenantid: process.env.REACT_APP_IDM_TENANT_ID };
    let getAccess = await AxiosCall("post", Config.login, params, customheader);
    // console.log(getAccess);
    if (getAccess.Code === "201") {
      if (newpw === confirmpw) {
        let param = {
          email: email.email,
          password: confirmpw,
        };
        let resetpw = await AxiosCall(
          "post",
          process.env.REACT_APP_KCLK_RESET,
          param,
          customheader
        );
        history.push(Routes.ChangepwSuccess);
        setTimeout(() => {
          history.push(Routes.login);
        }, 5000);
      } else {
        ToastMessage(
          `New Password and Confirm Password does not match`,
          "error",
          "top-center"
        );
      }
    } else {
      ToastMessage(`Old password is incorrect`, "error");
    }
    // console.log (process.env.REACT_APP_KCLK_RESET);
  };
  const handleAlert = () => {
    ToastMessage(
      `Old Password and New Password Should not Same `,
      "error",
      "top-center"
    );
  };

  const handleCancle = () => history.push(Routes.Dashboard);

  const handleClickShowPassword = () => setShowPasswordold((show) => !show);
  const handleClickShowPasswordnew = () => setShowPasswordnew((show) => !show);
  const handleClickShowPasswordcnfrm = () =>
    setShowPasswordcnfrm((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div style={{ borderRadius: "16px", backgroundColor: "#ECF0F8" }}>
      <Dialog open={true}>
        <DialogContent
          style={{
            padding: "0px",
            height: "23.5rem",
            width: "21rem",
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              padding: "1em 0em 1em 1em",
            }}
          >
            <Typography style={{ fontWeight: "600" }}>
              Change Password
            </Typography>
            <Grid item style={{ marginLeft: "7.5rem" }}>
              <IconButton onClick={handleCancle}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item style={{ padding: "1em 1em 0em 1em" }}>
            <Typography style={{ opacity: "70%", fontSize: "13px" }}>
              Old password
            </Typography>

            <OutlinedInput
              style={{ borderRadius: "8px", backgroundColor: "#ECF0F8" }}
              type={showPasswordold ? "text" : "password"}
              value={oldpw}
              onChange={(e) => setOldpw(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showPasswordold ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item style={{ padding: "1em 1em 0em 1em" }}>
            <Typography style={{ opacity: "70%", fontSize: "13px" }}>
              New password
            </Typography>
            <OutlinedInput
              style={{ borderRadius: "8px", backgroundColor: "#ECF0F8" }}
              type={showPasswordnew ? "text" : "password"}
              value={newpw}
              onChange={(e) => setNewpw(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPasswordnew}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showPasswordnew ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item style={{ padding: "1em 1em 0em 1em" }}>
            <Typography style={{ opacity: "70%", fontSize: "13px" }}>
              Confirm password
            </Typography>
            <OutlinedInput
              style={{ borderRadius: "8px", backgroundColor: "#ECF0F8" }}
              type={showPasswordcnfrm ? "text" : "password"}
              value={confirmpw}
              onChange={(e) => setConfitmpw(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPasswordcnfrm}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {!showPasswordcnfrm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              fullWidth
            />
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "2.2em 1em 1em 1em",
              gap: "0.5em",
            }}
          >
            {/* <Button onClick={handleCancle} size="small" variant="outlined">
              Cancel
            </Button> */}
            <Button
              onClick={oldpw == newpw ? handleAlert : handleUpdate}
              size="small"
              color="primary"
              variant="contained"
              fullWidth
              // disabled={newpw == confirmpw ? false : true}
            >
              Update
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Changepw;
