/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all helper functions.
 */

import Config from "../config";
import moment from "moment";
import { fromUnixTime, format } from "date-fns";

import { toast } from "react-toastify";

import { Routes } from "../router/routes";
import axios from "axios";
import { repolist as subNavBarArr } from "./constants";

export const giveMeRoleDataSecurity = (data) => {
  let roleDataSecurity = {
    country: [],
    level1: [],
    level2: [],
    level3: [],
    level4: [],
    level5: [],
    level6: [],
    level7: [],
    level8: [],
    level9: [],
    level10: [],
    level11: [],
    level12: [],
    disease: [],
    form: [],
    myRole: [],
    isOnlyNotifier: false,
  };

  let mapped_roles = [data?.mapped_roles?.[0] ?? {}];

  for (const key in mapped_roles) {
    const role = mapped_roles[key];

    //Checking is Notifier Only
    if (
      role?.previlages?.notify?.length > 0 &&
      role?.previlages?.notification?.length > 0 &&
      role?.previlages?.Caseverification?.length === 0 &&
      role?.previlages?.caseRegistration?.length === 0 &&
      role?.previlages?.investigation?.length === 0
    ) {
      roleDataSecurity.isOnlyNotifier = true;
    }

    roleDataSecurity.myRole.push(role.role_id);

    //Country
    if (role.data_security?.country?.value) {
      roleDataSecurity.country.push(role.data_security.country.value);
    }
    if (role.data_security?.country?.country_id) {
      roleDataSecurity.country.push(role.data_security.country.country_id);
    }

    //Disease & Forms
    role.data_security?.mappingTable?.forEach((mapTable) => {
      //Disease
      if (
        mapTable.disease?.value?.toLowerCase() !== "all" &&
        roleDataSecurity?.disease?.[0]?.value?.toLowerCase() !== "all"
      ) {
        roleDataSecurity.disease.push(mapTable.disease?.value);
      } else {
        roleDataSecurity.disease = ["all"];
      }

      //Forms
      if (
        mapTable.form?.value?.toLowerCase() !== "all" &&
        roleDataSecurity?.form?.[0]?.value?.toLowerCase() !== "all"
      ) {
        roleDataSecurity.form.push(mapTable.form?.form_id);
      } else {
        roleDataSecurity.form = ["all"];
      }
    });

    //Remove at sometime puting this as hot fix
    if (roleDataSecurity.form.length === 0) {
      roleDataSecurity.form = ["formId"];
    }

    //SelectedLevel in role
    role?.mapped_location?.forEach((loc) => {
      Object.keys(loc)?.forEach((mapLocKey) => {
        if (
          loc[mapLocKey].value?.toLowerCase() !== "all" &&
          roleDataSecurity?.[mapLocKey]?.[0]?.value?.toLowerCase() !== "all"
        ) {
          roleDataSecurity[mapLocKey].push(loc[mapLocKey].value);
        } else {
          roleDataSecurity[mapLocKey] = ["all"];
        }
      });
    });

    //SelectedLevel in role edges
    if (Array.isArray(role?.edges?.mapped_location)) {
      role?.edges?.mapped_location?.forEach((loc) => {
        Object.keys(loc)?.forEach((mapLocKey) => {
          if (
            loc[mapLocKey].value?.toLowerCase() !== "all" &&
            roleDataSecurity?.[mapLocKey]?.[0]?.value?.toLowerCase() !== "all"
          ) {
            roleDataSecurity[mapLocKey].push(loc[mapLocKey].value);
          } else {
            roleDataSecurity[mapLocKey] = ["all"];
          }
        });
      });
    }
  }

  return roleDataSecurity;
};

export const giveMeRouteBasedOnTodoNotify = (data) => {
  if (data.notify_type) {
    //Redirecting URL for Notifier
    if (["1", "5", "6", "7", "8"].indexOf(data.notify_type) > -1) {
      return `${Routes.edit_form_notify_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Verifier
    if (["2", "9", "10", "11"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_verify_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Case Regsitor
    if (["3", "12"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_register_parent}${data.form_id}/${data.entry_id}`;
    }

    //Redirecting URL for Investigator
    if (["4"].indexOf(data.notify_type) > -1) {
      return `${Routes.view_form_investigator_parent}${data.form_id}/${data.entry_id}`;
    }
  }
  return "";
};

export const giveMeReplacedNotifiyTodoText = (text = "", data = {}) => {
  //Replacing Date
  text = text.replace("<<Date>>", moment(data?.created_at).format("lll"));

  //Replace Form Name
  text = text.replace(
    "<<Form Name>>",
    data?.form_detail?.form_name ?? "a form"
  );

  let patient_name = `${
    data?.patient_detail?.patient_name?.title?.label ?? ""
  } ${data?.patient_detail?.patient_name?.first_name ?? ""} ${
    data?.patient_detail?.patient_name?.last_name?.[0] ?? ""
  }`;
  //Replace Patient Name
  text = text.replace(
    "<<Patient Name>>",
    patient_name.trim().length > 0 ? patient_name : "a patient"
  );

  //Replace Notifier Name
  text = text.replace(
    "<<Notifier User Name>>",
    data?.created_by_detail?.first_name ?? "A notifier"
  );

  //Replace Verifier Name
  text = text.replace(
    "<<Verifier User Name>>",
    data?.created_by_detail?.first_name ?? "A verifier"
  );

  //Replace Case Registor Name
  text = text.replace(
    "<<Case Registor User Name>>",
    data?.created_by_detail?.first_name ?? "A case registor"
  );

  //Replace Investigator Name
  text = text.replace(
    "<<Investigator User Name>>",
    data?.created_by_detail?.first_name ?? "A investigator"
  );

  return text;
};

/**
 * The below function will help in decide the component type
 */

export const ComponentType = (type) => {
  const optionComponentsList = [
    "smart skip",
    "popup_switch",
    "list_text",
    "custom_multi_select_tag",
    "input_text",
    "input_textarea",
    "input_date_picker",
    "input_number",
    "hours_minutes_range",
    "label",
    "note",
    "location",
    "custom_table",
    "custom_table_2",
    "mobile_no",
    "location_level",
    "date_of_birth",
    "file_upload",
    "Image_only_upload",
    "input_time_picker",
    "input_datetime_picker",
    "select_modal_dropdown_qdm",
  ];
  if (optionComponentsList.includes(type)) {
    return false;
  } else {
    return true;
  }
};

export const amIableToDoThis = (module, op, data) => {
  let isIamOk = false;

  let mapped_roles = data?.mapped_roles;
  for (const key in mapped_roles) {
    if (Object.hasOwnProperty.call(mapped_roles, key)) {
      const element = mapped_roles[key];
      isIamOk = element?.previlages?.[module]?.indexOf(op) > -1;
      break;
    }
  }

  return isIamOk;
};

export const giveMeSubNavBars = (role) => {
  let navBarIds = [];
  let mapped_roles = role?.mapped_roles;
  for (const key in mapped_roles) {
    if (Object?.hasOwnProperty?.call(mapped_roles, key)) {
      const element = mapped_roles?.[key];
      let rolePrivilages = element?.previlages ?? {};
      let previlageKeys = Object.keys(rolePrivilages).filter(
        (pk) => element?.previlages?.[pk].length > 0
      );
      navBarIds.push(...previlageKeys);
    }
    break;
  }
  return subNavBarArr
    .filter((sb) => navBarIds.indexOf(sb.id) > -1)
    .sort((a, b) =>
      a.prioriy > b.prioriy ? 1 : b.prioriy > a.prioriy ? -1 : 0
    );
};

export const giveMeStatusColors = (status_id) => {
  const statusColor = {
    //Investigation
    5: "#f6b26b",
    6: "green",

    //Case Registration
    7: "#f6b26b",
    8: "green",
    10: "red",
    11: "orange",

    //Case Verification
    2: "#f6b26b",
    3: "green",

    //Notified
    1: "#ffe599",
    9: "grey",
  };

  return statusColor[status_id];
};
export const giveMeCurrentStatus = (entry = {}, allStatus = []) => {
  // Checking Investigation Status
  if (
    entry?.registration_status === 8 &&
    [6, 5].indexOf(entry?.investigator_status) > -1
  ) {
    return {
      status: `${
        allStatus?.filter((_) => _.status_id === entry.investigator_status)?.[0]
          ?.status_name ?? ""
      }`,
      stage: "Investigation",
      color: giveMeStatusColors(entry.investigator_status),
    };
  }

  // Checking Case Registration Status
  if (
    entry?.verifier_status === 3 &&
    [7, 8, 10, 11].indexOf(entry?.registration_status) > -1
  ) {
    return {
      status: `${
        allStatus?.filter((_) => _.status_id === entry.registration_status)?.[0]
          ?.status_name ?? ""
      } `,
      stage: "Case Registration",
      color: giveMeStatusColors(entry.registration_status),
    };
  }

  // Checking Case Verifier Status
  if (
    entry?.notifier_status === 1 &&
    [2, 3, 10, 11].indexOf(entry?.verifier_status) > -1
  ) {
    return {
      status: `${
        allStatus?.filter((_) => _.status_id === entry.verifier_status)?.[0]
          ?.status_name ?? ""
      } `,
      stage: "Case Verification",
      color: giveMeStatusColors(entry.verifier_status),
    };
  }

  if ([1, 9].indexOf(entry?.notifier_status) > -1) {
    return {
      status: allStatus?.filter(
        (_) => _.status_id === entry?.notifier_status
      )?.[0]?.status_name,
      stage: "Notification",
      color: giveMeStatusColors(entry.notifier_status),
    };
  }

  return {
    status: "Status Unknown",
    stage: "Unknow",
    color: "black",
  };
};
export function todaydate() {
  var today = new Date();

  var dd = today.getDate();

  var mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return (yyyy + "-" + mm + "-" + dd).toString();
}

export const AxiosCall = async (method, url, params, header) => {
  try {
    let config = {
      method: method,
      url: `${url}`,
      headers: {
        "Content-Type": "application/json",
        ...(header && { ...header }),
      },
      data: params,
    };
    let response = await axios(config);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const Debounce = (func, wait) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(args);
    }, wait);
  };
};

export const ToastMessage = (message, severity, position) => {
  toast(message, {
    position: position ?? "top-right",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    type: severity,
  });
};

/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
  darkgreen: "darkgreen",
  defaultgreen: "defaultgreen",
  darkpurple: "darkpurple",
  defaultpurple: "defaultpurple",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "auth_token",
  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

/**
 * The below function convert the normal array of object to
 * {label: "",value:1} pair which is suitable for React Select
 * component.
 */
export let ConvertToReactSelect = (data, valueKey, labelKey) => {
  if (!data || !data?.length) {
    return [];
  }

  return data.map((val) => {
    return {
      ...val,
      value: val[valueKey],
      label: val[labelKey],
    };
  });
};

/**
 * The below function convert the uploaded file to base64 file.
 */
export let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

/**
 * The below function capitalize the given string.
 */
export let CapitalizeString = (string) => {
  if (!string) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * The below function convert the HEX code to RGBA
 */
export let ConvertHexToRGBA = (hex, opacity) => {
  if (hex) {
    let tempHex = hex.replace("#", "");
    let r = parseInt(tempHex.substring(0, 2), 16);
    let g = parseInt(tempHex.substring(2, 4), 16);
    let b = parseInt(tempHex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity / 100})`;
  }
  return null;
};

/**
 * The below function will open an document node in a full screen.
 */
export let OpenFullScreen = (id) => {
  let elem = document.getElementById(id);
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari & Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

/**
 * The below function will close the full screen of an document
 * node.
 */
export let CloseFullScreen = (id) => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

/**
 * The below function will scroll the page to the Top.
 */
export let ScrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const makeName = (nameObj = {}) => {
  const {
    prefix = "",
    given = "",
    text = "",
    suffix = "",
    family = "",
  } = nameObj;
  let prefixVal = prefix?.coding?.[0]?.display || prefix?.display || "";
  let suffixVal = suffix?.coding?.[0]?.display || suffix?.display || "";
  return `${prefixVal && prefixVal + "."}${text && text + " "}${
    given && given + " "
  }${(family ?? "") + " "}${suffixVal ?? ""}`;
};

export const makeNameForPract = (nameObj = {}) => {
  const {
    prefix = "",
    given = "",
    text = "",
    suffix = "",
    family = "",
  } = nameObj;
  let prefixVal = prefix?.[0]?.display || "";
  let suffixVal = suffix?.[0]?.display || "";
  return `${prefixVal && prefixVal + "."}${text && text + " "}${
    given && given + " "
  }${family && family + " "}${suffixVal && suffixVal}`;
};

export const getImgUrl = (fileId) =>
  fileId ? `${Config.downloadUrl}${fileId}` : "";

export const Logout = () => {
  sessionStorage.clear();
  localStorage.clear();
  window.location.replace("/login");
};

export const UploadFun = async (file) => {
  return new Promise(async (resolve, reject) => {
    let formData = new FormData();
    formData.append("files", file);
    await fetch(Config.uploadUrl, {
      method: "POST",
      body: formData,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((res) => {
        resolve({
          fileid: res.fileid,
          fileName: file.name,
          filetype: file.type,
          objectid: res.objectid,
          date: file?.lastModified,
        });
      })
      .catch((err) => {
        resolve({});
      });
  });
};

export const uploadFileList = (fileList) => {
  return new Promise(async (resolve, reject) => {
    if (!fileList && !Array.isArray(fileList)) {
      resolve([]);
      return;
    }
    let calls = fileList.map(async (file) => {
      return await UploadFun(file);
    });
    Promise.all(calls)
      .then((data) => {
        let dataList = [];
        data.map((val) => {
          if (val.fileid) {
            dataList.push({
              fileid: val.fileid,
              filetype: val.filetype,
              objectid: val.objectid,
              fileName: val.fileName,
              date: val?.date?.toString(),
            });
          }
          return false;
        });
        resolve(dataList);
      })
      .catch((err) => {
        resolve([]);
        console.log(err);
      });
  });
};

export function getOrderName(val = {}, loggedName) {
  const { accessionNum, userName } = val;
  if (accessionNum) {
    if (accessionNum === loggedName) {
      return accessionNum + " (You)";
    }
    return accessionNum;
  }

  if (userName) {
    if (userName === loggedName) {
      return userName + " (You)";
    }
    return userName;
  }
  return loggedName + " (You)";
}

export const GenerateLabelPayload = (
  // patientInfo,
  // vitals,
  // allergies,
  // diagnosis,
  // orderLine
  data
) => {
  let payload = {
    reportid: `${process.env.REACT_APP_PRESCRIPTION_PRINT}`,
    inputparams: {},
    result: [
      {
        Mandatory: { ...data },
      },
    ],
    // result: [
    //   {
    //     Mandatory: {
    //       MRN: patientInfo.alias,
    //       PatientName: makeName(patientInfo?.name?.[0]),
    //       Height: JSON.stringify(
    //         vitals?.component.find((val) => val.obscode === "HEIGHT")?.obsvalue
    //       ),
    //       Weight: JSON.stringify(
    //         vitals?.component.find((val) => val.obscode === "WEIGHT")?.obsvalue
    //       ),
    //       Sex: patientInfo?.gender?.display ?? "",
    //       DateOfBirth: patientInfo?.birthDate,
    //       Allergy: allergies?.map((val) => {
    //         return val?.shortdesc;
    //       }) ?? [""],
    //       Diagnosis: diagnosis?.map((val) => {
    //         return val?.icdshortname;
    //       }),
    //       HospitalLogo: "10012",
    //       PolicyDetails: "",
    //       RxDate_format: TimeFormatConversion(
    //         moment().unix(),
    //         getFormatForDateTimePicker("date")
    //       ), //TimeFormatConversion(moment().unix()),
    //       Orderline: getOrderLine(orderLine),
    //     },
    //     optional: {},
    //   },
    // ],
  };

  return payload;
};

export const getEntityTypeCode = (type) => {
  switch (type) {
    case "OT002": // Organization
      return "OT001"; // Enterprise
    case "OT003": // Facility
      return "OT002"; // Organization
    default:
      break;
  }
};

export const valitationFunc = (state, list) => {
  let error = false;
  let errorState = {};
  list.forEach((val) => {
    if (state[val] === null || state[val] === undefined) {
      errorState[val] = true;
      error = true;
    } else {
      if (typeof state[val] === "string") {
        if (!state[val]) {
          errorState[val] = true;
          error = true;
        }
      } else if (typeof state[val] === "object") {
        if (Array.isArray(state[val])) {
          if (state[val]?.length === 0) {
            errorState[val] = true;
            error = true;
          }
        } else {
          if (Object.keys(state[val])?.length === 0) {
            errorState[val] = true;
            error = true;
          }
        }
      }
    }
  });

  return {
    error,
    errorState,
  };
};

export const validationForm = (list, states) => {
  let error = false;
  let errorState = {};
  list.forEach((val) => {
    if (val.required) {
      if (
        val?.componet_type !== "select" &&
        states[val?.state_name]?.length === 0
      ) {
        errorState[val.state_name] = true;
      } else if (val?.componet_type === "select") {
        if (
          states[val?.state_name]
            ? Object.keys(states[val?.state_name])?.length === 0
            : true
        )
          errorState[val.state_name] = true;
      } else if (val?.componet_type === "textField_select") {
        if (
          states[val?.state_name]?.type
            ? Object.keys(states[val?.state_name]?.type)?.length === 0
            : true
        ) {
          errorState[val.state_name] = true;
        }
        if (states[val?.state_name]?.value?.length === 0) {
          errorState[val.state_name] = true;
        }
      }
    } else {
      errorState[val.state_name] = false;
    }
  });
  if (
    errorState &&
    Object.keys(errorState).length > 0 &&
    Object.keys(errorState).filter((val) => errorState[val] === true).length > 0
  ) {
    error = true;
  } else {
    error = false;
  }
  return {
    error,
    errorState,
  };
};

export const uploadImage = (fileList) => {
  return new Promise(async (resolve, reject) => {
    if (!fileList && !Array.isArray(fileList)) {
      resolve([]);
      return;
    }

    let calls = fileList?.map(async (file) => {
      return await UploadFun(file);
    });

    Promise.all(calls)
      .then((data) => {
        let dataList = [];
        data.map((val) => {
          if (val.fileid) {
            dataList.push({
              fileid: val.fileid,
              filetype: val.filetype,
              objectid: val.objectid,
              filename: val.fileName,
            });
          }
          return false;
        });

        resolve(dataList);
      })
      .catch((err) => {
        resolve([]);
        console.log(err);
      });
  });
  // fetch("http://164.52.210.54:5000/primarycare/11", requestOptions)
};

export let checkError = (data) => {
  const isError = data?.error || data?.data?.error;
  let errMsg = "Something went wrong! Try Again.";
  if (
    data?.data?.error ||
    data?.data?.errorMessage ||
    data?.data?.error_description
  ) {
    if (typeof data?.data?.error === "string") {
      errMsg =
        data?.data?.error ||
        data?.data?.errorMessage ||
        data?.data?.error_description;
    } else {
      errMsg = data?.data?.errorMessage || data?.data?.error_description;
    }
  }
  if (errMsg === "invalid_grant" || errMsg === "Invalid user credentials") {
    errMsg = "Invalid OTP";
  }
  return {
    isError: !!isError,
    errMsg,
  };
};

export const getTimeSlot = (date, type = "date", isUpdate = false) => {
  const currentTime = new Date();
  let comparedate = date;
  if (type === "unix") {
    comparedate = convertTime(date, type, "DD-MM-YYYY");
  }
  let time = 0;
  // if (!date) {
  //   comparedate = this.state.date;
  // }
  if (moment().format("DD-MM-YYYY") === comparedate || isUpdate) {
    let currentHour = currentTime.getHours();
    if (isUpdate) {
      currentHour = new Date(date * 1000).getHours();
    }
    if (currentHour <= 12) {
      time = 0;
    } else if (currentHour > 12 && currentHour <= 15) {
      time = 1;
    } else if (currentHour > 15 && currentHour <= 18) {
      time = 2;
    } else {
      time = 3;
    }
  }
  return time;
};

export const getTimeString = (hour, minutes) => {
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (hour === 0) {
    return `12:${minutes} AM`;
  }
  if (hour === 12) {
    return `12:${minutes} PM`;
  }
  if (hour <= 11) {
    if (hour < 10) {
      hour = `0${hour}`;
    }
    return `${hour}:${minutes} AM`;
  }
  return `${hour - 12 < 10 ? "0" : ""}${hour - 12}:${minutes} PM`;
};

export const convertTime = (time, inputFormat, outputFormat, type = "date") => {
  if (type === "date") {
    if (inputFormat !== "unix") {
      return moment(time, inputFormat).format(outputFormat);
    }
    const date = new Date(time * 1000);
    return moment(date).format(outputFormat);
  }
  if (type === "diff") {
    if (inputFormat === "unix") {
      return moment().diff(moment.unix(time), outputFormat);
    }
  }
  return moment().diff(moment(time, inputFormat), outputFormat);
};

export const isIntegerValue = (value) => {
  const integerPattern = /^[0-9]*$/;
  return integerPattern.test(value);
};

export const validationFormEnbty = (list, states) => {
  let error = false;
  let errorState = {};

  list.forEach((val) => {
    // if (val.required) {
    if (
      val?.componet_type !== "select" &&
      states[val?.state_name]?.length > 0
    ) {
      errorState[val.state_name] = false;
    } else if (val?.componet_type === "select") {
      if (
        states[val?.state_name]
          ? Object.keys(states[val?.state_name])?.length > 0
          : false
      )
        errorState[val.state_name] = false;
    } else if (val?.componet_type === "textField_select") {
      if (
        states[val?.state_name]?.type
          ? Object.keys(states[val?.state_name]?.type)?.length > 0
          : false
      ) {
        errorState[val.state_name] = false;
      }
      if (states[val?.state_name]?.value?.length > 0) {
        errorState[val.state_name] = false;
      }
    } else if (val?.componet_type === "date") {
      if (states[val?.state_name] ? states[val?.state_name] : false)
        errorState[val.state_name] = false;
    }
    // }
  });

  if (
    errorState &&
    Object.keys(errorState).length > 0 &&
    Object.keys(errorState).filter((val) => errorState[val] === false).length >
      0
  ) {
    error = false;
  } else {
    error = true;
  }

  return {
    error,
    errorState: [],
  };
};

export const treeData = [
  {
    id: 1,
    name: "Apollo group of hospitals",
    checkedValue: "unchecked",
    children: [
      {
        id: 2,
        name: "Apollo hospitals - India",
        checkedValue: "unchecked",
        children: [
          {
            id: 3,
            name: "Apollo hospitals - India",
            checkedValue: "unchecked",
          },
          {
            id: 4,
            name: "Apollo hospitals - India",
            checkedValue: "unchecked",
          },
        ],
      },
      {
        id: 5,
        name: "Apollo hospitals - China",
        checkedValue: "unchecked",
      },
      {
        id: 6,
        name: "Apollo hospitals - Canada",
        checkedValue: "unchecked",
      },
      {
        id: 7,
        name: "Apollo hospitals - USA",
        checkedValue: "unchecked",
      },
      {
        id: 8,
        name: "Apollo hospitals - Japan",
        checkedValue: "unchecked",
      },
      {
        id: 9,
        name: "Apollo hospitals - Brazil",
        checkedValue: "unchecked",
      },
      {
        id: 10,
        name: "Apollo hospitals - France",
        checkedValue: "unchecked",
      },
    ],
  },
];
export const TimeFormatConversion = (date, formatPattern) => {
  if (!date) {
    return;
  }
  let Ndate = new Date();
  if (typeof date === "number") {
    // Ndate = moment.unix(date);
    Ndate = fromUnixTime(date);
  }

  let fmt = formatPattern ? formatPattern : "dd/MM/yyyy";
  if (formatPattern) {
    return format(new Date(Ndate), fmt);
  } else {
    return format(new Date(Ndate), fmt);
  }
};

// Check the versions
export let semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA ? versionA.split(/\./g) : ["0", "0", "0"];
  const versionsB = versionB ? versionB.split(/\./g) : ["0", "0", "0"];

  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

// Refresh the cache by clearing the cache and reload
export const refreshCacheAndReload = async () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    const names = await caches.keys();
    const promArr = [];
    for (const name of names) {
      promArr.push(caches.delete(name));
    }
    await Promise.all(promArr);
  }
  // delete browser cache and hard reload
  window.location.reload(true);
};
export const optionsFormatter = (options = [], labelKey, valueKey = "_id") => {
  return options.map((option) => {
    return {
      ...option,
      label: option?.[labelKey],
      value: option?.[valueKey],
    };
  });
};

export const getDifferenceByLeftArray = (arr1, arr2, prop) => {
  return arr1.filter((item) => arr2.indexOf(item) === -1);
};

export const UserInfo = (
  location,
  facilitystate,
  organisation,
  enterprise,
  rolestate,
  code,
  practitonerId,
  personId
) => {
  switch (code) {
    case "OT001":
      return {
        roleid: rolestate?._id,
        facilityid: facilitystate?._id,
        locationid: location?._id,
        orgid: organisation?._id,
        enterpriseid: enterprise?._id,
        practitionerid: practitonerId,
        personid: personId,
      };
    case "OT002":
      return {
        roleid: rolestate?._id,
        facilityid: facilitystate?._id,
        locationid: location?._id,
        orgid: organisation?._id,
        enterpriseid: organisation?.ParentOrgID?._id,
        practitionerid: practitonerId,
        personid: personId,
      };
    case "OT003":
      return {
        roleid: rolestate?._id,
        facilityid: facilitystate?._id,
        locationid: location?._id,
        orgid: facilitystate?.ParentOrgID?._id,
        enterpriseid: facilitystate?.Parentofparent?.[0]?._id,
        practitionerid: practitonerId,
        personid: personId,
      };
    default:
      return null;
  }
};

export const userInfoKeys = (
  location,
  facilitystate,
  organisation,
  enterprise,
  rolestate,
  code,
  practitonerId,
  personId
) => {
  switch (code) {
    case "OT001":
      return {
        role: rolestate,
        facility: facilitystate,
        location: location,
        org: organisation,
        enterprise: enterprise,
        practitioner: practitonerId,
        personid: personId,
      };
    case "OT002":
      return {
        role: rolestate,
        facility: facilitystate,
        location: location,
        org: organisation,
        enterprise: organisation?.ParentOrgID,
        practitioner: practitonerId,
        personid: personId,
      };
    case "OT003":
      return {
        role: rolestate,
        facility: facilitystate,
        location: location,
        org: facilitystate?.ParentOrgID,
        enterprise: facilitystate?.Parentofparent?.[0],
        practitioner: practitonerId,
        personid: personId,
      };
    default:
      return null;
  }
};
export const getFilteredData = (value, stateName, result, stateList) => {
  let data = null;
  if (result?.[value]) {
    data = stateList?.[stateName]?.data.filter(
      (val) => val.value === result[value]
    )[0];
  }
  if (
    !data &&
    (value === "ivInfusionRateUOM" || value === "ivInfusionTimeUom")
  ) {
    data = stateList.DurationType_masters.data.filter(
      (val) => val.label === "min"
    )[0];
  }
  if (!data && value === "priority") {
    data = stateList.Priority_masters.data.filter(
      (val) => val.label === "1"
    )[0];
  }
  if (!data && value === "presMode") {
    data = stateList.OrderMode_masters.data.filter(
      (val) => val.label === "Regular"
    )[0];
  }
  return data;
};

export const checkMaxDateValidation = (maxDate, value) => {
  let errorMessage = null;
  if (moment(value).diff(maxDate) >= 0) {
    return errorMessage;
  }
  return (errorMessage = `date must be ${moment(maxDate).format(
    "DD/MM/YYYY"
  )} or greater`);
};

export function getDateValue(value) {
  if (value === undefined) {
    return;
  } else if (value === null) {
    return;
  } else if (value === "") {
    return;
  }
}

//
export const CustomaddDays = (days) => {
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const getFormatForDateTimePicker = (formatType) => {
  let orgFormat = "";
  if (localStorage.getItem("orgFormat") !== "undefined") {
    orgFormat = JSON.parse(localStorage.getItem("orgFormat"));
  } else {
    orgFormat = "";
  }

  switch (formatType) {
    case "date":
      return (
        orgFormat?.orgdateformat?.shortdesc
          ?.replace(new RegExp("Y", "g"), "y")
          ?.replace(new RegExp("D", "g"), "d") ?? "dd/MM/yyyy"
      );

    case "time":
      return (
        orgFormat?.orgtimeformat?.shortdesc
          ?.replace(new RegExp("Y", "g"), "y")
          ?.replace(new RegExp("D", "g"), "d") ?? "HH:mm"
      );

    case "datetime":
      return (
        orgFormat?.orgdatetimeformat?.shortdesc
          ?.replace(new RegExp("Y", "g"), "y")
          ?.replace(new RegExp("D", "g"), "d") ?? "dd/MM/yyyy HH:mm"
      );

    default:
      return "dd/MM/yyyy";
  }
};

export const getDayDifference = (date1, date2) => {
  const dayStart = moment(date1).startOf("day");
  const dayEnd = moment(date2).startOf("day");
  return dayEnd.diff(dayStart, "days");
};

export const FrequencyFieldsByScheduleType = (type) => {
  switch (type) {
    case "DBA":
      return ["Day", "Base schedule", "Remarks"];
    case "UDE":
      return ["Base schedule", "From Day", "To Day", "Dosage", "Remarks"];
    case "DET":
      return ["Time", "Remarks"];
    case "INT":
    case "SEQ":
      return ["Base schedule", "Remarks"];
    default:
      return [
        "Day",
        "Time",
        "Base schedule",
        "Start date",
        "End date",
        "Dosage",
        "Remarks",
      ];
  }
};

export const FrequencyDetailsByScheduleType = (FreqDetls) => {
  let data = FreqDetls?.map((v) => {
    return {
      Day: v?.weekDay ? v?.weekDay : "-",
      Time: v?.time ? v?.time : "-",
      "Base schedule": v?.baseFreq ?? "-",
      "Start date":
        v?.fromDate === 0
          ? "-"
          : moment(v?.fromDate * 1000).format("DD-MM-YYYY"),

      "End date":
        v?.toDate === 0 ? "-" : moment(v?.toDate * 1000).format("DD-MM-YYYY"),
      Dosage: v?.dose ? v?.dose : "-",
      "From Day": v?.fromDay,

      "To Day": v?.toDay,
      Remarks: v?.remarks ?? "-",
    };
  });
  return data;
};

export const SCHEDULE_TYPES = {
  DBA: "Day Based",
  DET: "Detail",
  INT: "Interval",
  SEQ: "Sequential",
  UDE: "User Defined",
  TID: "Three times daily",
};

export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

export const getBrand = (brands, compound, addtictive, isCompound, isIV) => {
  let Brands = [];
  if (brands?.length >= 0 && !isCompound) {
    Brands = [...brands];
  }
  if (compound?.length >= 0) {
    Brands = [...Brands, ...compound];
  }
  if (addtictive?.length >= 0 && isIV) {
    addtictive.forEach((val) => {
      Brands = [...Brands, ...val.brands];
    });
  }

  return Brands;
};

export const WEEKDAYS = [
  {
    label: "Sunday",
    value: "Sunday",
  },
  {
    label: "Monday",
    value: "Monday",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    label: "Thursday",
    value: "Thursday",
  },
  {
    label: "Friday",
    value: "Friday",
  },
  {
    label: "Saturday",
    value: "Saturday",
  },
];

export const getDateTime = () => {
  let date = new Date();
  let epochestr = Math.floor(date.getTime() / 1000);
  return epochestr.toString();
};

export const getCurrentDate = (Date) => {
  if (Date !== undefined) {
    const today = Date;
    // console.log({ today });
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    // const formattedToday = dd + "/" + mm + "/" + yyyy;
    // var result = `${yyyy}-${mm}-${dd}T00:00:00.000Z`;

    // result = Date.parse(result) / 1000;

    // // console.log("getdate", result, date);

    // return result;
    const formattedToday = yyyy + "-" + mm + "-" + dd;
    return formattedToday;
  }
};

export const getTmrwDate = () => {
  const tmrw = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  return tmrw;
};

export const getTmrwDateformat = (Date) => {
  const today = new Date(Date + 24 * 60 * 60 * 1000);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;
  return formattedToday;
};

export let BasicRoute = [
  {
    roleid: "1",
    route: Routes.Organization,
  },
  {
    roleid: "4",
    route: Routes.orderConfigurator,
  },
  {
    roleid: "3",
    route: Routes.patientRegistration,
  },
  {
    roleid: "5",
    route: Routes.menu,
  },
  {
    roleid: "2",
    route: Routes.Organization,
  },
];

export function dateToEpoch(date) {
  const myDate = new Date(date);
  return myDate.getTime() / 1000;
}

export function getYesterdayEpoch(epochTime) {
  const date = new Date(epochTime * 1000);
  date.setDate(date.getDate() - 1);
  return date.getTime() / 1000;
}
export const aqlQuery = {
  getFormData: (id, ticketId) => {
    return `
        for doc in forms
            filter doc.form_id == '${id}' && doc.is_active == true
            let master_form_type = (
                for mfty in master_form_types
                    filter mfty.is_active == true && mfty.form_type_id == doc.form_type_id
                    return {
                        id: mfty.form_type_id,
                        form_type: mfty.form_type_name
                    }
            )
            let form_detail = {
                id: doc.form_id,
                name: doc.form_name,
                is_active: doc.is_active,
                created_at: doc.created_at,
                updated_at: doc.updated_at,
                form_type: doc.form_type_id,
                master_form_type: master_form_type[0],
                mapped_diseases: (
                    for mapForm in rl_disease_form
                        filter mapForm.is_active == true && mapForm.form_id == doc.form_id
                            return FIRST(for mapForm_disease in master_disease
                                filter mapForm_disease.is_active == true && mapForm_disease.disease_id == mapForm.disease_id
                                    return {
                                        disease_name: mapForm_disease.disease_name,
                                        disease_definition: mapForm_disease.disease_definition
                                    }
                            )
                )
            }
            let answers = (
                for entry in form_entries
                    filter entry.form_id=="${id}" && entry.ticketId == "${ticketId}"
                    return {
                        bulkAnswers: entry,
                        answers:    merge(
                            for ans in entry.answers
                                let qnDetail = first(
                                    for qn in questions
                                        filter qn.is_active == true && qn.question_id == ans.question_id
                                        return {
                                            tag: qn.tag,
                                            file: qn.file,
                                            editorState: qn.editorState,
                                            embedFields: qn.embedFields,
                                            ui_component: qn.ui_component
                                        }
                                )

                                return {
                                  [ans.question_id]:merge(ans.answer,qnDetail)
                                }
                        )
                    }
            )

            let tree = (for vertices , edges , paths in OUTBOUND CONCAT("forms/" ,doc.form_id ) edge_form_section
                filter vertices.parent_section_id == null && vertices.is_active == true
                sort vertices.priority ASC
                return {
                    id: vertices.section_id,
                    form_id: vertices.form_id,
                    is_form: vertices.is_form,
                    section_name : vertices.section_name,
                    description: vertices.description,
                    formLayoutType:vertices.formLayoutType,
                    priority: vertices.priority,
                    is_skippable: vertices.is_skippable ? vertices.is_skippable :  false,
                    is_smart_skip: vertices.is_smart_skip ? vertices.is_smart_skip : false,
                    parent_section_id: vertices.parent_section_id,
                    is_investigator_section: vertices.is_investigator_section,
                    section_type: vertices.section_type,
                    level: 1,
                    is_active: vertices.is_active,
                    created_by: vertices.created_at,
                    updated_by: vertices.updated_at,
                    framed_questions: (
                        for qn in questions
                            filter qn.section_id == vertices.section_id && qn.is_active == true
                            sort qn.priority ASC
                            return {
                                id : qn.question_id,
                                priority: IS_NULL(qn.priority) ? 1 : qn.priority,
                                ui_component: qn.ui_component,
                                is_required: qn.is_required,
                                question: qn.question_name,
                                question_options: qn.options,
                                tag: qn.tag,
                                QueryId: qn.QueryId,
                                file: qn.file,
                                editorState: qn.editorState,
                                embedFields: qn.embedFields,
                                table_scheme:qn.table_scheme,
                                formula:qn.formula,
                                validation:qn.validation,
                                has_dependency_qn: qn.has_dependency_qn,
                                dependencies: qn.dependency ? qn.dependency : [],
                                dependent: FIRST(
                                    for dep_qn in dependency_questions
                                        filter dep_qn.is_active == true && dep_qn.target_question_id == qn.question_id
                                        return dep_qn
                                )
                            }
                    ),
                    dependent: FIRST(
                        for dep_qn in dependency_questions
                            filter dep_qn.is_active == true
                            filter dep_qn.target_form_id == vertices.section_id || dep_qn.target_section_id == vertices.section_id
                            return dep_qn
                    ),
                    child_sections: (
                        for childSection1 in section
                        filter childSection1.parent_section_id == vertices.section_id && childSection1.is_active == true
                            sort childSection1.priority ASC
                            return {
                                id: childSection1.section_id,
                                form_id: childSection1.form_id,
                                is_form: childSection1.is_form,
                                section_name : childSection1.section_name,
                                description: childSection1.description,
                                formLayoutType:childSection1.formLayoutType,
                                priority: childSection1.priority,
                                is_skippable: childSection1.is_skippable ? childSection1.is_skippable :  false,
                                is_smart_skip: childSection1.is_smart_skip ? childSection1.is_smart_skip : false,
                                parent_section_id: childSection1.parent_section_id,
                                is_investigator_section: childSection1.is_investigator_section,
                                section_type: childSection1.section_type,
                                level: 2,
                                is_active: childSection1.is_active,
                                created_by: childSection1.created_at,
                                updated_by: childSection1.updated_at,
                                framed_questions: (
                                    for qn1 in questions
                                        filter qn1.section_id == childSection1.section_id && qn1.is_active == true
                                        sort qn1.priority ASC
                                        return {
                                            id : qn1.question_id,
                                            priority: IS_NULL(qn1.priority) ? 1 : qn1.priority,
                                            ui_component: qn1.ui_component,
                                            is_required: qn1.is_required,
                                            question: qn1.question_name,
                                            question_options: qn1.options,
                                            tag: qn1.tag,
                                            QueryId: qn1.QueryId,
                                            file: qn1.file,
                                            editorState: qn1.editorState,
                                            embedFields: qn1.embedFields,
                                            table_scheme:qn1.table_scheme,
                                            formula:qn1.formula,
                                            validation:qn1.validation,
                                            has_dependency_qn: qn1.has_dependency_qn,
                                            dependencies: qn1.dependency ? qn1.dependency : [],
                                            dependent: FIRST(
                                                for dep_qn in dependency_questions
                                                    filter dep_qn.is_active == true && dep_qn.target_question_id == qn1.question_id
                                                    return dep_qn
                                            )
                                        }
                                ),
                                dependent: FIRST(
                                    for dep_qn in dependency_questions
                                        filter dep_qn.is_active == true
                                        filter dep_qn.target_form_id == childSection1.section_id || dep_qn.target_section_id == childSection1.section_id
                                        return dep_qn
                                ),
                                child_sections: (
                                    for childSection2 in section
                                    filter childSection2.parent_section_id == childSection1.section_id && childSection2.is_active == true
                                    sort childSection2.priority ASC
                                        return {
                                            id: childSection2.section_id,
                                            form_id: childSection2.form_id,
                                            is_form: childSection2.is_form,
                                            section_name : childSection2.section_name,
                                            description: childSection2.description,
                                            formLayoutType:childSection2.formLayoutType,
                                            priority: childSection2.priority,
                                            is_skippable: childSection2.is_skippable ? childSection2.is_skippable :  false,
                                            is_smart_skip: childSection2.is_smart_skip ? childSection2.is_smart_skip : false,
                                            parent_section_id: childSection2.parent_section_id,
                                            is_investigator_section: childSection2.is_investigator_section,
                                            section_type: childSection2.section_type,
                                            level: 3,
                                            is_active: childSection2.is_active,
                                            created_by: childSection2.created_at,
                                            updated_by: childSection2.updated_at,
                                            framed_questions: (
                                                for qn2 in questions
                                                    filter qn2.section_id == childSection2.section_id && qn2.is_active == true
                                                    sort qn2.priority ASC
                                                    return {
                                                        id : qn2.question_id,
                                                        priority: IS_NULL(qn2.priority) ? 1 : qn2.priority,
                                                        ui_component: qn2.ui_component,
                                                        is_required: qn2.is_required,
                                                        question: qn2.question_name,
                                                        question_options: qn2.options,
                                                        tag: qn2.tag,
                                                        QueryId: qn2.QueryId,
                                                        file: qn2.file,
                                                        editorState: qn2.editorState,
                                                        embedFields: qn2.embedFields,
                                                        table_scheme:qn2.table_scheme,
                                                        formula:qn2.formula,
                                                        validation:qn2.validation,
                                                        has_dependency_qn: qn2.has_dependency_qn,
                                                        dependencies: qn2.dependency ? qn2.dependency : [],
                                                        dependent: FIRST(
                                                            for dep_qn in dependency_questions
                                                                filter dep_qn.is_active == true && dep_qn.target_question_id == qn2.question_id
                                                                return dep_qn
                                                        )
                                                    }
                                            ),
                                            dependent: FIRST(
                                                for dep_qn in dependency_questions
                                                    filter dep_qn.is_active == true
                                                    filter dep_qn.target_form_id == childSection2.section_id || dep_qn.target_section_id == childSection2.section_id
                                                    return dep_qn
                                            ),
                                            child_sections: (
                                                for childSection3 in section
                                                filter childSection3.parent_section_id == childSection2.section_id && childSection3.is_active == true
                                                sort childSection3.priority ASC
                                                    return  {
                                                        id: childSection3.section_id,
                                                        form_id: childSection3.form_id,
                                                        is_form: childSection3.is_form,
                                                        section_name : childSection3.section_name,
                                                        description: childSection3.description,
                                                        formLayoutType:childSection3.formLayoutType,
                                                        priority: childSection3.priority,
                                                        is_skippable: childSection3.is_skippable ? childSection3.is_skippable :  false,
                                                        is_smart_skip: childSection3.is_smart_skip ? childSection3.is_smart_skip : false,
                                                        parent_section_id: childSection3.parent_section_id,
                                                        is_investigator_section: childSection3.is_investigator_section,
                                                        section_type: childSection3.section_type,
                                                        level: 4,
                                                        is_active: childSection3.is_active,
                                                        created_by: childSection3.created_at,
                                                        updated_by: childSection3.updated_at,
                                                        framed_questions: (
                                                            for qn3 in questions
                                                                filter qn3.section_id == childSection3.section_id && qn3.is_active == true
                                                                sort qn3.priority ASC
                                                                return {
                                                                    id : qn3.question_id,
                                                                    priority: IS_NULL(qn3.priority) ? 1 : qn3.priority,
                                                                    ui_component: qn3.ui_component,
                                                                    is_required: qn3.is_required,
                                                                    question: qn3.question_name,
                                                                    question_options: qn3.options,
                                                                    tag: qn3.tag,
                                                                    QueryId: qn3.QueryId,
                                                                    file: qn3.file,
                                                                    editorState: qn3.editorState,
                                                                    embedFields: qn3.embedFields,
                                                                    table_scheme:qn3.table_scheme,
                                                                    formula:qn3.formula,
                                                                    validation:qn3.validation,
                                                                    has_dependency_qn: qn3.has_dependency_qn,
                                                                    dependencies: qn3.dependency ? qn3.dependency : [],
                                                                    dependent: FIRST(
                                                                        for dep_qn in dependency_questions
                                                                            filter dep_qn.is_active == true && dep_qn.target_question_id == qn3.question_id
                                                                            return dep_qn
                                                                    )
                                                                }
                                                        ),
                                                        dependent: FIRST(
                                                            for dep_qn in dependency_questions
                                                                filter dep_qn.is_active == true
                                                                filter dep_qn.target_form_id == childSection3.section_id || dep_qn.target_section_id == childSection3.section_id
                                                                return dep_qn
                                                        ),
                                                        child_sections:[]
                                                    }
                                            )
                                        }
                                )
                            }
                    )
            })

            let form = (for tr in tree
                return tr.is_form == true ? merge({expanded:[]} ,tr) : (
                    for tr1 in tr.child_sections
                        return tr1.is_form == true ? merge({expanded:[tr.id]}, tr1) : (
                            for tr2 in tr1.child_sections
                                return tr2.is_form == true ? merge({expanded:[tr.id,tr1.id]},tr2): (
                                    for tr3 in tr2.child_sections
                                        return tr3.is_form == true ? merge({expanded:[tr.id,tr1.id,tr2.id]},tr3) : (
                                            for tr4 in tr3.child_sections
                                                return merge({expanded: [tr.id,tr1.id,tr2.id,tr2.id]},tr4)
                            )
                        )
                    )
                )
            )

            let statusArray = (
                for status in master_entry_status
                filter status.is_active == true && status.status_id in []
                sort status.status_id asc
                    return {
                        value : status.status_id,
                        label : status.status_name
                    }
            )

    return {
        form_detail: form_detail,
        tree: tree,
        form: form[****],
        bulkAnswers: answers[0] ? answers[0].bulkAnswers ? answers[0].bulkAnswers : {}  : {},
        answers: answers[0] ? answers[0].answers ? answers[0].answers : {}  : {},
        patientDetail: answers[0] ? answers[0].patientDetail ? answers[0].patientDetail : {}  : {},
        statusArray: statusArray
    }`;
  },
};
