import React, { useEffect, useState } from "react";
import {
  TablePagination,
  makeStyles,
  Paper,
  Button,
  Grid,
} from "@material-ui/core";
import { ManageCard } from "atp-crud";
import { actions, async, idmapis } from "central_pharma_binder";
import { connect } from "react-redux";
import { giveMeRoleDataSecurity, AlertProps } from "../../utils";
import { Divider, Typography } from "@material-ui/core";
import {
  DrawerContext,
  AlertContext,
  DialogContext,
  BackdropContext,
} from "../../contexts";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { DrawerProps, ConvertToReactSelect, Message } from "../../utils";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
// import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PublishIcon from "@material-ui/icons/Publish";
import { AddEditForm } from "./addEditForm";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import copy from "copy-to-clipboard";
import { withTheme } from "@material-ui/core";
import form3 from "../../assets/form3.svg";
import Config from "../../config";
import { AxiosCall } from "../../utils";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import Fade from "@mui/material/Fade";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import { ToastMessage } from "../../utils";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CircularProgress } from "@material-ui/core";
// import Paper from '@mui/material/Paper';
import * as xlsx from "xlsx";
// import xlsxPopulate from "xlsx-populate";

const { ADD_DRUG_MASTER } = actions;

const useStyles = makeStyles((theme) => ({
  paper: {
    // borderRadius: "15px",
    // padding: "0px 30px 20px 30px",
    // position: "relative",
    fontSize: "10px",
    maxHeight: "50dvh",
    borderRadius: "0px",
    padding: "0px 20px",
    boxShadow: "none",
  },
  tablecell: {
    maxWidth: "400px",
  },
  divsearch: {
    borderRadius: "50px",
    position: "absolute",
    zIndex: "10",
    backgroundColor: "#EFF7FF",
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 5px",
    gap: "3px",
    top: "15px",
    width: "300px",
    fontSize: "10px",
  },
  icon: {
    color: theme?.palette?.primary?.main,
  },
  search: {
    fontSize: "10px",
    width: "100%",
  },
  tablepage: {
    fontSize: "10px",
    "& .MuiTypography-body2": {
      fontSize: "10px",
    },
  },
  rightarrow: {
    transform: "rotate(270deg)",
    color: "black",
  },
  downarrow: {
    color: "black",
  },
  toppaper: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    borderRadius: "0px",
    gridRow: "1/2",
  },
  table: {
    // "& .MuiTableCell-stickyHeader": {
    //     zIndex: "0"
    // }
  },
  rowbottom: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  bottompaper: {
    borderRadius: "0px",
  },
  topdiv: {
    display: "grid",
    gridTemplateRows: "0.1fr 0.9fr",
    gridTemplateColumn: "1fr",
    height: "100%",
  },
  popover: {
    pointerEvents: "none",
  },
  modelStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: "90%",
    backgroundColor: "#fff",
    //border: '2px solid #000',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  },
}));

export const ItemMasterParent = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [state, setState] = useState({
    sort: "all",
    useState,
  });

  console.log("propsItem", props);
  const classes = useStyles();
  const [pageq, setPageq] = React.useState(0);
  const [rowsPerPageq, setRowsPerPageq] = React.useState(5);
  const [pageq1, setPageq1] = React.useState(0);
  const [rowsPerPageq1, setRowsPerPageq1] = React.useState(5);
  const handleChangePageq = (event, newPage) => {
    setPageq(newPage);
  };
  const handleChangePageq1 = (event, newPage) => {
    setPageq1(newPage);
  };
  const handleChangeRowsPerPageq = (event) => {
    setRowsPerPageq(parseInt(event.target.value, 10));
    setPageq(0);
  };
  const handleChangeRowsPerPageq1 = (event) => {
    setRowsPerPageq1(parseInt(event.target.value, 10));
    setPageq1(0);
  };
  const [clientData, setClientData] = useState("");
  const [tenatIdData, setTenatIdData] = "";
  const [selectOrg, setSelectedOrg] = useState([]);
  const [UOM, setUOM] = React.useState([]);
  const [DrugClass, setDrugClass] = React.useState([]);
  const [Drugcategory, setDrugcategory] = React.useState([]);
  const [Status, setStatus] = React.useState([]);
  const [SearchItem, setSearchItem] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [druggeneric, setDrugGeneric] = React.useState([]);
  const [drugform, setdrugform] = React.useState([]);
  const [viewopen, setviewopen] = React.useState(false);

  useEffect(() => {
    (async function () { })();
    // clientCheckFunc();
    setTimeout(() => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      setDelay(true);
    }, 2000);
  }, []);

  useEffect(() => {
    let Arr = [
      "UOM",
      "DRUGCLASS",
      "DRUG CATEGORY",
      "STATUS",
      "DRUGFORM",
      "DRUGGENERIC",
    ];
    getAllDropdown(Arr);
    // createExcel()
  }, []);

  const getAllDropdown = (Arr) => {
    Arr.map(async (li) => {
      let params = {
        db_name: Config.dbname,
        entity: "CodeableConceptMaster",
        filter: `CodeableConceptMaster.Type=='${li}' && CodeableConceptMaster.status==true`,
        //sort: "CodeableConceptMaster.text",
        return_fields:
          "MERGE(FIRST(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod)))",
      };
      let res = await AxiosCall(
        "post",
        `${Config.api_url}/api/read_documents`,
        params
      );
      let result = res?.result;
      if (li == "UOM") {
        setUOM(result);
      } else if (li == "DRUGCLASS") {
        setDrugClass(result);
      } else if (li == "DRUG CATEGORY") {
        setDrugcategory(result);
      } else if (li == "STATUS") {
        setStatus(result);
      } else if (li == "DRUGGENERIC") {
        setDrugGeneric(result);
      } else if (li == "DRUGFORM") {
        setdrugform(result);
      }
    });
  };
  // const [copyValue, setCopyValue] = React.useState("");
  const [delay, setDelay] = React.useState(false);
  const [drugs, setDrugs] = React.useState([]);
  const drawerContext = React.useContext(DrawerContext);
  const alert = React.useContext(AlertContext);
  const dialogContext = React.useContext(DialogContext);
  const backdrop = React.useContext(BackdropContext);
  const [excelData, setExcelData] = React.useState("");
  const [personID, setPersonID] = React.useState();
  const [editDataaa, setEditData] = React.useState([]);
  const [isEdit, setisEdit] = React.useState(false);
  const history = useHistory();
  console.log("personIDRR", personID);
  const formTypes = useSelector((state) => state?.formSlice?.getAllFormTypes);

  const upsertForm = useSelector((state) => state?.formSlice?.upsertForm);

  const getallselector = useSelector((state) => ({
    getAllAssessment: state?.AssessmentSlice?.getAllAssessments,
    getAllICD10Code: state?.DiseaseSlice?.getAllICD10Code,
    getAllDiseasesClassification:
      state?.DiseaseSlice?.getAllDiseasesClassification,
  }));
  const formsGet = useSelector((state) => ({
    getAllForms: state?.formSlice?.getAllForms,
    userData: state?.authSlice?.login?.data ?? {},
  }));
  const sortOptionArray = [
    { value: "all", label: "All Form Types" },
    ...ConvertToReactSelect(formTypes?.data, "form_type_id", "form_type_name"),
  ];
  useEffect(() => {
    getForms();
  }, [state]);

  const handleSortChange = (sort) => {
    setState(
      {
        sort,
      },
      () => {
        getForms();
      }
    );
  };

  const getForms = async () => {
    const data = await dispatch(actions.GET_DRUGS());

    if (data?.payload?.data?.length > 0) {
      setDrugs(data?.payload?.data);
      setSearchItem(data?.payload?.data);
    }

    // let params = {
    //   db_name: Config.dbname,
    //   entity: "drugmaster",
    //   filter: `drugmaster.activestatus==true`,
    //   return_fields:
    //     "merge(unset(drugmaster,'activestatus','updatedate','updatedby','createddate','createdby','_rev'),{drugcategory:contains(drugmaster.drugcategory,'CodingMaster')?drugmaster.drugcategory:''},{uom:contains(drugmaster.uom,'CodingMaster')?drugmaster.uom:''},{status:contains(drugmaster.status,'CodingMaster')?drugmaster.status:''},{drugclass:contains(drugmaster.drugclass,'CodingMaster')?drugmaster.drugclass:''})",
    //   sort: "drugmaster.serialno desc",
    // };
    // let res = await AxiosCall(
    //   "post",
    //   `${Config.api_url}/api/read_documents`,
    //   params
    // );
    // let result = res?.result;
    // setDrugs(result)
    // setSearchItem(result)
  };

  const getFormTypes = () => {
    dispatch(actions.GET_ALL_FORM_TYPES());
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const addForm = () => {
    handleOpen();
  };
  const [open, setOpen] = React.useState(false);
  const [upload, setUpload] = React.useState(false);
  const [errorScreen, setErrorScreen] = React.useState(false);
  const [createEx, setCreateEx] = React.useState({});
  // const [uploadEx,setUploadEx]=React.useState()

  const [uploadfile, setUploadfile] = React.useState(false);
  const [message, setMessage] = useState("");
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [whileCreatingExcel, setCreatingExcel] = useState(false);
  const [whileUploadingExcel, setUploadingExcel] = useState(false);
  const messages = [
    "Loading",
    "fetching the data",
    "initializing excel data",
    "Getting the excel data",
  ];

  const [message2, setMessage2] = useState("");
  const [data2, setData2] = useState(null);
  const [isLoading2, setIsLoading2] = useState(true);
  const messages2 = ["Uploading", "fetching the data", "validating Fields"];

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setMessage(messages[index]);
      index = (index + 1) % messages.length; // Cycle through the messages array.
    }, 5000);

    // Clean up the interval
    return () => clearInterval(interval);
  }, [createEx]);

  useEffect(() => {
    let index2 = 0;
    const interval2 = setInterval(() => {
      setMessage2(messages2[index2]);
      index2 = (index2 + 1) % messages2.length; // Cycle through the messages array.
    }, 5000);

    // Clean up the interval
    return () => clearInterval(interval2);
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onActionCompleted = () => {
    getForms();
    setisEdit(false);
    setEditData([]);
    handleClose();
  };

  const onViewActionCompleted = () => {
    setisEdit(false);
    setEditData([]);
    setviewopen(false);
  };

  const handleOpenView = (data) => {
    setviewopen(true);
    setEditData(data.currentmapobject);
  };

  // const addfunc = () => {
  //   alert("hi");
  // };
  const uploadfunc = () => {
    setUpload(true);
    createExcel();

    // let params = {
    //   db_name: "pintar2u",
    //   entity: "bulkdataupload",
    //   filter: `bulkdataupload.person_id=='${keyClkId}' && bulkdataupload.activestatus==true`,
    //   return_fields: "Person",
    // };
    // let res = await AxiosCall("post", process.env.REACT_APP_API_URL, params);
    // let personData = res?.result[0];
  };
  const [errData, setErrData] = React.useState({});
  const [errLine, setErrLine] = React.useState([]);

  const errorFunc = async (fileId) => {
    setErrorScreen(true);
    console.log("errorWin", fileId);

    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "bulkdataerror",
      filter: `bulkdataerror.fileid=='${fileId}' && bulkdataerror.activestatus==true`,
      return_fields: "bulkdataerror",
    };
    let res = await AxiosCall("post", process.env.REACT_APP_API_URL, params);
    let errorData = res?.result[0];
    setErrData(errorData);
    setErrLine([...errorData?.linenumber]);
    console.log("errDatats", errorData);
  };
  // console.log("errDss", errData);
  // console.log("errLine", errLine);
  const modelHandleClose = () => {

    if (whileCreatingExcel) {
      ToastMessage("Fetching data ..Please wait", "info", "top-center")
    } else if (whileUploadingExcel === false) {
      setUpload(false)
      setCreateEx({})
    }


    if (whileUploadingExcel) {
      ToastMessage("File is Uploading ..Please wait", "info", "top-center")
    } else if (whileCreatingExcel === false) {
      setUpload(false)
      setCreateEx({})
    }
    // if (uploadfile) {
    //   ToastMessage("File is Uploading... Please wait", "info", "top-center");
    // } else {
    //   setUpload(false);
    //   setCreateEx({});
    // }
  };
  const modelErrorHandleClose = () => {
    setErrorScreen(false);
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const onSearchChange = (value) => {
    if (value) {
      const results = SearchItem.filter((li) =>
        li?.itemname?.toLowerCase().includes(value.toLowerCase())
      );
      setDrugs(results);
    } else if (!value) {
      setDrugs(SearchItem);
    }
  };

  const onViewFormClicked = (data) => {
    localStorage.setItem(
      "formId",
      window.btoa(data?.currentmapobject?.form_id)
    );
    history.push(Routes.manage_form_detail);
  };

  const onEditIconClicked = (data) => {
    setEditData(data.currentmapobject);
    setisEdit(true);
    handleOpen();
  };

  const setCopy = (value) => {
    copy(value.currentmapobject?.form_id);

    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.success,
      msg: "Form ID Copied To ClipBoard !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const onDeleteIconClicked = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: Message.manage_form_delete_title,
      //body: Message.manage_form_delete_message,
      positiveBtn: Message.manage_form_delete_btn,
      negativeBtn: "Cancel",
      onOk: () => deleteCountry(data.currentmapobject),
    });
  };

  const deleteCountry = (data) => {
    dialogContext.setDialog({ ...dialogContext, open: false });
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: Message.manage_form_delete,
    });
    Promise.resolve(
      dispatch(
        ADD_DRUG_MASTER({
          _id: data._id,
          activestatus: false,
        })
      )
    ).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
      getForms();
      let resStatus = res.payload.error;
      alert.setSnack({
        ...alert,
        open: true,
        severity: resStatus
          ? AlertProps.severity.error
          : AlertProps.severity.success,
        msg: resStatus
          ? "Form deletion Failed !"
          : "Form deleted Successfully !",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createExcel = async () => {
    setCreatingExcel(true)
    let keyClkId = localStorage.getItem("kclkid");
    console.log(keyClkId, "keyClkId", typeof keyClkId);
    let params = {
      db_name:process.env.REACT_APP_DB,
      entity: "Person",
      filter: `Person.keycloackid=='${keyClkId}' && Person.activestatus==true`,
      return_fields: "Person",
    };
    let res = await AxiosCall("post", process.env.REACT_APP_API_URL, params);
    let personData = res?.result[0];

    console.log("resPerson", res?.result[0]);
    let personId = res?.result[0]?._id;
    setPersonID(personId);
    // setPersonId(personId);
    let orgType = res?.result[0]?.orgType;

    let params2 = {
      db_name: process.env.REACT_APP_DB,
      entity: "ContactPointMaster",
      filter: `ContactPointMaster._id=='${res?.result[0].telecom[0]}' && ContactPointMaster.activestatus==true`,
      return_fields: "ContactPointMaster.value",
    };
    let resContactPoint = await AxiosCall(
      "post",
      process.env.REACT_APP_API_URL,
      params2
    );
    console.log("resContactPoint", resContactPoint?.result[0]);
    let email = resContactPoint?.result[0];

    let createPayload = {
      personId: personId,
      orgType: orgType,
      email: email,
    };

    let createExcel = await AxiosCall(
      "post",
      process.env.REACT_APP_CREATEEXCEL_SERVICE,
      // "http://localhost:3009/createexcel",
      createPayload
    );

    setCreateEx({ ...createExcel });

    let paramsGet = {
      db_name: process.env.REACT_APP_DB,
      entity: "bulkdataupload",
      filter: `bulkdataupload.person_id=='${personId}' && bulkdataupload.activestatus==true`,
      return_fields: "bulkdataupload",
    };
    let resGet = await AxiosCall(
      "post",
      process.env.REACT_APP_API_URL,
      paramsGet
    );

    console.log("resGet", resGet.result);
    setExcelData([...resGet.result]);
    console.log("createExcel", createExcel);
    // let dounloex = await downloadExcelFile(createExcel?.url)
    // let dounloex1 = await downloadAndStoreFilename(createExcel?.url)

    // console.log("dounloex", dounloex1)
    if (createExcel) {
      setIsLoading(false);
      setCreatingExcel(false);
    }
  };

  console.log(createEx, "uploadopen");

  const uploadExcel = async (jsonExcelObject) => {
    setUploadfile(true);
    setUploadingExcel(true);

    // let bulkData = await axios({
    //   method: "POST",
    //   url: "https://arangodbservice.dev.ainqaplatform.in/api/read_documents",
    //   headers: { "Content-Type": "application/json" },
    //   data: [
    //     {
    //       "db_name": "pintar2u",
    //       "entity": "bulkdataupload",
    //       "filter": {
    //         "fileid": "12601"
    //       },
    //       "return_fields": "bulkdataupload"
    //     }
    //   ],
    // })

    let bulparams = {
      db_name: process.env.REACT_APP_DB,
      entity: "bulkdataupload",
      filter: `bulkdataupload.fileid=='${createEx?.fileid}'&&bulkdataupload.activestatus==true`,
      // "fileid"==="12601"

      return_fields: "bulkdataupload",
    };
    let bulkData = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      bulparams
    );

    console.log(bulkData, "bulkData");
    let uploadPayload = {
      uploaddata: [...jsonExcelObject],
      // fileId:"FileId"
      fileID: createEx?.fileid,
      bulkId: bulkData?.result[0]?._id,
    };
    console.log("uploadPayload", uploadPayload);
    let uploadapi = await AxiosCall(
      "post",
      process.env.REACT_APP_UPLOADEXCEL_SERVICE,
      // "http://localhost:3009/uploadexcel",
      uploadPayload
    );
    if (uploadapi?.uploadfile) {
      setUploadfile(false);
      setIsLoading2(false);
      setUploadingExcel(false);
      ToastMessage("File Uploaded successfully", "success", "top-center");
    }
    // console.log("uploadapi", uploadapi)
  };
  //ErrorIcon

  const readUploadFile = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    const filename = file?.name;

    console.log("fileO", file, file?.name);
    const fileNameWithoutExtension = filename.substring(
      0,
      filename.lastIndexOf(".xlsx")
    );

    const perName = personID.replace(/^Person\//, "");
    console.log(
      "fileNameWithoutExtension",
      fileNameWithoutExtension,
      typeof fileNameWithoutExtension,
      personID,
      typeof personID,
      perName
    );
    if (fileNameWithoutExtension == perName) {
      if (e.target.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          console.log("eTar", data, e.target);

          const workbook = xlsx.read(data, { type: "array" });
          console.log("workbookTar", workbook);
          const sheetName = workbook.SheetNames[0];
          console.log("sheetNameTar", sheetName);
          const worksheet = workbook.Sheets[sheetName];
          console.log("worksheetTar", worksheet);
          let json = xlsx.utils.sheet_to_json(worksheet);
          // console.log(json, "jsonDerilo");
          // setJsonExcelObject(json)
          uploadExcel(json);
        };
        reader.readAsArrayBuffer(e.target.files[0]);
      }
    } else {
      ToastMessage(
        "Not a valid file name. Give proper file name",
        "error",
        "top-center"
      );
    }
  };
  const getTime = (epoche) => {
    var myDate = new Date(Number(epoche) * 1000);

    return epoche
      ? `${myDate.getDate()}/${myDate.getMonth() + 1}/${myDate.getFullYear()}`
      : "-";
  };
  const downloadedExcelfile = async (value) => {
    console.log("value", value);
    let configUpdate = {
      method: "POST",
      url: process.env.REACT_APP_ARANGO_URL_UPSERT,
      headers: {
        "Content-Type": "application/json",
      },
      data: [
        {
          db_name: process.env.REACT_APP_DB,
          entity: "bulkdataupload",
          filter: { fileid: value?.fileid },
          doc: {
            status: "Downloaded",
            // price:updateItemData?.["Price"],
          },
        },
      ],
    };
    let downlod = await axios(configUpdate).then((res) => {
      return res;
    });
    window.open(value?.url);
  };
  // const downloadExcelFile = async (url) => {
  //   try {
  //     const response = await axios.get(url, { responseType: "arraybuffer" });
  //     const buffer = Buffer.from(response.data, "binary");
  //     const workbook = xlsx.read(buffer, { type: "buffer" });
  //     return workbook;
  //   } catch (error) {
  //     console.error("Error downloading the Excel file:", error);
  //     throw error;
  //   }
  // };

  return (
    <div>
      {delay && (
        <ManageCard
          //Below props for SearchFilter Props
          title="Item Master"
          topbuttonName={clientData === "" ? "Add Item" : ""}
          uplodName={"upload"}
          uploadfunc={uploadfunc}
          hideupload={true}
          hideFilter={true}
          globalsrc={""}
          showSort={false}
          addfunc={clientData === "" ? addForm : null}
          sortOptions={sortOptionArray}
          sortfunc={handleSortChange}
          searchfunc={onSearchChange}
          inbuiltsearchfunctinality={false}
          basedata={drugs.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
          keysformap={{
            title_key: "itemname",
            cardname_key: "selectedOrg",
            src_key: "drugimage",
          }}
          hidebtn1={false}
          Btn1Name={"View"}
          onBtn1Clicked={handleOpenView}
          show_card={clientData === "" ? false : true}
          show_sub_title={clientData == "" ? false : true}
          sub_title="Test"
          hideaddbutton={clientData === "" ? false : true}
          hidebtn2={true}
          isMoreBtn={clientData === "" ? true : false}
          hideEditBtn={clientData === "" ? false : true}
          hideDeleteBtn={clientData === "" ? false : true}
          editfunc={onEditIconClicked}
          deletefunc={onDeleteIconClicked}
          theme={props?.theme}
          hideAvatar={true}
          hideImage={false}
          defaultImage={
            "https://images.unsplash.com/photo-1587854692152-cbe660dbde88?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
          }
        />
      )}
      {drugs?.length > 0 && (
        <Paper elevation={0} className={classes.bottompaper}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={drugs?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes?.tablepage}
          />
        </Paper>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography className={classes.header}>
            {isEdit ? "Edit Item" : "Add Item"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onActionCompleted}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        {isEdit ? (
          <AddEditForm
            isEdit={isEdit}
            editData={editDataaa}
            formTypes={formTypes}
            upsertForm={upsertForm}
            onActionCompleted={onActionCompleted}
            UOM={UOM}
            diseasesData={drugs}
            DrugClass={DrugClass}
            Drugcategory={Drugcategory}
            Status={Status}
            druggeneric={druggeneric}
            drugform={drugform}
            viewopen={viewopen}
          />
        ) : (
          <AddEditForm
            onActionCompleted={onActionCompleted}
            formTypes={formTypes}
            upsertForm={upsertForm}
            diseasesData={drugs}
            UOM={UOM}
            DrugClass={DrugClass}
            Drugcategory={Drugcategory}
            Status={Status}
            druggeneric={druggeneric}
            drugform={drugform}
            isEdit={isEdit}
            viewopen={viewopen}
          />
        )}
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={viewopen}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography className={classes.header}>{"View Item"}</Typography>
          <IconButton
            aria-label="close"
            onClick={onViewActionCompleted}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />

        <AddEditForm
          isEdit={isEdit}
          editData={editDataaa}
          formTypes={formTypes}
          upsertForm={upsertForm}
          onActionCompleted={onViewActionCompleted}
          UOM={UOM}
          diseasesData={drugs}
          DrugClass={DrugClass}
          Drugcategory={Drugcategory}
          Status={Status}
          druggeneric={druggeneric}
          drugform={drugform}
          viewopen={viewopen}
        />
      </Dialog>

      <Dialog
        // fullScreen={fullScreen}
        open={upload}
        aria-labelledby="responsive-dialog-title"
        // aria-labelledby="scroll-dialog-title"
        // dividers={scroll === 'paper'}
        fullWidth
        maxWidth="md"
      >
        {/* <Grid style={{ position: "fixed" }}> */}
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {/* <Button
            variant="outline"
            color="primary"
            // style={{ alignItems:}}
            size="small"
          > */}

          <IconButton

            sx={{
              position: "absolute",
              right: 115,
              top: 10,

              cursor: "pointer",
              // left: 8,
              color: (theme) => theme.palette.primary[1000],

            }}

            onClick={() => downloadedExcelfile(createEx)}
            disabled={createEx?.url?.length > 0 ? false : true}
          >
            <CloudDownloadIcon />
          </IconButton>



          {/* <IconButton
            sx={{
              position: "absolute",
              right: 166,
              top: 8,
              // left: 8,
              color: (theme) => theme.palette.grey[5000],
            }}
            onClick={() => downloadedExcelfile(createEx)}
            disabled={excelData.length > 0 ? false : true}
          >
            <CloudDownloadIcon />
          </IconButton> */}

          <input
            type="file"
            // accept="file"
            style={{ display: "none" }}
            id="contained-button-file"
            onChange={readUploadFile}
          />
          <label htmlFor="contained-button-file">
            <Grid
              variant="outlined"
              color="primary"
              component="span"
              style={{
                position: "absolute",
                height: "28px",
                marginLeft: "790px",
                marginTop: "3px",
                color: "grey",
                cursor: "pointer"
              }}
            >

              <PublishIcon />

            </Grid>
            {/* <Button
              variant="contained"
              color="primary"
              component="span"
              style={{
                position: "absolute",
                height: "19px",
                marginLeft: "733px",
                marginTop: "2px",
              }}
            >
              Upload
            </Button> */}
          </label>


          <Typography
            style={{
              // position: "absolute",
              // paddingLeft: "235px",
              // top: 7,
              display: "flex",
              alignItems: "center",
              // color: (theme) => theme.palette.grey[500],
            }}
            className={classes.header}
          >
            Bulk upload
          </Typography>
          <IconButton
            aria-label="close"
            onClick={modelHandleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* </Grid> */}
        <Divider />

        <DialogContent>
          <DialogContentText>
            {uploadfile ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "23px",
                  }}
                >
                  <CircularProgress />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  {isLoading2 && <Typography>{message2}</Typography>}
                </div>
              </>
            ) : createEx?.url?.length > 0 && excelData.length > 0 ? (
              <>
                <div
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "20px",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="sticky table"
                      stickyHeader
                    >
                      <TableHead>
                        {/* <div style={{ position: 'sticky', bottom: 0, backgroundColor: '#fff', padding: '10px', }}> */}
                        <TableRow>
                          <TableCell style={{ paddingLeft: "50px" }}>
                            Id
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: "19px" }}
                            align="left"
                          >
                            Status
                          </TableCell>
                          <TableCell
                            style={{ paddingRight: "82px" }}
                            align="right"
                          >
                            Error
                          </TableCell>
                          <TableCell
                            style={{ paddingRight: "40px" }}
                            align="right"
                          >
                            Date
                          </TableCell>
                        </TableRow>
                        {/* </div> */}
                      </TableHead>

                      <TableBody>
                        {excelData
                          .slice(
                            pageq * rowsPerPageq,
                            pageq * rowsPerPageq + rowsPerPageq
                          )
                          .map((data) => (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                style={{ paddingLeft: "40px" }}
                                component="th"
                                scope="row"
                              >
                                {data?.fileid}
                              </TableCell>
                              <TableCell align="left">{data?.status}</TableCell>
                              <TableCell align="right">
                                {data?.status === "Error in processs" ? (
                                  <div
                                    style={{
                                      // paddingLeft: "40px"
                                      paddingRight: "66px",
                                    }}
                                  >
                                    <IconButton
                                      aria-label="error"
                                      onClick={() => errorFunc(data?.fileid)}
                                    >
                                      <ErrorIcon />
                                    </IconButton>
                                  </div>
                                ) : (
                                  <Grid style={{ paddingRight: "86px" }}>
                                    -
                                  </Grid>
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {getTime(data?.updatedate)}
                              </TableCell>
                            </TableRow>
                          ))}
                        {/* {map((row) => ( */}

                        {/* ))} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <DialogActions> */}
                  <div
                    style={{
                      position: "sticky",
                      bottom: -8,
                      backgroundColor: "#fff",
                      padding: "10px",
                    }}
                  >
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 20]}
                      component="div"
                      count={excelData.length}
                      rowsPerPage={rowsPerPageq}
                      page={pageq}
                      onPageChange={handleChangePageq}
                      onRowsPerPageChange={handleChangeRowsPerPageq}
                    />
                  </div>
                  {/* </DialogActions> */}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "123px",
                  }}
                >
                  <CircularProgress />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "30px",
                  }}
                >
                  {isLoading && <Typography>{message}</Typography>}
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        // fullScreen={fullScreen}
        open={errorScreen}
        aria-labelledby="responsive-dialog-title"
        // aria-labelledby="scroll-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {/* ArrowBackIosIcon */}
          <IconButton
            aria-label="close"
            onClick={modelErrorHandleClose}
            sx={{
              position: "absolute",
              // right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <KeyboardBackspaceIcon />
            {/* <ArrowBackIosIcon /> */}
          </IconButton>
          <Typography
            style={{
              // position: "absolute",
              paddingLeft: "63px",
              // top: 7,
              display: "flex",
              alignItems: "center",
              // color: (theme) => theme.palette.grey[500],
            }}
            className={classes.header}
          >
            Error Message
          </Typography>
          <IconButton
            aria-label="close"
            onClick={modelErrorHandleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText>
            {errData?.fileid?.length > 0 && errLine.length > 0 ? (
              <>
                <div
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "40px",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ paddingLeft: "40px" }}
                            align="left"
                          >
                            Error Message
                          </TableCell>
                          <TableCell
                            style={{ paddingLeft: "83px" }}
                            align="center"
                          >
                            Line Number
                          </TableCell>
                          <TableCell
                            style={{ paddingRight: "100px" }}
                            align="right"
                          >
                            Errors
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {errLine
                          .slice(
                            pageq1 * rowsPerPageq1,
                            pageq1 * rowsPerPageq1 + rowsPerPageq1
                          )
                          .map((data) => (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                              >
                                {errData?.error_text}
                              </TableCell>
                              <TableCell
                                style={{ paddingLeft: "65px" }}
                                align="center"
                              >
                                {data?.linenumber}
                              </TableCell>
                              <TableCell align="right">
                                {data?.fields?.join(", ")}
                              </TableCell>
                            </TableRow>
                          ))}

                        {/* {map((row) => ( */}

                        {/* ))} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div
                    style={{
                      position: "sticky",
                      bottom: -8,
                      backgroundColor: "#fff",
                      padding: "10px",
                    }}
                  >
                    <TablePagination
                      // onScrollCapture={}

                      rowsPerPageOptions={[5, 10, 20]}
                      component="div"
                      count={errLine.length}
                      rowsPerPage={rowsPerPageq1}
                      page={pageq1}
                      onPageChange={handleChangePageq1}
                      onRowsPerPageChange={handleChangeRowsPerPageq1}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, actions)(withTheme(ItemMasterParent));
