import React, { useContext } from "react";
import {
  Box,
  makeStyles,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TextField,
  Link,
} from "@material-ui/core";
// import { Mobile } from "../../components";
import Config from "../../config";
import { AxiosCall } from "../../utils";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { Input } from "../../components";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import axios from "axios";
import { AlertContext, BackdropContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.appBar.main,
    display: "flex",
    justifyContent: "center",
    minHeight: "48px",
    maxHeight: "100px",
    padding: `${theme.spacing(0)} ${theme.spacing(2)}`,
  },
  toolbar: {},
  body: {
    height: "calc(100% - 100px)",
    padding: "22px 16px",
  },
  progressBar: {
    color: "#ffffff",
  },
  signInWrapper: {
    padding: `${theme.spacing(1)}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rootoftextfield: {
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
    "& .MuiFormHelperText-contained": {
      margin: 0,
      marginTop: 2,
      color: "red",
      fontSize: "10px",
    },
  },
  labelStyle: {
    fontSize: 16,
  },
}));

const HomeBody = () => {
  const classes = useStyles();
  const alert = useContext(AlertContext);
  const backdrop = useContext(BackdropContext);
  const [email, setEmail] = React.useState("");
  // const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showOTP, setShowOTP] = React.useState(false);
  const history = useHistory();
  const [emailerror, setEmailError] = React.useState("");
  const form = useForm({
    defaultValues: {
      email: "",
      otp: "",
    },
  });
  const form_for_email = useForm({
    defaultValues: {
      email: "",
    },
  });
  const {
    register,
    handleSubmit,
    formState,
    getValues,
    control,
    resetField,
    setError,
    setFocus,
  } = form;
  const { errors } = formState;
  // const {
  //   register: regis_for_email,
  //   handleSubmit: submit_email,
  //   formState: formstate_email,
  //   control: controler,
  //   getValues: getValues_from_email,
  // } = form_for_email;
  // const { errors: error_for_email } = formstate_email;
  const onHandleChange = (e) => {
    setEmail(e);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const onSubmit = (email) => {
    return {
      db_name: Config.dbname,
      entity: "Person",
      filter: `document(Person.telecom)[*].value any =='${email}' && Person.activestatus==true`,
      return_fields: "Person",
    };
  };

  const isObjectEmpty = (objectName) => {
    for (let prop in objectName) {
      if (objectName.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  };

  const sendOTP = async (dataFromFrom, e, resend) => {
    let email = dataFromFrom?.email || getValues("email");
    if (!email) {
      alert.setSnack({
        ...alert,
        open: true,
        msg: "please enter the email",
        severity: "warning",
      });
      return;
    }
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });

    let result = onSubmit(email);
    let data = await AxiosCall(
      "post",
      `${Config.api_url}/api/read_documents`,
      result
    );
    if (data?.Code === 201) {
      if (data?.result?.length > 0) {
        history.push({
          pathname: Routes.login,
          state: {
            val: btoa(email),
          },
        });
        setLoading(false);
      } else {
        let params = {
          email: email ?? "",
        };
        let config = {
          method: "post",
          url: process.env.REACT_APP_BACKEND_SEND_OTP_TO_EMAIL_URL,
          headers: {
            "Content-Type": "application/json",
          },
          data: params,
        };
        await axios(config)
          .then(async (res) => {
            if (res?.data?.status === "true") {
              alert.setSnack({
                ...alert,
                open: true,
                msg: `OTP is ${resend ? "resent" : "sent"} successfully`,
                severity: "success",
              });
              setShowOTP(true);
            } else {
              alert.setSnack({
                ...alert,
                open: true,
                msg: "something went wrong !",
                severity: "error",
              });
            }
          })
          .catch((err) => {
            alert.setSnack({
              ...alert,
              open: true,
              msg: err?.message,
              severity: "error",
            });
          });
      }
    }
    backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
  };

  const ResendOTP = (data) => {};

  const VerifyOTP = async (data) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Verfiying the OTP",
    });
    let params = {
      email: data?.email ?? "",
      verifyotp: data?.otp ?? "",
    };
    let config = {
      method: "post",
      url: process.env.REACT_APP_BACKEND_SEND_VERFIY_EMAIL_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    axios(config)
      .then((res) => {
        if (res?.data?.status === "Verified") {
          alert.setSnack({
            ...alert,
            open: true,
            msg: "OTP is verified successfully",
            severity: "success",
          });
          history.push({
            pathname: "/registration",
            state: {
              Email: data?.email,
            },
          });
        } else if (res?.data?.status === "Invalid") {
          alert.setSnack({
            ...alert,
            open: true,
            msg: "Invalid OTP !",
            severity: "error",
          });
          setError("otp", { message: "invalid OTP" });
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            msg: "something went wrong !",
            severity: "error",
          });
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          msg: err.message,
          severity: "error",
        });
      });
    backdrop.setBackDrop({ ...backdrop, open: false, message: "" });
  };

  return (
    <Box className={classes.root}>
      <DevTool control={control} />
      <div className={classes.appBar}>
        <img
          alt="icon"
          // src="images/pintar2u.webp"
          style={{ width: "150px", height: "80px", aspectRatio: "1" }}
        />
      </div>
      <Box className={classes.body}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} lg={3} md={6} sm={8}>
            <Typography variant="h5">Our Partners</Typography>
          </Grid>

          <Grid
            item
            xs={12}
            lg={3}
            md={6}
            sm={8}
            style={{ marginTop: "16px" }}
            justifyContent="center"
          >
            <img
              alt="icon"
              src="images/SMEBankGroupLogo.png"
              style={{ width: "100px", height: "60px", aspectRatio: "1" }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="center"
          //   alignItems="center"
        >
          <Grid item xs={12} lg={3} md={6} sm={8}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Box>
                <Typography
                  variant="h5"
                  align="center"
                  color="secondary"
                  gutterBottom
                  style={{ marginTop: "12px" }}
                >
                  Register Now !
                </Typography>
              </Box>
              <div className={classes.rootoftextfield}>
                <Typography
                  variant="body1"
                  style={{ fontSize: 16 }}
                  gutterBottom
                >
                  Email
                  <Typography variant="caption" style={{ color: "red" }}>
                    *
                  </Typography>
                </Typography>
                <TextField
                  type={"email"}
                  id={"email"}
                  variant={"outlined"}
                  fullWidth
                  InputLabelProps={{
                    shrink: false,
                  }}
                  disabled={showOTP}
                  size="small"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                  error={errors?.email}
                  helperText={errors?.email?.message}
                />
              </div>
              <Button
                variant="contained"
                style={{
                  background: "#01205C",
                  color: "#fff",
                  margin: "7px 0px",
                }}
                // color="primary"
                fullWidth
                size="small"
                onClick={handleSubmit(sendOTP)}
                disabled={showOTP}
              >
                Send OTP
              </Button>
              {showOTP && (
                <>
                  <div className={classes.rootoftextfield}>
                    <Typography
                      variant="body1"
                      style={{ fontSize: 16 }}
                      gutterBottom
                    >
                      OTP
                      <Typography variant="caption" style={{ color: "red" }}>
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      type={"number"}
                      id={"otp"}
                      variant={"outlined"}
                      fullWidth
                      InputLabelProps={{
                        shrink: false,
                      }}
                      inputProps={{
                        maxLength: 6,
                      }}
                      size="small"
                      {...register("otp", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        maxLength: {
                          value: 6,
                          message: "only six digits are allowed",
                        },
                        validate: {
                          sixdigit: (data) => {
                            return (
                              data?.length === 6 || "OTP must be six digits"
                            );
                          },
                        },
                        pattern: {
                          value: /^[0-9]{1,6}$/,
                          message: "OTP must be six digit number",
                        },
                      })}
                      error={errors?.otp}
                      helperText={errors?.otp?.message}
                    />
                  </div>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "7px 0px",
                    }}
                  >
                    <Typography variant="caption">
                      Didn't receive one time password{" "}
                    </Typography>
                    <Link
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => sendOTP(undefined, undefined, true)}
                    >
                      Resend again
                    </Link>
                  </span>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      margin: "7px 0px",
                    }}
                  >
                    <Link
                      style={{ fontSize: "12px", cursor: "pointer" }}
                      onClick={() => {
                        setShowOTP(false);
                        resetField("otp");
                      }}
                    >
                      change email address
                    </Link>
                  </span>
                  <Box style={{ marginTop: "0px" }}>
                    <>
                      {loading ? (
                        <Button
                          variant="contained"
                          size="large"
                          // color="primary"
                          style={{ background: "#01205C", color: "#fff" }}
                          fullWidth
                        >
                          <CircularProgress
                            size={24}
                            className={classes.progressBar}
                          />
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={handleSubmit(VerifyOTP)}
                          style={{ background: "#01205C", color: "#fff" }}
                          // color="primary"
                          fullWidth
                          size="small"
                        >
                          Verify OTP
                        </Button>
                      )}
                    </>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HomeBody;
