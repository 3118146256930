import { CircularProgress, Divider, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { Cards } from "../card";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
// import WalletIcon from "@mui/icons-material/Wallet";
import SavingsIcon from "@mui/icons-material/Savings";
import { TimeLine } from "../timeline";
import { AlertProps, validateSpecialCharacter } from "../../utils";
import { AlertContext, BackdropContext } from "../../contexts";
import axios from "axios";
import { ToastMessage } from "../../utils";
import { PageEmpty } from "../pageEmpty";
import { TableComponet } from "../table";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(1),
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
  },
  flex: {
    display: "flex",
  },
}));
export const MainPanel = (props) => {
  const {
    selectedTask,
    logState,
    isLoading,
    onUserActionPerformed,
    scenarioState,
    timeline,
    updateTimeLine,
    alignment,
    updateState,
    PermissionData,
    scenarioId,
    config,
    updateselectedList = () => false,
    alltickets,
  } = props;
  // console.log("propsOncard", props)
  const classes = useStyles();
  const backDrop = React.useContext(BackdropContext);
  const alertMessage = React.useContext(AlertContext);
  const [statesAction, setStatesAction] = React.useState([]);
  const [creditDetails, setCreditDetails] = React.useState({});
  const [taskTable, settaskTable] = React.useState([]);
  const [allTicketsids, setAllTicketsids] = React.useState([]);
  const [selectdPharmacy, setselectPharmacy] = React.useState({});

  // const [tableWithId, setTableWithId] = React.useState([]);
  const db = sessionStorage.getItem("ProjectDbname");
  let token = localStorage.getItem("aTclk");
  let Userdata = jwt_decode(token);
  // console.log({ Userdata })
  React.useEffect(() => {
    if (selectedTask?.ticketId) {
      getApplicableAction(selectedTask?.ticketId);
      getTransactionId(selectedTask?.ticketId);
      getCreditData(selectedTask?.ticketId);
      setAllTicketsids([]);
    }
    // if (selectedTask?.table && Array.isArray(selectedTask?.table)) {
    //   let val = selectedTask?.table.map((l, index) => ({ ...l, id: index }));
    //   setTableWithId(val);
    // }

    //eslint-disable-next-line
  }, [selectedTask?.ticketId]);

  React.useEffect(async () => {
    let ticId = [];
    let Arr = [];

    if (alltickets?.length > 0) {
      alltickets?.map((li) => {
        ticId.push(li.ticketId);
      });
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        db_name: process.env.REACT_APP_DB,
        filter: {
          ticketId: ticId,
        },
        queryid: "4b78f4dd-878f-488d-b2ce-19b9d197e5e1",
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES, requestOptions)
        .then((response) => response.json())
        .then((result) => {
         
          result.map((li) => {
            let value = li?.orderDetails?.OrderItemCode;
            value["ticketId"] = li?.orderDetails?.ticketId;
            Arr.push(value);
          });
          settaskTable(Arr);
          setAllTicketsids(ticId);
          setselectPharmacy(alltickets?.[0]);
          getApplicableAction(ticId[0]);
          getTransactionId(ticId[0]);
          getCreditData(ticId[0]);
        })
        .catch((error) => console.log("error", error));
    } else {
      setselectPharmacy({});
      setAllTicketsids([]);
    }
  }, [alltickets]);

  React.useEffect(() => {
   
    if (selectedTask?.table) {
      let value = selectedTask?.table;
      value["ticketId"] = selectedTask?.ticketId;
      let Arr = [];
      Arr.push(value);
      settaskTable(Arr);
    }
  }, [selectedTask?.table]);

  // React.useEffect(() => {

  //   console.log("creditDetails" , creditDetails)
  //   let cr = creditDetails.orderInProcess - props?.selectedTask?.table?.totalprice;
  //   if (cr <= 0) {
  //     // alertMessage.setSnack({
  //     //   ...alertMessage,
  //     //   open: true,
  //     //   severity: AlertProps.severity.error,
  //     //   msg: "Credit limit exceed !",
  //     //   vertical: AlertProps.vertical.top,
  //     //   horizontal: AlertProps.horizontal.center,
  //     // });
  //     ToastMessage(`Credit limit exceeded`, "error", "top-center");
  //     // backDrop.setBackDrop({
  //     //   ...backDrop,
  //     //   open: false,
  //     // });
  //   }
  // }, [props?.selectedTask?.table?.totalprice]);

  const checkCreditDEtails = async (tik, selectedTask) => {
    
    // let tik = props.selectedTask.ticketId;
    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.ticketId=='${tik}'and QDMATPtransactionlog.activestatus==true`,
      return_fields:
        "{PharmacyDetails:QDMATPtransactionlog.payload.inputDoc.PharmacyDetails}",
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };

    let result = await axios(config).then((res) => {
      return res?.data?.result?.[0]?.PharmacyDetails?._id;
    });

    let creditPayload = {
      // date: Date.now(),
      //date: Math.floor(new Date() / 1000),
      Bedcode: result,
      queueId: "queue/10025",
    };

    let config2 = {
      method: "post",
      url: `${process.env.REACT_APP_CREDIT_API}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: creditPayload,
    };

    let result2 = await axios(config2).then((res) => {
      return res.data;
    });

    setCreditDetails({ ...result2 });

    let datasss = { ...result2 };

    let cr = datasss?.orderInProcess - selectedTask;
    if (result2?.difference <= 0) {
      ToastMessage(`Credit limit exceeded`, "error", "top-right");
    }
  };

  const getApplicableAction = async (ticketId) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog,queuescenariostatemapping",
        filter: {
          QDMATPtransactionlog: `QDMATPtransactionlog.activestatus==true && QDMATPtransactionlog.ticketId=='${ticketId}'`,
          queuescenariostatemapping:
            "queuescenariostatemapping.activestatus==true && queuescenariostatemapping.queueid==QDMATPtransactionlog.queueIds && queuescenariostatemapping.scenarioid IN QDMATPtransactionlog.payload.scenarioId[*] LET docstates=(FOR docstates IN TO_ARRAY(queuescenariostatemapping.states) FILTER docstates._id IN QDMATPtransactionlog.payload.statesid[*] return docstates.action[*])",
        },
        return_fields:
          "Merge({docStatesActions:(For docstateaction in docstates[0] return Merge(docstateaction,unset(document(docstateaction._id),'createddate','updatedate','_rev','version','_key','activestatus','updatedby','active','createdby')))})",
      };

      // SetLoading(true);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            // console.log("data?.docStatesActions", data?.docStatesActions);
            setStatesAction(data?.docStatesActions);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const getTransactionId = async (ticketId) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId=='${ticketId}'`,
        return_fields: "{ _id: QDMATPtransactionlog._id }",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            getAduitLog(data._id);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  const getAduitLog = async (_id) => {
    try {
      let params = {
        db_name: sessionStorage.getItem("AuditDB"),
        entity: "QDMATPtransactionlog_audit",
        filter: `QDMATPtransactionlog_audit.refkey=='${_id}'`,
        return_fields: "QDMATPtransactionlog_audit",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result;

            if (data.length > 0) {
              let decodeArr = data.map((l) => {
                let Payload = atob(l.payload);
                let Document = atob(l.document);
                return {
                  ...l,
                  payload: JSON.parse(Payload),
                  document: JSON.parse(Document),
                };
              });
              // let stateList = props.scenarioState;
              let isArrHasState = decodeArr.filter(
                (l) =>
                  l?.document?.payload?.statesid &&
                  l?.document?.payload?.queueMetaData?.userAction?.userAction
              );

              let z = scenarioState.map((l) =>
                Object.assign(l, { statustime: "", userAction: "" })
              );

              for (let j = 0; j < z.length; j++) {
                for (let i = 0; i < isArrHasState.length; i++) {
                  if (
                    z[j]._id === isArrHasState[i].document.payload.statesid[0]
                  ) {
                    Object.assign(z[j], {
                      statustime: isArrHasState[i].statustime,
                      userAction:
                        isArrHasState[i].document?.payload?.queueMetaData
                          ?.userAction?.userAction,
                    });
                  }
                }
              }

              updateTimeLine(z);
            } else {
              let z = scenarioState.map((l) =>
                Object.assign(l, { statustime: "", userAction: "" })
              );
              updateTimeLine(z);
            }

            //  getAduitLog(data._id);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          let z = scenarioState.map((l) =>
            Object.assign(l, { statustime: "", userAction: "" })
          );
          updateTimeLine(z);
          console.log(err);
        });
    } catch (error) {
      // updateTimeLine(scenarioState);
    }
  };

  const getCreditData = async (tik) => {
    //let tik = props.selectedTask.ticketId;
    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "QDMATPtransactionlog",
      filter: `QDMATPtransactionlog.ticketId=='${tik}'and QDMATPtransactionlog.activestatus==true`,
      return_fields:
        "{PharmacyDetails:QDMATPtransactionlog.payload.inputDoc.pharmacy_Id}",
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };

    let result = await axios(config).then((res) => {
      return res?.data?.result?.[0]?.PharmacyDetails;
    });

    let creditPayload = {
      // date: Date.now(),
      //date: Math.floor(new Date() / 1000),
      Bedcode: result,
      queueId: "queue/10025",
    };

    let config2 = {
      method: "post",
      url: `${process.env.REACT_APP_CREDIT_API}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: creditPayload,
    };

    let result2 = await axios(config2).then((res) => {
      return res.data;
    });

    setCreditDetails({ ...result2 });

    // let creditData = await AxiosCall(
    //   "post",
    //   "https://centralpharma.dev.ainqaplatform.in/creditlimit",
    //   creditPayload
    // );

    // console.log("CreditData", creditData);
    // setCreditDetails({ ...creditData });
  };

  const a = new Date(selectedTask?.field9 * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // url: `${process.env.REACT_APP_PERFORMUSERACTION_API}`,
  const performUserAction = async (
    Action,
    ticketId,
    formDataOfState = {},
    nameOfState = "",
    entryid = ""
  ) => {
    formDataOfState = { ...formDataOfState, entry_id: entryid };
    if (Action) {
      try {
        backDrop.setBackDrop({
          ...backDrop,
          open: true,
          message: "Loading...",
        });
        let config = {
          method: "post",
          url: `${sessionStorage.getItem("PerformUserAction")}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            ticketId: ticketId,
            userAction: Action.action,
            data: {
              ticketId: Array.isArray(ticketId) ? ticketId : [ticketId],
              formDataOfState,
              nameOfState,
              entryid,
              PaymentStatus: nameOfState == "PaymentDetails" ? "Completed" : "",
              Action,
              keycloackid: localStorage.getItem("kclkid"),
              username: Userdata?.name,
            },
          },
        };
        await axios(config).then((res) => {
          if (Action.apiendpoint.length !== 0 && !Action?.backEndCall) {
            setTimeout(() => {
              axios({
                method: "POST",
                url: `${Action.apiendpoint}`,
                // url: `http://localhost:3009/paymentordispensepost`,
                headers: { "Content-Type": "application/json" },
                data: {
                  ticketId: Array.isArray(ticketId) ? ticketId : [ticketId],
                  formDataOfState,
                  nameOfState,
                  entryid,
                  PaymentStatus:
                    nameOfState == "PaymentDetails" ? "Completed" : "",
                  Action,
                  keycloackid: localStorage.getItem("kclkid"),
                  username: Userdata?.name,
                },
              }).then((res1) => {
                if (!res1.data.error) {
                  alertMessage?.setSnack({
                    ...alertMessage,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `You've marked report as "${Action.action}"`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                  });
                  onUserActionPerformed(ticketId);
                } else {
                  alertMessage?.setSnack({
                    ...alertMessage,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `${res1?.data?.message ?? res1?.data?.msg}`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                  });
                }
              });
              backDrop.setBackDrop({
                ...backDrop,
                open: false,
              });
            }, 3000);
          } else {
            if (!res.data.error) {
              alertMessage?.setSnack({
                ...alertMessage,
                open: true,
                severity: AlertProps.severity.success,
                msg: `You've marked report as "${Action.action}"`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
              // onUserActionPerformed(selectedTask?.subtitle2);
              onUserActionPerformed(ticketId);
              // setState({});
              // setAlignment("");
            } else {
              backDrop.setBackDrop({
                ...backDrop,
                open: false,
              });
              alertMessage?.setSnack({
                ...alertMessage,
                open: true,
                severity: AlertProps.severity.error,
                msg: `${res?.data?.message ?? res?.data?.msg}`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
              // setAlignment("");
            }
          }
        });
      } catch (error) {}
    }
  };

  return (
    <div className={classes.root}>
      {logState?.length !== 0 ? (
        <>
          {alltickets?.length > 0 ? (
            allTicketsids?.length <= 0 ? (
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <Typography variant="body1" color="InactiveCaptionText">
                  {`"Please select a task from the left panel to view the configuration"`}
                </Typography>
              </div>
            ) : (
              <>
                <Cards
                  scenarioId={scenarioId}
                  type={2}
                  action={statesAction}
                  field1={selectdPharmacy?.field1?.toString()}
                  field2={selectedTask?.field2?.toString()}
                  field3={selectedTask?.field3?.toString()}
                  field4={selectedTask?.field4?.toString()}
                  field5={selectedTask?.field5?.toString()}
                  field6={selectedTask?.field6?.toString()}
                  field7={selectedTask?.field7?.toString()}
                  field8={selectedTask?.field8?.toString()}
                  field9={selectedTask?.field9?.toString()}
                  field10={selectedTask?.field10?.toString()}
                  field11={selectedTask?.field11?.toString()}
                  field12={selectedTask?.field12?.toString()}
                  field13={selectedTask?.field13?.toString()}
                  ticketId={allTicketsids.toString()}
                  state={selectdPharmacy?.state?.states}
                  performUserAction={performUserAction}
                  updateState={updateState}
                  alignment={alignment}
                  PermissionData={PermissionData}
                  allTicketsids={allTicketsids}
                />

                <div
                  style={{
                    height: "calc(100% -  120px)",
                    overflowX: "auto",
                    margin: "8px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "90px",
                      justifyContent: "space-around",
                      alignItems: "center",
                      backgroundColor: " #ffffff",
                      borderRadius: "10px",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      padding: "16px",
                      marginTop: "5px",
                      border: "0.2px solid #D3D3D3",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AccountBalanceIcon />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "15px" }}
                          variant="body2"
                        >
                          Credit Limit
                        </Typography>
                        <Typography variant="body3">
                          {creditDetails?.creditLimit} MYR
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <LocalMallIcon />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "15px" }}
                          variant="body2"
                        >
                          Old Outstanding
                        </Typography>
                        <Typography variant="body3">
                          {creditDetails?.OldOutstanding} MYR
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ShoppingCartCheckoutIcon />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "15px" }}
                          variant="body2"
                        >
                          Order In Process
                        </Typography>
                        <Typography variant="body3">
                          {creditDetails?.orderInProcess} MYR
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <AccountBalanceWalletOutlinedIcon />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "10px",
                        }}
                      >
                        <Typography
                          style={{ fontSize: "15px" }}
                          variant="body2"
                        >
                          Available Credit
                        </Typography>
                        <Typography variant="body3">
                          {creditDetails?.difference} MYR
                        </Typography>
                      </div>
                    </div>
                  </div>
                  {taskTable?.length > 0 && config?.tableConfig.length > 0 && (
                    <>
                      <div
                        style={{
                          margin: "4px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body1" color="InactiveCaptionText">
                          &nbsp;{"Order Details"}
                        </Typography>
                      </div>
                      <div style={{ minHeight: 200, width: "100%" }}>
                        <TableComponet
                          TableHeader={config?.tableConfig}
                          TableContent={taskTable}
                          state={selectdPharmacy?.state?.states}
                          stateID={selectdPharmacy?.state?.statesid}
                          ticketId={selectedTask?.ticketId}
                          updateselectedList={updateselectedList}
                          logState={logState}
                          getCreditData={getCreditData}
                          checkCreditDEtails={checkCreditDEtails}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )
          ) : Object.keys(selectedTask)?.length === 0 ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Typography variant="body1" color="InactiveCaptionText">
                {`"Please select a task from the left panel to view the configuration"`}
              </Typography>
            </div>
          ) : (
            <>
              <Cards
                scenarioId={scenarioId}
                type={2}
                action={statesAction}
                field1={selectedTask?.field1?.toString()}
                field2={selectedTask?.field2?.toString()}
                field3={selectedTask?.field3?.toString()}
                field4={selectedTask?.field4?.toString()}
                field5={selectedTask?.field5?.toString()}
                field6={selectedTask?.field6?.toString()}
                field7={selectedTask?.field7?.toString()}
                field8={selectedTask?.field8?.toString()}
                field9={selectedTask?.field9?.toString()}
                field10={selectedTask?.field10?.toString()}
                field11={selectedTask?.field11?.toString()}
                field12={selectedTask?.field12?.toString()}
                field13={selectedTask?.field13?.toString()}
                ticketId={selectedTask?.ticketId}
                state={selectedTask?.state?.states}
                performUserAction={performUserAction}
                updateState={updateState}
                alignment={alignment}
                PermissionData={PermissionData}
              />

              <div
                style={{
                  height: "calc(100% -  120px)",
                  overflowX: "auto",
                  margin: "8px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "70px",
                    justifyContent: "space-around",
                    alignItems: "center",
                    backgroundColor: " #ffffff",
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    padding: "10px",
                    marginTop: "5px",
                    border: "0.2px solid #D3D3D3",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AccountBalanceIcon />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ fontSize: "15px" }} variant="body2">
                        Credit Limit
                      </Typography>
                      <Typography variant="body3">
                        {creditDetails?.creditLimit} MYR
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LocalMallIcon />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ fontSize: "15px" }} variant="body2">
                        Old Outstanding
                      </Typography>
                      <Typography variant="body3">
                        {creditDetails?.OldOutstanding} MYR
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ShoppingCartCheckoutIcon />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ fontSize: "15px" }} variant="body2">
                        Order In Process
                      </Typography>
                      <Typography variant="body3">
                        {creditDetails?.orderInProcess} MYR
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AccountBalanceWalletOutlinedIcon />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography style={{ fontSize: "15px" }} variant="body2">
                        Available Credit
                      </Typography>
                      <Typography variant="body3">
                        {creditDetails?.difference} MYR
                      </Typography>
                    </div>
                  </div>
                </div>
                {taskTable?.length > 0 && config?.tableConfig.length > 0 && (
                  <>
                    <div
                      style={{
                        margin: "4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Typography variant="body1" color="InactiveCaptionText">
                          &nbsp;{"Order Details"}
                        </Typography> */}
                    </div>
                    <div style={{ minHeight: 200, width: "100%" }}>
                      <TableComponet
                        TableHeader={config?.tableConfig}
                        TableContent={taskTable}
                        state={props?.selectedTask?.state?.states}
                        stateID={props?.selectedTask?.state?.statesid}
                        ticketId={selectedTask?.ticketId}
                        updateselectedList={updateselectedList}
                        logState={logState}
                        getCreditData={getCreditData}
                        checkCreditDEtails={checkCreditDEtails}
                      />
                    </div>
                  </>
                )}
                {/* <Divider style={{ margin: "16px 0px" }} />
                  <div>
                    <div
                      style={{
                        margin: "4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AccessTimeIcon color="info" />
                      <Typography variant="caption" color="InactiveCaptionText">
                        &nbsp;{"Timeline"}
                      </Typography>
                    </div>
                    <TimeLine
                      scenarioState={timeline}
                      currantState={selectedTask?.state}
                    />
                  </div> */}
              </div>
            </>
          )}
        </>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          {isLoading ? <CircularProgress /> : <PageEmpty />}
        </div>
      )}
    </div>
  );
};
