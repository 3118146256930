import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps, validateSpecialCharacter, AxiosCall } from "../../utils";
import { v4 as uuidV4 } from "uuid";

import axios from "axios";

export const TableComponet = (props) => {
  const {
    TableHeader,
    TableContent,
    ticketId,
    updateselectedList = () => false,
    getCreditData = () => false,
    checkCreditDEtails = () => false,
  } = props;
  const alertMessage = React.useContext(AlertContext);
  const [data, setdata] = useState([]);
  const [queId, setQueId] = useState("");
  const [headerData, setHeaderData] = useState([]);
  // const [disble, setDisable] = useState(false);

  useEffect(() => {
    let arry = [];
    TableContent?.[0]?.forEach((v) => {
      let a = {
        id: uuidV4(),
        ...v,
      };
      arry.push(a);
    });
    setdata(arry);
  }, [TableContent]);

  let getId = async () => {
    let param2 = {
      db_name: process.env.REACT_APP_DB,
      entity: "CodeableConceptMaster",
      filter: `CodeableConceptMaster.activestatus==true && CodeableConceptMaster.Type=='${process.env.REACT_APP_ALLOW_EDIT_DISPENSE}'`,
      return_fields:
        "MERGE({coding:(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod).display)})",
    };

    let con = {
      method: "post",
      url: process.env.REACT_APP_ARANGO_URL_READ,
      headers: { "Content-Type": "application/json" },
      data: param2,
    };
    let data1 = await axios(con).then((res) => {
      return res.data.result;
    });
    let queId1 = data1[0].coding[0];

    let queId2 = data1[1].coding[0];

    // console.log("tole", queId1);

    // console.log("tole2", queId2);

    if (props?.stateID === queId1 || props?.stateID === queId2) {
      const newArray = [...TableHeader];
      newArray[3].editable = true;
      setHeaderData([...newArray]);
    } else {
      const newArray = [...TableHeader];
      newArray[3].editable = false;
      setHeaderData([...newArray]);
    }

    return data1;
  };

  useEffect(() => {
    getId();
  }, [props.state]);

  //console.log("dataheaderData", headerData, data)
  return (
    <div style={{ height: 200, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={headerData}
        getRowId={(TableContent) => TableContent?.id}
        // autoHeight={true}
        //autoPageSize={true}
        density={"compact"}
        headerHeight={45}
        hideFooter={true}
        //onCellEditCommit={RowEDit}
      />
    </div>
  );
};
