/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-27
 * @modify date 2021-06-25
 * @desc Form Renderer
 */
import { makeStyles, Grid, Paper } from "@material-ui/core";
import React from "react";
import { NotFoundSection, SubHeader, CaseDefinition } from "../../components";
import { FormTreeView } from "./formTree";
import { FormBuilder } from "./formBuilder";
import { DrawerContext } from "../../contexts";
import { DrawerProps } from "../../utils";
// import { PatientDetail } from "./patientDetail";
// import { PatientDetailCard } from "../../components/patientDetailCard";
// import { UserDetailCard } from "../../components/userDetailsCard";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Tooltip, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { actions, idmapis } from "central_pharma_binder";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 16,
    [theme.breakpoints.only("xs")]: {
      padding: 10,
    },
  },
  sub: {},
  infocardGrid: {
    margin: "12px 0px -10px 20px",
  },
  infocard: {
    maxWidth: "97%",
    padding: "0px",
    margin: "12px",
  },
  cardContent: {
    //boxShadow: theme.shadows[10],
    paddingBottom: "8px",
  },
  cardContent1: {
    float: "Right",
  },
  render: {
    // marginTop: "20px",
    // padding: theme.spacing(4),
  },

  backBtn: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 12,
    },
    boxShadow: "0px 15px 25px #110f4712",
    backgroundColor: "#fff",
    paddingRight: theme.spacing(2),
    "&:hover": {
      boxShadow: "0px 15px 25px #110f4712",
      backgroundColor: "#fff",
      paddingRight: theme.spacing(2),
    },
  },
  treePaper: {
    padding: theme.spacing(1),
    marginBottom: 10,
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 150px)",
      marginBottom: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopRightRadius: 12,
    border: "1px solid #0000001A",
    boxShadow: "0px 15px 25px #110f4712",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  patientdetailpaper: {
    height: "100px",
  },
  formBuilderPaper: {
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      // borderTopLeftRadius: 0,
      // borderBottomLeftRadius: 0,
    },
    overflow: "auto",
    padding: "16px",
    // boxShadow: "0px 15px 25px #110f4712",
    // borderRadius: "8px",
    // border: "1px solid #0000001A",
  },
  subbacktext: {
    opacity: 0.6,
    fontSize: 12,
    paddingLeft: 4,
  },
}));

export const FormRenderer = ({
  form_detail,
  transferDetails,
  tree_view,
  forms,
  parent_form_id,
  entry_id,
  isEdit,
  answer,
  submitBtnBottom,
  isInvestigator = false,
  secToShow = [],
  secName = "",
  onSaveForm = () => false,
  isReadyOnly = false,
  toggleButtonStatus = {},
  getPatientDetails = {},
  entryPatientDetails = {},
  dataSecurityMappedCountryAndSelectedlevel = {},
  mappedLocations = {},
  goBack = () => false,
  hideBtn,
  hidePatient,
  ticketId,
  isCancel = false,
  isCancelFunc = () => false,
}) => {
  // const getIntialState = () => {
  //   let updatefromExpaneded = {
  //     ...forms[0],
  //     expanded: [...forms[0]?.expanded, forms[0].id],
  //   };
  //   let obj = {
  //     selected_form: updatefromExpaneded,
  //     form_id: forms[0]?.id,
  //     answer: answer,
  //   };
  //   return obj;
  // };

  // console.log("Inner", ticketId);
  const classes = useStyles();
  const dispatch = useDispatch();
  const drawerContext = React.useContext(DrawerContext);
  const [view, setView] = React.useState([]);
  const [historyOfViewedForms, setHistoryOfViewedForms] = React.useState([]);
  const [patientDetail, setPatientDetail] = React.useState(entryPatientDetails);
  const [showForm, setShowForm] = React.useState(isEdit);
  const [showuserDetails, setShowuserDetails] = React.useState(true);
  const [state, setState] = React.useState({
    selected_form: forms[0],
    form_id: forms[0]?.id,
    answer: answer,
  });

  const [hidebtnDEtails, setHideBTnDetails] = React.useState(true);

  const [client_ID, setClient_ID] = React.useState("");

  const onInfoClicked = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      // component: <CaseDefinition data={form_detail?.mapped_diseases} />,
      onClose: () => onDrawerClosed(),
    });
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const showBTn = () => {
    if (hideBtn) {
      setHideBTnDetails(true);
    } else {
      setHideBTnDetails(false);
    }
  };

  const getSectionId = (data) => {
    // console.log(data, "data");
    // console.log("forms", forms);
    // let formexpanded = forms?.filter((_) => _.id === data.id)?.[0];
    // let updatefromExpaneded = {
    //   ...formexpanded,
    //   expanded: [...formexpanded.expanded, data.id],
    // };
    setState({
      ...state,
      selected_form: forms?.filter((_) => _.id === data.id)?.[0],
      form_id: data.id,
    });

    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
      component: <></>,
      // onClose: () => onViewSectionDrawerClosed(),
    });
  };

  const onBackBtnClicked = () => {
    if (hideBtn) {
      if (!showForm) {
        goBack();
      } else {
        setShowForm(false);
        setHideBTnDetails(true);
        goBack();
      }
    } else {
      goBack();
    }
  };

  const viewSectionDrawer = () => {
    // console.log("answer from builder", answerobj);
    // console.log("stateanswer", state.answer);
    // console.log("stateanswer", tree_view);

    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: "right",
      isLarge: true,
      variant: DrawerProps.variant.temporary,
      component: (
        // <div style={{ padding: 20 }}>
        <FormTreeView
          tree_view={tree_view}
          getSectionId={getSectionId}
          selected_section_id={state.form_id}
          selected_form={state.selected_form}
          isEdit={isEdit}
          view={view}
          historyOfViewedForms={historyOfViewedForms}
          setHistoryOfViewedForms={setHistoryOfViewedForms}
          setView={setView}
          isReadyOnly={isReadyOnly}
          secToShow={secToShow}
          title={form_detail?.name}
          answerobj={state.answer}
        />
        // </div>
      ),
      onClose: () => onViewSectionDrawerClosed(),
    });
  };

  const onViewSectionDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: false,
      direction: "right",
      component: <></>,
    });
  };

  const updateAnswer = (answer) => {
    setState({
      ...state,
      answer,
    });
  };

  const onPatientSelected = async (data) => {
    data = { ...data, client_ID };
    if (!data.notify) {
      let patientdetails = await dispatch(
        actions.UPSERT_PATIENT({
          ...data.createPatientData,
          patient_id: false,
          client_ID,
        })
      );
      data = {
        ...patientdetails?.payload?.data?.Result[0]?.properties?.doc,
        patient_id:
          patientdetails?.payload?.data?.Result[0]?.properties?.doc?._id,
      };
    }
    setPatientDetail({
      ...patientDetail,
      ...data,
    });

    setShowForm(true);
    setHideBTnDetails(false);
  };

  const restForm = (data) => {
    setPatientDetail(null);
    setShowForm(false);
  };

  React.useEffect(() => {
    setHistoryOfViewedForms([...historyOfViewedForms, view]);
    // eslint-disable-next-line
  }, [view]);
  return (
    // <div className={classes.root} id="frDiv1">
    <Paper className={classes.formBuilderPaper} id="paper">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        {/* Header Section */}
        {/* <Grid item>
          <SubHeader
            hideBackBtn={hidebtnDEtails}
            title={form_detail?.name}
            dataSecurityMappedCountryAndSelectedlevel={
              dataSecurityMappedCountryAndSelectedlevel
            }
            mappedLocations={mappedLocations}
            entry_id={entry_id}
            form_id={state.form_id}
            parent_form_id={parent_form_id}
            subTitle={`(${
              form_detail?.master_form_type?.form_type ?? "Form Type"
            })`}
            transferDetails={transferDetails}
            onBackBtnClicked={onBackBtnClicked}
            onActionIconBtnClicked={onInfoClicked}
            showInfoButton
            secName={secName}
            showToogleButton
            toggleButtonData={
              isInvestigator
                ? toggleButtonStatus.toggleButtonData?.filter(
                    (l) => l.value === 5 || l.value === 6
                  )
                : toggleButtonStatus.toggleButtonData
            }
            selected={toggleButtonStatus.isSelected}
            exclusive={toggleButtonStatus.exclusive}
            onSelected={toggleButtonStatus.onSelected}
            statusUpdateData={toggleButtonStatus.statusUpdateData}
            statusPath={toggleButtonStatus.status}
            redirectPath={toggleButtonStatus?.redirectPath}
            showPatientDetails={true}
            removeMarginLeft={true}
            getPatientDetails={patientDetail}
            view={view}
            setView={setView}
            isReadyOnly={isReadyOnly}
            secToShow={secToShow}
            // onPatientInfoBtnClicked={onPatientInfoBtnClicked}
          />
        </Grid> */}

        {/* Main Content */}
        {forms.length > 0 && (
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.render}
          >
            {/* Form View */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <Paper className={classes.formBuilderPaper} id="paper"> */}

              {
                <FormBuilder
                  getSectionId={getSectionId}
                  form_id={state.form_id}
                  onBackBtnClicked={onBackBtnClicked}
                  forms={forms}
                  parent_form_id={parent_form_id}
                  isEdit={isEdit}
                  formName={form_detail?.name}
                  entry_id={entry_id}
                  answer={answer}
                  isInvestigator={isInvestigator}
                  isReadyOnly={isReadyOnly}
                  secToShow={secToShow}
                  secName={secName}
                  onSaveForm={onSaveForm}
                  onAnswerUpdated={updateAnswer}
                  view={view}
                  setView={setView}
                  viewSection={viewSectionDrawer}
                  setHistoryOfViewedForms={setHistoryOfViewedForms}
                  statusUpdateData={toggleButtonStatus.statusUpdateData}
                  patientDetail={patientDetail}
                  restForm={restForm}
                  ticketId={ticketId}
                  isCancel={isCancel}
                  isCancelFunc={isCancelFunc}
                />
              }
              {/* </Paper> */}
            </Grid>
          </Grid>
        )}

        {forms.length === 0 && (
          <NotFoundSection message="No Forms Added Yet!" />
        )}
      </Grid>
    </Paper>
    // </div>
  );
};
