import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { Cards } from "../card";
import { AlertProps, aqlQuery, AxiosCall } from "../../utils";
import { AlertContext, BackdropContext } from "../../contexts";
import axios from "axios";
import { PageEmpty } from "../pageEmpty";
import jwt_decode from "jwt-decode";
// import { FormRenderer } from "../formRenderer";
// import { FormRendererParent, ViewForm } from "form-configurator";
import FormRendererParent from "../../screens/formRenderer";
import AlertDialog from "../../components/model";
import AttachmentModal from "../../components/attachmentmodal";
import { KeyboardArrowLeftOutlined } from "@material-ui/icons";
import { actions } from "central_pharma_binder";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  mainPanelfor: {
    height: "100%",
    width: "100%",
    padding: theme.spacing(3),

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 8px",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
  },
  flex: {
    display: "flex",
  },
}));
export const MainPanel = (props) => {
  const {
    selectedTask,
    logState,
    isLoading,
    onUserActionPerformed,
    onReloadActionPerformed,
    scenarioState,
    timelineState,
    timeline,
    updateTimeLine,
    alignment,
    updateState,
    PermissionData,
    scenarioId,
    getTaskViewerConifg,
    queueId,
    each,
    favtab,
    stateid,
    setHidePanel,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const backDrop = React.useContext(BackdropContext);
  const alertMessage = React.useContext(AlertContext);
  const [statesAction, setStatesAction] = React.useState([]);
  const [formRendering, setFormRendering] = React.useState({});
  const [showForm, setShowForm] = React.useState(false);
  const db = sessionStorage.getItem("ProjectDbname");
  const [sectoshow, setsecToShow] = React.useState([0, null]);
  const [secname, setsecname] = React.useState(0);
  const [UserRole, setUserRole] = React.useState("");
  const [displayActionButton, setDisplayActionButton] = React.useState(0);
  const [open, setOpen] = React.useState({
    timeline: false,
    attachment: false,
  });
  const theme = useTheme();
  const forTab = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen({ timeline: true, attachment: false });
  };
  const handleClickOpenAttachment = () => {
    setOpen({ timeline: false, attachment: true });
  };

  const handleClose = () => {
    setOpen({
      timeline: false,
      attachment: false,
    });
  };
  React.useEffect(() => {
    if (selectedTask?.ticketId) {
      getApplicableAction(selectedTask?.ticketId);
      getTransactionId(selectedTask?.ticketId);
      setShowForm(false);
    }

    GetRoleBased();

    //eslint-disable-next-line
  }, [selectedTask]);

  const GetRoleBased = async () => {
    let value = [0, 1, null];
    setsecToShow(value);
    setsecname(1);
    setUserRole("verifier");
  };

  const getApplicableAction = async (ticketId) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog,queuescenariostatemapping",
        filter: {
          QDMATPtransactionlog: `QDMATPtransactionlog.activestatus==true && QDMATPtransactionlog.ticketId=='${ticketId}'`,
          queuescenariostatemapping:
            "queuescenariostatemapping.activestatus==true && queuescenariostatemapping.queueid==QDMATPtransactionlog.queueIds && queuescenariostatemapping.scenarioid IN QDMATPtransactionlog.payload.scenarioId[*] LET docstates=(FOR docstates IN TO_ARRAY(queuescenariostatemapping.states) FILTER docstates._id IN QDMATPtransactionlog.payload.statesid[*] return docstates.action[*])",
        },
        return_fields:
          "Merge({docStatesActions:(For docstateaction in docstates[0] return Merge(docstateaction,unset(document(docstateaction._id),'createddate','updatedate','_rev','version','_key','activestatus','updatedby','active','createdby')))})",
      };

      // SetLoading(true);
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            setStatesAction(data?.docStatesActions);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  const getTransactionId = async (ticketId) => {
    try {
      let params = {
        db_name: `${db}`,
        entity: "QDMATPtransactionlog",
        filter: `QDMATPtransactionlog.ticketId=='${ticketId}'`,
        return_fields: "QDMATPtransactionlog",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result[0];
            let doc = {
              formId: data?.payload?.inputDoc?.form_id,
              formName: data?.payload?.inputDoc?.form_name,
              // patient: data?.payload?.inputDoc?.patient_details,
              // formId: data?.payload?.inputDoc?.form_id,
            };
            // setFormRendering(doc);
            getFormFromExecuteAql(doc, ticketId);
            getAduitLog(data._id);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  // console.log("formRen2", formRendering.formId);

  const getFormFromExecuteAql = async (doc, ticketId) => {
    let params = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      query: aqlQuery.getFormData(doc?.formId, ticketId),
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_READ_DOCUMENT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };

    await axios(config)
      .then((res) => {
        let aqldata = res.data[0];
        let answerArr = aqldata?.bulkAnswers?.answers ?? [];
        let answer = {};
        // console.log(aqldata, "aqldata");
        answerArr?.map((l) => (answer[l.question_id] = l.answer));
        let updateState = {
          ...doc,
          formData: aqldata,
          selected_form: aqldata?.form[0],
          answer: answer,
          _id: aqldata?.bulkAnswers?.entry_id,
          secName: aqldata?.bulkAnswers?.secName ?? 1,
        };
        setFormRendering(updateState);
        setShowForm(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAduitLog = async (_id) => {
    try {
      let params = {
        db_name: sessionStorage.getItem("AuditDB"),
        entity: "QDMATPtransactionlog_audit",
        filter: `QDMATPtransactionlog_audit.refkey=='${_id}'`,
        return_fields: "QDMATPtransactionlog_audit",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_ARANGO_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.result;

            if (data.length > 0) {
              let decodeArr = data.map((l) => {
                let Payload = atob(l.payload);
                let Document = atob(l.document);
                return {
                  ...l,
                  payload: JSON.parse(Payload),
                  document: JSON.parse(Document),
                };
              });
              // let stateList = props.scenarioState;
              let isArrHasState = [];
              let first;
              let last;
              decodeArr.forEach((l) => {
                if (l?.version === "2") {
                  first = {
                    statustime: "",
                    userAction: "",
                    states: l?.document?.payload?.queueMetaData?.states?.states,
                    message: "action is not yet held",
                  };
                } else if (
                  l?.document?.payload?.statesid &&
                  l?.document?.payload?.queueMetaData?.userAction?.userAction
                ) {
                  isArrHasState.push({
                    statustime: l?.statustime,
                    userAction:
                      l?.document?.payload?.queueMetaData?.userAction
                        ?.userAction,
                    states: l?.document?.payload?.queueMetaData?.states?.states,
                  });
                } else if (l?.document?.statesEnd) {
                  isArrHasState.push({
                    statustime: "",
                    userAction: "",
                    states: l?.document?.payload?.queueMetaData?.states?.states,
                    message: "completed",
                  });
                }
              });
              if (isArrHasState?.length === 0) {
                isArrHasState.push(first);
              }

              //  console.log("isarr", isArrHasState)
              updateTimeLine(isArrHasState);
            } else {
              // window.alert("else")
              let z = timelineState.map((l) =>
                Object.assign(l, { statustime: "", userAction: "" })
              );
              updateTimeLine(z);
            }

            //  getAduitLog(data._id);
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          let z = timelineState.map((l) =>
            Object.assign(l, { statustime: "", userAction: "" })
          );
          updateTimeLine(z);
          console.log(err);
        });
    } catch (error) {
      // updateTimeLine(scenarioState);
    }
  };

  const a = new Date(selectedTask?.field9 * 1000);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // url: `${process.env.REACT_APP_PERFORMUSERACTION_API}`,
  const performUserAction = async (
    Action,
    ticketId,
    formDataOfState = {},
    nameOfState = "",
    entryid = ""
  ) => {
    formDataOfState = { ...formDataOfState, entry_id: entryid };

    if (Action) {
      try {
        backDrop.setBackDrop({
          ...backDrop,
          open: true,
          message: "Loading...",
        });
        let config = {
          method: "post",
          url: `${sessionStorage.getItem("PerformUserAction")}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            ticketId: ticketId,
            userAction: Action.action,
          },
        };
        await axios(config).then((res) => {
          if (Action.apiendpoint.length !== 0) {
            setTimeout(() => {
              axios({
                method: "POST",
                // url: `http://localhost:3009/paymentordispensepost`,
                url: `${Action.apiendpoint}`,
                headers: { "Content-Type": "application/json" },
                data: {
                  ticketId,
                  formDataOfState,
                  nameOfState,
                  entryid,
                  Action,
                },
              }).then((res1) => {
                if (!res1.data.error) {
                  alertMessage?.setSnack({
                    ...alertMessage,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: `You've marked report as "${Action.action}"`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                  });
                  onUserActionPerformed(ticketId);
                } else {
                  alertMessage?.setSnack({
                    ...alertMessage,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: `${res.data.message}`,
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.right,
                  });
                }
              });
              backDrop.setBackDrop({
                ...backDrop,
                open: false,
              });
            }, 3000);
          } else {
            if (!res.data.error) {
              alertMessage?.setSnack({
                ...alertMessage,
                open: true,
                severity: AlertProps.severity.success,
                msg: `You've marked report as "${Action.action}"`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });
              // onUserActionPerformed(selectedTask?.subtitle2);
              onUserActionPerformed(ticketId);
              // setState({});
              // setAlignment("");
            } else {
              backDrop.setBackDrop({
                ...backDrop,
                open: false,
              });
              alertMessage?.setSnack({
                ...alertMessage,
                open: true,
                severity: AlertProps.severity.error,
                msg: `${res.data.message}`,
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.right,
              });

              // setAlignment("");
            }
          }
        });
      } catch (error) {}
    }
  };

  const updateFormEntries = async (data) => {
    const personId = jwt_decode(localStorage.getItem("queue_token"));
    let doc = { ...data.formdata, verifier_id: personId.personid };
    if (doc.entry_id) {
      if (doc.notifier_status) {
        // doc.notifier_status = notifier_status;
        if (doc.notifier_status === 1) {
          doc.verifier_status = 2;
        }
      }
      if (doc.verifier_status) {
        // doc.verifier_status = verifier_status;
        if (doc.verifier_status === 3) {
          doc.registration_status = 7;
        }
      }
    }

    let params = {
      db_name: `${process.env.REACT_APP_DB}`,
      entity: "form_entries",
      is_metadata: false,
      filter: {
        _key: doc.entry_id,
      },
      doc: doc,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: [params],
    };

    await axios(config)
      .then((res) => {
        if (res.data.Code === 201) {
          let ticketId = data?.QDMdata?.ticketId;
          let _data = data?.QDMdata?._data;
          Promise.resolve(
            dispatch(
              actions.UPDATE_QDM_TRANSACTION_LOG({
                ticketId,
                data: _data,
              })
            )
          ).then((res) => {
            // console.log("UPDATE_QDM_TRANSACTION_LOG", res);
            updateReportOrg(ticketId, _data);
          });
          setDisplayActionButton(1);
          if (selectedTask?.ticketId) {
            getApplicableAction(selectedTask?.ticketId);
            getTransactionId(selectedTask?.ticketId);
            setShowForm(false);
          }
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: `Notification has been "updated" Successfully!!!`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          getTransactionId(selectedTask?.ticketId);
        } else {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something Went Wrong",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateReportOrg = async (ticketId, data) => {
    let entityType = data?.mappedData?.EntityType;
    let docParams = undefined;

    if (entityType === "Group Parent Entity") {
      docParams = {
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "Room",
        is_metadata: false,
        filter: {
          ticketId: ticketId,
        },
        doc: {
          WardCode: data?.mappedData?.ReportingOrganization?.value ?? "",
        },
      };
    } else if (entityType === "Individual entity part of group") {
      docParams = {
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "Bed",
        is_metadata: false,
        filter: {
          ticketId: ticketId,
        },
        doc: {
          RoomCode: data?.mappedData?.ParentGroup?.value ?? "",
        },
      };
    } else if (entityType === "Individual Entity") {
      docParams = {
        db_name: `${process.env.REACT_APP_DB}`,
        entity: "Bed",
        is_metadata: false,
        filter: {
          ticketId: ticketId,
        },
        doc: {
          RoomCode: data?.mappedData?.ReportingOrganization?.value ?? "",
        },
      };
    }

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/api/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: [docParams],
    };

    await axios(config)
      .then((res) => {
        //console.log("PharmacyUpdate", res)//data.Result[0].properties.doc._id
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log("status", formRendering?.formData?.bulkAnswers?.verifier_registration_status)

  //  console.log("timeline", timelineState, timeline)
  return (
    <div className={classes.mainPanelfor}>
      {logState?.length !== 0 ? (
        <>
          {Object.keys(selectedTask)?.length === 0 ? (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <Typography variant="body1" color="InactiveCaptionText">
                {`"Please select a task from the left panel to view the configuration"`}
              </Typography>
            </div>
          ) : (
            <>
              {forTab && (
                <Button
                  variant="text"
                  startIcon={<KeyboardArrowLeftOutlined />}
                  onClick={() => {
                    setHidePanel(false);
                  }}
                >
                  Back
                </Button>
              )}
              <Cards
                verifier_registration_status={displayActionButton}
                scenarioId={scenarioId}
                type={2}
                action={statesAction || []}
                field1={selectedTask?.field1}
                field2={selectedTask?.field2}
                field3={selectedTask?.field3}
                field4={selectedTask?.field4}
                field5={selectedTask?.field5}
                field6={selectedTask?.field6}
                field7={selectedTask?.field7}
                field8={selectedTask?.field8}
                field9={selectedTask?.field9}
                ticketId={selectedTask?.ticketId}
                state={selectedTask?.state?.states}
                performUserAction={performUserAction}
                updateState={updateState}
                alignment={alignment}
                PermissionData={PermissionData}
                handleClickOpen={handleClickOpen}
                handleClickOpenAttachment={handleClickOpenAttachment}
              />

              <div
                style={{
                  height: "calc(100% -  120px)",
                  overflowY: "auto",
                  margin: "8px 0px",
                }}
              >
                {showForm && (
                  <FormRendererParent
                    verifier_registration_status={0}
                    onSaveForm={(doc) => {
                      updateFormEntries(doc);

                      // setdata(data);
                    }}
                    // getSectionId={getSectionId}
                    formid={formRendering?.formId}
                    answer={formRendering?.answer}
                    forms={formRendering?.formData?.form}
                    form_detail={formRendering?.formData}
                    entryId={formRendering?._id}
                    btnName="save"
                    submitBtnBottom="Right"
                    negativeBtn="Cancel"
                    secName={secname}
                    secToShow={sectoshow}
                    // cancelPresent={false}
                    // headerPresent={true}
                    // formTitle={true}
                    isReadyOnly={true}
                    // handleCancelBtn={() => handleCancel()}
                    patient_details={formRendering?.patient}
                    UserRole={"verifier"}
                    onReloadActionPerformed={onReloadActionPerformed}
                    // alertRequired
                    // divider
                  />
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          {isLoading ? <CircularProgress /> : <PageEmpty />}
        </div>
      )}
      <AlertDialog
        open={open.timeline}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        scenarioState={timeline}
        currantState={selectedTask?.state}
      />
      <AttachmentModal
        open={open.attachment}
        answer={formRendering?.answer}
        handleClickOpenAttachment={handleClickOpenAttachment}
        handleClose={handleClose}
      />
    </div>
  );
};
